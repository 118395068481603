import { Box, IconButton, styled } from "@mui/material";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";

interface StyledSpanProps {
  bgColor?: string; // Define bgColor prop here
}

export const StyledSpan = styled("span")<StyledSpanProps>(({ theme, bgColor }) => ({
  backgroundColor: bgColor || "#ffffff",
  padding: "3px",
  fontSize: "15px",
  fontWeight: "500",
  borderRadius: "5px",
}));

export const StyledContainer = styled("div")({
  padding: "10px",
  borderRadius: "8px",
  boxShadow: "0px 2px 4px rgba(1, 1, 0, 0.1)",
  width: "100%",
});

export const StyledText1 = styled(Typography)({
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "11.3px",
  textAlign: "justify",
  color: "#222222",
  marginBottom: "10px",
});

export const StyledText2 = styled(Typography)({
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "20px",
  color: "#2F343A",
  whiteSpace: "nowrap",
  display: "inline-block",
  padding: "0 10px",
  borderRadius: "5px",
  fontcolor: "#2F343A",
});

export const StyledTitle = styled(Typography)({
  fontWeight: 600,
  marginBottom: "16px",
});
