import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

interface IState {
    constitutionOfBusiness: string;
    legalName: string;
    tradeName: string;
    locationOfSuplier: string;
    registrationNumber: string;
    dateOfRegistration: string;
    mobileNumber: number | null;
    altMobileNumber: number | null;
    email: string;
    website: string;
    gstIn: string;
    gstRegistrationCertificate: string;
    pan: string;
    ePanCopy: string;
    nameOfAuthorizedSignatory: string;
    authorizedSignatoryEmail: string;
    authorizedSignatoryMobile: number | null;
    flatNo: string;
    floorNo: string;
    premises: string;
    street: string;
    locality: string;
    city: string;
    district: string;
    state: string;
    pin: string;
    isGst: boolean
}

export const initialState: IState = {

    constitutionOfBusiness: '',
    legalName: '',
    tradeName: '',
    locationOfSuplier: '',
    registrationNumber: '',
    dateOfRegistration: '',
    mobileNumber: null,
    altMobileNumber: null,
    email: '',
    website: '',
    gstIn: '',
    gstRegistrationCertificate: '',
    pan: '',
    ePanCopy: '',
    nameOfAuthorizedSignatory: '',
    authorizedSignatoryEmail: '',
    authorizedSignatoryMobile: null,
    flatNo: '',
    floorNo: '',
    premises: '',
    street: '',
    locality: '',
    city: '',
    district: '',
    state: '',
    pin: '',
    isGst: true

}

export const BillingEntitySlice = createSlice({
    name: "billingEntity",
    initialState,
    reducers: {
        handleChange(
            state: IState,
            action: PayloadAction<{ key: string; value: any }>
        ) {
            state[action.payload.key] = action.payload.value;

        },
    }
});

export const { handleChange } = BillingEntitySlice.actions;
export const selectBillingEntity = (state: RootState) => state.billingEntity;
export default BillingEntitySlice.reducer;