import { Box, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledProfileNav = styled("div")(({ theme }) => ({
  background: "#F5F5F5",
  display: "flex",
  justifyContent: "center",
  gap: 20,
  
'@media (max-width: 768px)': {
    position: 'absolute', // Position absolutely on mobile screens
    bottom: 0, // Position at the bottom
    width: '100%', // Full width
    
    justifyContent: 'left', // Align items to the start
    gap: 10, // Reduce gap between items
    overflowX: 'scroll', // Enable horizontal scrolling
    // '-webkit-overflow-scrolling': 'touch', // Smooth scrolling for iOS
    whiteSpace: 'nowrap', // Prevent wrapping of child elements
  },
}));

export const StyledProfileNavColor = styled("div")(({ theme }) => ({
  // background: theme.palette.primary.main,
  display: "flex",
  justifyContent: "center",
  gap: 25,

  '@media (max-width: 768px)': {
   position: 'absolute', // Position absolutely on mobile screens
    bottom: 0, // Position at the bottom
    width: '100%', // Full width
    
    justifyContent: 'left', // Align items to the start
    gap: 10, // Reduce gap between items
    overflowX: 'scroll', // Enable horizontal scrolling
    '-webkit-overflow-scrolling': 'touch', // Smooth scrolling for iOS
    whiteSpace: 'nowrap', // Prevent wrapping of child elements
  },
}));

export const StyledProfileNavItem = styled(Typography)<{ active: number }>(
  ({ theme, active }) => ({
    padding: "15px 0px",
    position: "relative",
    color: active ? "black" : "rgba(0,0,0,0.6)",
    cursor: "pointer",
    ...(active && {
      "&:before": {
        position: "absolute",
        content: "''",
        bottom: 0,
        width: "80%",
        left: "50%",
        transform: "translateX(-50%)",
        height: 2,
        background: theme.palette.primary.dark,
      },
    }),
  })
);

export const StyledFile = styled("div")<{ dragging?: number }>(
  ({ dragging }) => ({
    border: `1px solid ${dragging ? "red" : "#DDDDDD"}`,
    borderRadius: "4px",
    overflow: "hidden",
    height: "220px",
    display: "flex",
    flexDirection: "column",
  })
);

export const StyledFileTitle = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

export const StyledSingleLineContainer = styled("div")(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  lineClamp: 1,
  WebkitBoxOrient: "vertical",
}));

export const StyledFolder = styled("div")<{
  dragging?: number;
  dropping?: number;
}>(({ dragging, dropping }) => ({
  border: `1px solid ${dragging ? "red" : dropping ? "#182F53" : "rgba(0,0,0,0.08)"
    }`,
  borderRadius: "10px",
  cursor: "pointer",
  display: "flex",
  gap: 10,
  alignItems: "center",
  backgroundColor: dropping ? "rgb(24, 47, 83, 0.2)" : "rgba(0,0,0,0.06)",
  padding: "15px 20px",
}));

export const StyledUploadStatusDrawer = styled("div")(({ theme }) => ({
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  background: "white",
  width: "400px",
  border: "1px solid black",
  position: "fixed",
  left: 20,
  bottom: 0,
  zIndex: 500000,
  [theme.breakpoints.down("sm")]: {
    width: "100%", // Full width on small screens
    left: 0,
  },
  [theme.breakpoints.between("sm", "md")]: {
    width: "75%", // 75% width on small to medium screens
    left: "12.5%", // Centered on screen
  },
  [theme.breakpoints.between("md", "lg")]: {
    width: "50%", // 50% width on medium to large screens
    left: "25%", // Centered on screen
  },
  [theme.breakpoints.up("lg")]: {
    width: "400px", // Fixed width on large screens
    left: 20,
  },
}));

export const StyledProfileImageContainer = styled("div")(() => ({
  position: "relative",
  display: "inline-flex",
}));

export const StyledProfileImage = styled("img")(() => ({
  width: "100px",
  height: "100px",
  borderRadius: "50%",
  objectFit: "cover",
}));

export const StyledOrganizationImageContainer = styled("div")(() => ({
  width: 70,
  height: 70,
  position: "relative",
}));

export const StyledOrganizationImage = styled("img")(() => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
}));

export const StyledOrganizationImageOverlay = styled("div")<{ hover: boolean }>(
  ({ hover }) => ({
    position: "absolute",
    right: 0,
    top: 0,
    width: "100%",
    opacity: hover ? 1 : 0,
    height: "100%",
    display: "flex",
    transition: "0.3s",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(0,0,0,0.6)",
    cursor: "pointer",
  })
);

export const StyledProfileImageOverlay = styled("div")<{ hover: number }>(
  ({ hover }) => ({
    position: "absolute",
    right: 0,
    top: 0,
    width: "100%",
    opacity: hover ? 1 : 0,
    height: "100%",
    display: "flex",
    transition: "0.3s",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    background: "rgba(0,0,0,0.6)",
    cursor: "pointer",
  })
);

export const StyledLocaldirectorypath = styled("div")(() => ({
  display: "flex",
  gap: 20,
  border: "1px solid lightgrey",
  padding: "15px 40px 15px 10px",
  justifyContent: "space-between",
  borderRadius: 4,
  alignItems: "center",
  marginTop: "10px",
  position: "relative",
}));

export const StyledContactPerson = styled("div")(() => ({
  display: "flex",
  gap: 20,
  border: "1px solid lightgrey",
  padding: "20px 10px",
  paddingTop: 30,
  justifyContent: "space-between",
  borderRadius: 4,
  alignItems: "center",
  marginTop: "10px",
  position: "relative",
}));

export const StyledRecurProfileContainer = styled("div")(() => ({
  display: "flex",
  maxWidth: "92vw",
  margin: "auto",
  width: "100%",
  gap: 10,
  marginTop: 30,
  boxShadow: "0px 5px 15px #22222214",
  borderRadius: 10,
}));

export const StyledRecurItemsContainer = styled("div")(() => ({
  height: "80vh",
  overflowY: "auto",
  border: "1px solid rgba(0,0,0,0.1)",
  borderRadius: "10px"
}));

export const StyledRecurTasksContainer = styled("div")(() => ({
  padding: 10,
  height: "80vh",
  overflowY: "auto",
}));

export const StyledRecurProfileItem = styled("div")<{
  active: number | undefined;
  last: number | undefined;
}>(({ active, last }) => ({
  cursor: "pointer",
  padding: "13px 10px",
  backgroundColor: active ? "rgb(126, 168, 236, 0.4)" : "white",
  borderBottom: last ? "none" : "1px solid rgba(0,0,0,0.1)",
  position: "relative",
  display: "flex",
  justifyContent: "space-between",
  gap: 10,
}));

export const StyledTaskSection = styled("div")(({ theme }) => ({
  padding: 30,
  paddingBottom: 50,
  borderBottom: "4px solid rgba(0,0,0,0.05)",
  position: "relative",
  overflow: "hidden",
}));

export const StyledLogHoursTopbar = styled("div")<{ active: 1 | 0 }>(
  ({ active }) => ({
    position: "absolute",
    left: 0,
    transition: "0.4s",
    top: active ? 0 : -100,
    width: "100%",
    background: "#ededed",
    padding: 15,
    zIndex: 100,
    display: "flex",
    gap: 10,
    justifyContent: "space-between",
  })
);

export const StyledSearchList = styled(Paper)(() => ({
  width: "100%",
  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
  position: "absolute",
  top: "100%",
  left: 0,
  minHeight: "200px",
  maxHeight: "400px",
  overflowY: "auto",
  zIndex: 1,
}));

export const StyledTimelineIcon = styled("div")(({ theme }) => ({
  background: "#0D47A11A",
  padding: "10px 20px",
  width: "100%", // Make width 100% for responsiveness
  maxWidth: 240, // Add a max-width to ensure it doesn't get too large
  position: "relative",
  "&:before": {
    content: "''",
    position: "absolute",
    bottom: 0,
    width: 0,
    height: 0,
    left: "100%",
    borderLeft: "20px solid #0D47A11A",
    borderTop: "35px solid transparent",
    borderBottom: "35px solid transparent",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "8px 16px",
    "&:before": {
      position: "absolute",

      borderTop: "25px solid transparent",
      borderBottom: "25px solid transparent",
    },
  },
  [theme.breakpoints.down("xs")]: {
    padding: "6px 8px",    "&:before": {
      position: "absolute",
      borderTop: "20px solid transparent",
      borderBottom: "20px solid transparent",
    },
  },
}));

export const  StyledBox = styled("div")(({ theme }) => ({
  border: "1px solid #E1E9F8",
  borderRadius: "10px",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  width: "100%", // default width for larger screens
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    width: "400px", // full width for extra-small screens
  },
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "400px", // full width for extra-small screens
  },
}));
export const StyledFolderOneDeive = styled("div")<{
  dragging?: number;
  dropping?: number;
}>(({ dragging, dropping }) => ({
  border: `1px solid ${dragging ? "red" : dropping ? "#182F53" : "rgba(0,0,0,0.08)"
    }`,
  borderRadius: "10px",
  cursor: "pointer",
  display: "flex",
  gap: 10,
  alignItems: "center",
  backgroundColor: dropping ? "rgb(24, 47, 83, 0.2)" : "rgba(0,0,0,0.06)",
  padding: "15px 20px",
}));

export const ResponsiveBox = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(0),
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(0),

  },
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(0),
  },
}));

export const ResponsiveUploadBox = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  maxHeight: 400,
  overflowY: "auto",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.between("sm", "md")]: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.between("md", "lg")]: {
    padding: theme.spacing(3),
  },
  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(4),
  },
}));

// Define ResponsiveUploadItem with styles for sm, md, and lg breakpoints
export const ResponsiveUploadItem = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(2),
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  [theme.breakpoints.between("sm", "md")]: {
    flexDirection: "row",
    alignItems: "center",
  },
  [theme.breakpoints.between("md", "lg")]: {
    flexDirection: "row",
    alignItems: "center",
  },
  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
    alignItems: "center",
  },
}));

export const StyledNavContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
  },
  [theme.breakpoints.between("sm", "md")]: {
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
    padding: theme.spacing(0),
  },
  [theme.breakpoints.between("md", "lg")]: {
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
    padding: theme.spacing(0),
  },
  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
    padding: theme.spacing(0),
    paddingBottom: theme.spacing(2), 
  
  },
}));

export const StyledClientsContainer = styled(Box)(({ theme }) => ({
  width: 320,
  height: "80vh",
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("sm")]: {
    height: "40vh",
    width: 250,
    padding: theme.spacing(1),
  },
  [theme.breakpoints.between("sm", "md")]: {
    height: "70vh",
    width: 320,

    // padding: theme.spacing(1.5),
    flexDirection: "column",

  },
  [theme.breakpoints.up("md")]: {
    height: "80vh",
    width: 320,

    padding: theme.spacing(2),
  },
}));

export const StyledSearchBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  overflowY: "auto",
  flex: 1,
  paddingRight: theme.spacing(1),

  [theme.breakpoints.down("sm")]: {
    marginTop: theme.spacing(1.5),
  },
}));


