import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "redux/store";

export interface Address {
  displayName: string;
  legalName: string;
  buildingNumber: string;
  floorNumber: string;
  district: string;
  location: string;
  buildingName: string;
  street: string;
  city: string;
  state: string;
  pincode: string;
  email: string;
  mobileNumber: string;
  locationOfSupply: string;
  countryCode: string;
}

export interface IState {
  receiptNumber: string;
  receiptDate: string | null;
  client: number | null;
  clientType: string | null;
  type: "INVOICE" | "TASK" | "ADVANCE";
  amount: number;
  previousCredits: number;
  paymentMode:
  | "CASH"
  | "CREDIT_CARD"
  | "CHEQUE"
  | "BANK_TRANSFER_NEFT_RTGS_IMPS"
  | "UPI"
  | "DEBIT_CARD";
  paymentDate: string | null;
  referenceNumber: string;
  tds: string;
  tdsAmount: number;
  creditsUsed: number;
  totalCredits: number;
  particulars: Array<any>;
  invoices: Array<any>;
  whatsappCheck: boolean;
  //new
  billingEntity: number | null;
  billingEntityAddress: Address | null;
  shippingAddress: Address | null;
  logoUrl: string;
  signatureUrl: string;
  termsAndConditionsCopy: Array<string>;
  selectedInvoices: Array<any>;
  selectedInvoicesInInv: Array<any>;
  fixedLowerBoundary: number;
  fixedAmount: number;
  fromInvoice: boolean;
  avaliableCredits: number;
  emailCheck: boolean;
  selectedd: Array<any>;
  removedRps: Array<any>;
  invoiceIds: Array<number>;
  selected: Array<any>;
}

const initialState: IState = {
  receiptNumber: "",
  receiptDate: null,
  client: null,
  clientType: null,
  type: "INVOICE",
  particulars: [],
  amount: 0,
  previousCredits: 0,
  totalCredits: 0,
  paymentMode: "CASH",
  paymentDate: null,
  referenceNumber: "",
  tds: "",
  tdsAmount: 0,
  creditsUsed: 0,
  invoices: [],
  whatsappCheck: false,
  //new
  billingEntity: null,
  billingEntityAddress: null,
  shippingAddress: null,
  logoUrl: "",
  signatureUrl: "",
  termsAndConditionsCopy: [],
  selectedInvoices: [],
  selectedInvoicesInInv: [],
  fixedLowerBoundary: 0,
  fixedAmount: 0,
  fromInvoice: false,
  emailCheck: false,
  avaliableCredits: 0,
  selectedd: [],
  removedRps: [],
  invoiceIds: [],
  selected: [],
};

export const createReceiptSlice = createSlice({
  name: "createReceipt",
  initialState: _.cloneDeep(initialState),
  reducers: {
    handleChange(state: IState, action: PayloadAction<{ key: string; value: any }>) {
      // if (action.payload.key === "invoices") {
      //   const aa = (state[action.payload.key].find(i => i.id === action.payload.value.id));
      //   state[action.payload.key].push(action.payload.value);
      // } else {
      state[action.payload.key] = action.payload.value;
      // }
    },
    handleInvoiceChnage(state: IState, action: PayloadAction<{ key: string; value: any }>) {
      state[action.payload.key] = action.payload.value;
    },
    handleAddInvoice(state: IState, action: PayloadAction<{ key: string; value: any }>) {
      const existing: any[] = state.invoices.map((i) => i.invoice.id);
      const filter = action.payload.value.filter((j) => !existing.includes(j.invoice.id));

      state[action.payload.key].push(...filter);
    },
    handleWhatsapp(state: IState, action: PayloadAction<any>) {
      const data = action.payload;
      state.whatsappCheck = data;
    },

    handleMail(state: IState, action: PayloadAction<any>) {
      const data = action.payload;
      state.emailCheck = data;
    },

    resetState(state: IState) {
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    handleBillingEntityChange(state: IState, action: PayloadAction<{ billingEntity: any }>) {
      let billEntity = action.payload.billingEntity;
      state.billingEntity = billEntity?.id;
      let address = {
        legalName: billEntity?.tradeName,
        displayName: billEntity?.displayName,
        buildingNumber: billEntity?.buildingNumber,
        floorNumber: billEntity?.floorNumber,
        location: billEntity?.location,
        district: billEntity?.district,
        buildingName: billEntity?.buildingName,
        street: billEntity?.street,
        city: billEntity?.city,
        state: billEntity?.state,
        pincode: billEntity?.pincode,
        email: billEntity?.email,
        mobileNumber: billEntity?.mobileNumber,
        locationOfSupply: billEntity?.locationOfSupply,
        countryCode: billEntity?.countryCode,
      };
      state.billingEntityAddress = address;
    },

    handleClientChange(state: IState, action: PayloadAction<{ client: any }>) {
      let client = action.payload.client;
      state.client = client?.id;
      state.clientType = client?.type;
      let address = {
        displayName: client?.displayName,
        legalName: client?.legalName,
        buildingName: client?.buildingName,
        street: client?.street,
        city: client?.city,
        state: client?.state,
        pincode: client?.pincode,
        gstTreatment: "",
        gstIn: client?.gstIn,
        email: client?.email,
        mobileNumber: client?.mobileNumber,
        coutryCode: client?.countryCode,
      };
      if (client?.address?.billingfulladdress && client?.address?.shippingfulladdress) {
        const tempBillingAddress = JSON.parse(JSON.stringify(client?.address));
        const tempShippingAddress = JSON.parse(JSON.stringify(client?.address));
        tempBillingAddress["mobileNumber"] = client?.mobileNumber;
        tempBillingAddress["email"] = client?.email;
        tempBillingAddress["displayName"] = client?.displayName;
        tempShippingAddress["mobileNumber"] = client?.mobileNumber;
        tempShippingAddress["email"] = client?.email;
        tempShippingAddress["legalName"] = client?.legalName || client?.displayName;
        tempBillingAddress["countryCode"] = client?.countryCode;
        state.shippingAddress = tempShippingAddress;
      } else {
        const tempBillingAddress = {
          displayName: "",
          legalName: "",
          buildingNumber: "",
          floorNumber: "",
          district: "",
          location: "",
          buildingName: "",
          street: "",
          city: "",
          state: "",
          pincode: "",
          email: "",
          mobileNumber: "",
          locationOfSupply: "",
          countryCode: "",
        };
        tempBillingAddress["mobileNumber"] = client?.mobileNumber;
        tempBillingAddress["email"] = client?.email;
        tempBillingAddress["legalName"] = client?.legalName || client?.displayName;
        tempBillingAddress["displayName"] = client?.displayName;
        tempBillingAddress["countryCode"] = client?.countryCode;
        state.shippingAddress = tempBillingAddress;
      }
    },
    handleExistingClientChange(state: IState, action: PayloadAction<any>) {
      let client = action.payload.client;
      state.client = client?.id;
      let address = {
        displayName: client?.displayName,
        legalName: client?.legalName,
        buildingName: client?.buildingName,
        street: client?.street,
        city: client?.city,
        state: client?.state,
        pincode: client?.pincode,
        gstTreatment: "",
        gstIn: client?.gstIn,
        email: client?.email,
        mobileNumber: client?.mobileNumber,
        countryCode: client?.countryCode,
      };
      if (client?.address?.billingaddress) {
        const tempBillingAddress = JSON.parse(JSON.stringify(client?.address?.billingaddress));
        const tempShippingAddress = JSON.parse(JSON.stringify(client?.address?.shippingaddress));
        tempBillingAddress["mobileNumber"] = client?.mobileNumber;
        tempBillingAddress["email"] = client?.email;
        tempBillingAddress["displayName"] = client?.displayName;
        tempShippingAddress["mobileNumber"] = client?.mobileNumber;
        tempShippingAddress["email"] = client?.email;
        tempShippingAddress["legalName"] = client?.legalName;
        tempShippingAddress["countryCode"] = client?.countryCode;
        state.shippingAddress = tempBillingAddress;
        state.shippingAddress = tempShippingAddress;
      } else {
        const tempBillingAddress = {
          displayName: "",
          legalName: "",
          buildingNumber: "",
          floorNumber: "",
          district: "",
          location: "",
          buildingName: "",
          street: "",
          city: "",
          state: "",
          pincode: "",
          email: "",
          mobileNumber: "",
          locationOfSupply: "",
          countryCode: "",
        };
        tempBillingAddress["mobileNumber"] = client?.mobileNumber;
        tempBillingAddress["email"] = client?.email;
        tempBillingAddress["legalName"] = client?.legalName;
        tempBillingAddress["displayName"] = client?.displayName;
        tempBillingAddress["countryCode"] = client?.countryCode;
        state.shippingAddress = tempBillingAddress;
      }
    },
    handleExistingTermsAndConditions(state: IState, action: PayloadAction<string>) {
      state.termsAndConditionsCopy = state.termsAndConditionsCopy.concat(action.payload);
    },
    handleAddTermsAndConditions(state: IState, action: PayloadAction<string>) {
      state.termsAndConditionsCopy.push(action.payload);
    },
    handleRemoveTermsAndConditions(state: IState, action: PayloadAction<number>) {
      state.termsAndConditionsCopy.splice(action.payload, 1);
    },
    handleUpdateTermsAndConditions(state: IState, action) {
      state.termsAndConditionsCopy = action.payload;
    },
    handleRpsChange(state: IState, action: PayloadAction<any>) {
      state.removedRps.push(action.payload.value);
    },
    handleEditInvoiceDetails(state: IState, action: PayloadAction<any>) {
      state.selectedInvoices = state.selectedInvoices.map(invoice => invoice.id === action.payload.id ? { ...invoice, [action.payload.value.name]: action.payload.value.value } : invoice)
    },
    handleEditInvoicEditeDetails(state: IState, action: PayloadAction<any>) {

      state.invoices =
        state.invoices.map(item => item?.invoice.id === action.payload.id ? { ...item, invoice: { ...item.invoice, [action.payload.value.name]: action.payload.value.value } } : item)
    }
  },
});

export const selectReceipt = (state: RootState) => state.createReceipt;

export const {
  handleChange,
  resetState,
  handleWhatsapp,
  handleClientChange,
  handleExistingClientChange,
  handleBillingEntityChange,
  handleExistingTermsAndConditions,
  handleAddTermsAndConditions,
  handleRemoveTermsAndConditions,
  handleUpdateTermsAndConditions,
  handleInvoiceChnage,
  handleRpsChange,
  handleMail,
  handleAddInvoice,
  handleEditInvoiceDetails,
  handleEditInvoicEditeDetails,
} = createReceiptSlice.actions;

export default createReceiptSlice.reducer;
