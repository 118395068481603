import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import {
  getPreferencesBasedOnUserId,
  updateUserWhatsAppConfig,
} from "api/notifications-preferences-service";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { ResType } from "types";
import AddPreferences from "./add-preferences";
import { snack } from "components/toast";
import BlockIcon from "@mui/icons-material/Block";
import Loader from "components/Loader";

const NotificationsSingleUser = () => {
  const params = useParams();
  const [preferenceData, setPreferenceData] = useState<any>({});
  const [userWhatsappPreferences, setUserWhatsappPreferences] = useState(false);
  const queryClient = useQueryClient();

  const {
    data: preferencedata,
    isLoading,
    error,
  }: ResType = useQuery(["get-user-preferences", params?.id], getPreferencesBasedOnUserId, {
    onSuccess: (res: any) => {
      setPreferenceData(res?.data);
      setUserWhatsappPreferences(res?.data?.whatsappConfig);
    },
    cacheTime: 0,
  });

  const { mutate } = useMutation(updateUserWhatsAppConfig, {
    onSuccess: (res) => {
      snack.success("Whatsapp Preferences Updated");
      setPreferencesChanged(false);
      queryClient.invalidateQueries("get-user-preferences");
    },
    onError: (err: any) => {
      snack.error(err.response.data.message);
    },
  });
  const [preferencesChanged, setPreferencesChanged] = useState(false);
  const handleChange = () => {
    setUserWhatsappPreferences((prev) => !prev);
    setPreferencesChanged((prev) => !prev);
  };

  useEffect(() => {
    // Reset preferencesChanged when the component mounts
    setPreferencesChanged(false);
  }, []);

  const handleSave = async () => {
    setPreferencesChanged((prev) => !prev);
    mutate({
      data: {
        id: preferenceData?.user?.id,
        userWhatsappPreferences,
      },
    });
  };

  if (isLoading) return <Loader />;

  return !preferencedata?.data ? (
    <>
      <Box minHeight="80vh" display="flex" justifyContent="center" alignItems="center">
        <Box p={2} borderRadius={2} minWidth={300} textAlign="center" bgcolor="rgb(253, 237, 237)">
          <BlockIcon
            fontSize="large"
            sx={{
              color: "rgb(95, 33, 32)",
            }}
          />
          <Typography variant="subtitle2" color="rgb(95, 33, 32)">
            Access Denied
          </Typography>
        </Box>
      </Box>
    </>
  ) : (
    <Box>
      <Box>
        <Typography variant="h5" m={1} color={"primary"}>
          Notification
        </Typography>
        <Box display="flex" m={1}>
          <Typography variant="h6" m={1} mr={5} color={"primary"}>
            Does this user want WhatsApp Messages?
          </Typography>
          <Box display="flex" alignItems="center" mx={5}>
            <Typography mx={5}>
              <Checkbox
                onChange={handleChange}
                checked={userWhatsappPreferences}
                inputProps={{ "aria-label": "Select endsWith B" }}
              />
            </Typography>

            <Button
              size="small"
              disabled={!preferencesChanged}
              variant="contained"
              style={{ paddingLeft: "35px", paddingRight: "35px" }}
              color="error"
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
      {preferenceData && preferenceData && (
        <Box
          sx={{
            background: "#FBF9F2",
            borderRadius: "8px",
            p: 1,
            mb: 1,
          }}
        >
          <Grid container pl={2}>
            <Grid item xs={5}>
              {" "}
              <Typography fontSize={"17px"} variant="h6" pb={1} color={"#505152"}>
                User Name
              </Typography>
            </Grid>
            <Grid item xs={5}>
              {" "}
              <Typography fontSize={"17px"} variant="h6" pb={1} color={"#505152"}>
                Role Name
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {" "}
              <Typography fontSize={"17px"} variant="h6" pb={1} color={"#505152"}>
                Status
              </Typography>
            </Grid>
          </Grid>
          <Grid container pl={2}>
            <Grid item xs={5}>
              <Typography variant="subtitle2" fontSize={"15px"} color={"#78797a"}>
                {" "}
                {preferenceData?.user?.fullName}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="subtitle2" fontSize={"15px"} color={"#78797a"}>
                {preferenceData?.user?.role?.name}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle2" fontSize={"15px"} color={"#78797a"}>
                {preferenceData?.user?.status}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      {preferenceData && preferenceData && (
        <Box>
          <AddPreferences data={preferenceData} />
        </Box>
      )}
    </Box>
  );
};

export default NotificationsSingleUser;
