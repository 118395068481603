import React, { useState, useEffect, useContext } from 'react'
import { Box, Breadcrumbs, Tooltip, Typography } from "@mui/material";
import { LinkRouter } from "components/BreadCrumbs";
import { snack } from "components/toast";
import useTitle from "hooks/useTitle";
import moment from "moment";
import { useMutation } from "react-query";
import { handleError } from "utils/handleError";
import { UserProfileContext } from 'context/UserProfile';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Filters from 'views/reports/EmployeeAttendanceReport/Filters';
import { getEmployeeAttendanceReport } from 'api/services/reports';
import Report from "views/reports/EmployeeAttendanceReport/Report";

export interface IState {
    users: Array<any>;
    fromMonth: null | string | Date;
    toMonth: null | string | Date;
}

function EmployeeAttendanceReport() {
    useTitle("Reports");

    const [data, setData] = useState([]);
    const [state, setState] = useState<IState>({ users: [], fromMonth: moment(new Date()).format("MM-YYYY"), toMonth: moment(new Date()).format("MM-YYYY") });

    const [payload, setPayload] = useState({});
    const [filterfields, setFilterfields] = useState([
        { type: 'date', label: 'Task Started From', name: 'fromDate' },
        { type: 'date', label: 'Task Started To', name: 'toDate', filter: 'fromDate' }
    ]);

    const { data: user } = useContext(UserProfileContext);

    const { mutate, isLoading, isError } = useMutation(getEmployeeAttendanceReport, {
        onSuccess: (res: any) => {
            setData([]);
            setTimeout((res: any) => {
                const values: any = Object.values(res?.data)
                setData(values);
            }, 300, res);
        },
        onError: (err: any) => {
            snack.error(handleError(err));
        },
    });

    const handleSubmit = () => {
        if (!state.fromMonth) return snack.error("Please Select From Date");
        if (!state.toMonth) return snack.error("Please Select To Date");
        setData([]);
        const updatedpayload = {
            organizationid: '' + user?.organization?.id
        }
        setPayload(updatedpayload);

        const updatedstate = Object.assign({}, state, updatedpayload);
        mutate({
            ...updatedstate,
        });
    };

    return (
        <Box p={2}>
            <Breadcrumbs>
                <LinkRouter underline="hover" color="inherit" to="/reports">
                    Reports
                </LinkRouter>
                <Typography color="text.primary">User</Typography>
                <Box display='flex'>
                    <Typography color="text.primary" mr={1}>Employee Attendance </Typography>
                </Box>
            </Breadcrumbs>
            <Filters isloading={isLoading} state={state} setState={setState} onSubmit={handleSubmit} />
            <Report isLoading={isLoading} isError={isError} state={state} data={data} />
        </Box>
    )
}

export default EmployeeAttendanceReport;