import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

interface IState {
    category: any,
    subCategory: any[],
    monthAdded: string,
    labels: any[],
    search: string;
    status: any[];
    pageCount: number;
};


const filterState: IState = {
    category: [],
    subCategory: [],
    monthAdded: "",
    labels: [],
    search: "",
    status: [],
    pageCount: 10,
};


interface InitialState {
    selectedFilters: IState;
    appliedFilters: IState;
    pageCount: number;
}

const initialState: InitialState = {
    selectedFilters: filterState,
    appliedFilters: filterState,
    pageCount: 10,
    // category: [],
    // subCategory: [],
    // monthAdded: "",
    // labels: [],
    // search: "",
}


const clientsSlice = createSlice({
    name: "clients",
    initialState,
    reducers: {
        handleFieldChange(state, action: PayloadAction<{ key: string, value: any }>) {
            if (action.payload.key === "search") {
                state.selectedFilters[action.payload.key] = action.payload.value
                state.appliedFilters[action.payload.key] = action.payload.value
            }
            state.selectedFilters[action.payload.key] = action.payload.value
        },

        addFilters(state, action: PayloadAction<{ values: Partial<IState> }>) {
            const updates = action.payload.values;
            Object.keys(updates).forEach(key => {
                if (state.selectedFilters.hasOwnProperty(key)) {
                    state[key] = updates[key];
                }
            });
        },

        handlePageCount(state, action: PayloadAction<number>){
            state.pageCount = action.payload;
        },

        handleApply(state: InitialState) {
            state.appliedFilters = state.selectedFilters;
            // removes duplicate filter values
            // Object.keys(state.selectedFilters).forEach((filter: any) => {
            //   if (state.selectedFilters[filter] && state.selectedFilters[filter].length > 0) {
            //     state.appliedFilters[filter] = state.selectedFilters[filter].filter(
            //       (item: any, index: number, self: any) => self.findIndex((v: any) => v.value === item.value) === index
            //     );
            //   } else {
            //     state.appliedFilters[filter] = state.selectedFilters[filter];
            //   }
            // });
        },

        resetState(state) {
            state.selectedFilters = filterState;
            state.appliedFilters = filterState;
            state.pageCount = 10;
        },
    }


});

export const { handleFieldChange, resetState, addFilters, handleApply, handlePageCount } = clientsSlice.actions

export const selectClient = (states: RootState) => states.clients;
export default clientsSlice.reducer
