import React, { useState } from 'react'
import { getAppliedDsc, postApplyDsc } from 'api/services/clients/dsc-register';
import { useMutation, useQueryClient } from 'react-query';
import { snack } from 'components/toast';
import { handleError } from 'utils/handleError';
import useTitle from 'hooks/useTitle';
import { Box } from '@mui/system';
import UploadImage from 'components/UploadImage';
import { Button, FormControl, FormControlLabel, Grid, IconButton, InputLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Add, Close, CloseOutlined, DownloadOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { dscTypes } from 'data/constants';
import { getTitle } from 'utils';
import { StyledAttachment } from 'views/settings/organization/OrganizationProfile/styles';


const AddApplyDsc = () => {
    const navigate = useNavigate();



    const { mutate, isLoading, isError } = useMutation(postApplyDsc, {
        onSuccess: (res: any) => {
          // setData(res.data);
          snack.success("DSC application Added Successfully");
          navigate('/dsc-register/apply-dsc')
        },
        onError: (err: any) => {
          snack.error(handleError(err));
        },
      });
    const organizationType =[
      "Government/PSU","Company","Partnership","Proprietorship","AOP/BOI","LLP","NGO/Trust","Bank"
    ]
      interface StateProps{
        selectedType:any,
          applicantName:string,
          mobileNumber:string,
          email:string,
          certificateType: string; 
    certificateValidity: string; 
          aadharStorage:any,
          aadharStorageName:string,
          panStorage:any,
          panStorageName:string,
          photoStorage:any,
          photoStorageName:string,
          organizationTypeValue:string,


    
    
      };
    
      const intialState={
        selectedType:null,
          applicantName:"",
          mobileNumber:"",
          email:"",
          certificateType: "", 
          certificateValidity: "", 
          aadharStorage:null,
          aadharStorageName:"",
          panStorage:null,
          panStorageName:"",
          photoStorage:null,
          photoStorageName:"",
          organizationTypeValue:""

    
      }
    
      const [state,setState]=useState<StateProps>(intialState)
      useTitle("Add DSC Apply");
      const dscTypesArray = Object.keys(dscTypes).map(key => dscTypes[key]);
      const orgTypesArray = Object.keys(organizationType).map(key => organizationType[key]);



      const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };
      const isValidMobile = (mobile) => {
        const mobilePattern = /^[1-9]{1}[0-9]{9}$/;
        if (mobilePattern.test(mobile)) {
          return true
        } else {
          return false
        }
      }
      const isValidEmail = (email) => {
        var emailPattern = /^[a-zA-Z0-9~!#$%^&*_+{}|?.'=`]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
      }
      const handleSubmit = () => {

        if(!state.certificateType){
          snack.error("Please enter Certificate Type");
          return;
        }
        else if(!state.certificateValidity){
          snack.error("Please enter Certificate Validity");
          return;
        }

         else if(!state.applicantName || null){
          snack.error("Please enter Applicant Name");
          return;
        }

        else if(state.applicantName.trim().length < 3){
          snack.error("Please enter Atleast 3 Characters for Applicant Name");
          return;

        }
        else if (!isValidMobile(state.mobileNumber)) {
          snack.error("Invalid Mobile number");
          return
        }
          else if (!isValidEmail(state.email)){
            snack.error("Invalid Email ID");
            return


        }
       
       
       
        else if(!state.aadharStorageName){
          snack.error("Please upload aadhar");
          return;
        }
        else if(!state.panStorageName){
          snack.error("Please upload PAN");
          return;
        }
        else if(!state.photoStorageName){
          snack.error("Please upload Photo");
          return;
        }
        else{
          mutate({...state});
          console.log(state,'state')

        }
       
      };
  return (
    <Box  className="view-all-paddingtop-dsc" sx={{ margin: "20px" }}>
    <FormControl >
      <InputLabel id="dsc-type-label">DSC Type</InputLabel>
      <Select
        labelId="dsc-type-label"
        id="dsc-type"
        value={state.selectedType}
        onChange={(e) => handleChange(e)}
        name="selectedType"
        label="DSC Type"
        style={{ width: '200px' }}
      >
        {dscTypesArray.map((type, index) => (
          <MenuItem key={index} value={type}>
            {getTitle(type)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>

    {/* {state.selectedType === "Individual DSC - Using aadhar" || state.selectedType === "Organization DSC- Gst holder" ? (
      <div>
        <label>Field 1:</label>
        <input type="text" />
      </div>
    ) : null} */}

    {state.selectedType === dscTypes.Apply_DSC_Individual? (
      <Box p={3} maxWidth={600} sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>

<Grid>
                    <FormControl sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} required>
                        <Typography sx={{ marginRight: "20px", fontWeight:700}} id="demo-row-radio-buttons-group-label">Certificate Type *</Typography>
                        <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="certificateType"
                            sx={{display: "flex", flexDirection:"row", justifyContent:"space-between"}}
                            value={state.certificateType}
                            onChange={(e) => handleChange(e)}
                            aria-required
                    
                        >
                            <FormControlLabel value="signature" control={<Radio />} label="Signature" />&emsp;
                            <FormControlLabel value="encryption" control={<Radio />} label="Encryption" />&emsp;
                            <FormControlLabel value="both" control={<Radio />} label="Both" />&emsp;
                      
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid >
                    <FormControl sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography sx={{ marginRight: "20px", fontWeight:700}} id="demo-row-radio-buttons-group-label">Certificate Validity *</Typography>
                        <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="certificateValidity"
                            sx={{display: "flex", flexDirection:"row", justifyContent:"space-between"}}
                            value={state.certificateValidity}
                            onChange={(e) => handleChange(e)}
                        >
                            <FormControlLabel value="1year" control={<Radio />} label="1 Year" />&emsp;
                            <FormControlLabel value="2years" control={<Radio />} label="2 years" />&emsp;
                            <FormControlLabel value="3years" control={<Radio />} label="3 Years" />&emsp;
                        </RadioGroup>
                    </FormControl>
                </Grid>
        <TextField
          required
          label="Applicant Name"
          onChange={(e) => handleChange(e)}
          variant="outlined"
          fullWidth
          name="applicantName"
          value={state.applicantName}
        />

        <TextField
          required
          label="Mobile Number"
          name="mobileNumber"
          value={state.mobileNumber}
          onChange={(e) => handleChange(e)}
          inputProps={{
            pattern: "^[1-9]{1}[0-9]{9}$",
            title: "Enter 10 digit mobile number",
            maxLength: 10,
          }}
          error={!isValidMobile(state.mobileNumber) && (state.mobileNumber !== "")}
          helperText={(!isValidMobile(state.mobileNumber) && (state.mobileNumber !== "") )&& 'Invalid Mobile Number'}
        />

        <TextField
          required
          label="Email"
          name="email"
          value={state.email}
          onChange={(e) => handleChange(e)}
          inputProps={{
            pattern: "^[a-zA-Z0-9~!#$%^&*_+{}|?.'=`]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$",
            title: "Enter Valid Email",
          }}
          error={!isValidEmail(state.email) && state.email !== "" && state.email !== null}
          helperText={(!isValidEmail(state.email) && state.email !== "" && state.email !== null) && 'Invalid Email ID'}
        />


         
          {state.aadharStorage ? (
            <StyledAttachment>
              <a href={state?.aadharStorage?.fileUrl} target="_blank" rel="noopener noreferrer">
                <Typography gutterBottom variant="body2" color="rgba(0,0,0,0.8)">
                  {state?.aadharStorage?.name}
                </Typography>
              </a>
              <CloseOutlined
                onClick={()=>setState({...state,aadharStorageName: '',aadharStorage:null})}
                sx={{ cursor: "pointer" }}
                fontSize="small"
              />
            </StyledAttachment>
          ) : (
            <UploadImage
              name="aadharCard"
              type="organization"
              label="* Upload Aadhar"

              accept={["image/jpg", "image/jpeg", "application/pdf", "image/png"]}
              widthoutIcon
              sx={{
                minHeight: "80px",
              }}
                          onChange={(id, fileData) => setState({ ...state, aadharStorageName: fileData?.name, aadharStorage: { ...fileData, type: "organization", show: false } })}

            />
          )}
         
         {state.photoStorage? (
           <StyledAttachment>
             <a href={state?.photoStorage?.fileUrl} target="_blank" rel="noopener noreferrer">
               <Typography gutterBottom variant="body2" color="rgba(0,0,0,0.8)">
                 {state?.photoStorage?.name}
               </Typography>
             </a>
             <CloseOutlined
               onClick={()=>setState({...state,photoStorageName: '',photoStorage:null})}
               sx={{ cursor: "pointer" }}
               fontSize="small"
             />
           </StyledAttachment>
         ) : (
           <UploadImage
             name="photo"
             type="organization"
             label="* Upload Photo"

             accept={["image/jpg","image/jpeg","image/png"]}
             widthoutIcon
             sx={{
               minHeight: "80px",
             }}
             onChange={(id, fileData) => setState({ ...state, photoStorageName: fileData?.name, photoStorage: { ...fileData, type: "organization", show: false } })}

           />
         )}

         
         {state.panStorage ? (
           <StyledAttachment>
             <a href={state?.panStorage?.fileUrl} target="_blank" rel="noopener noreferrer">
               <Typography gutterBottom variant="body2" color="rgba(0,0,0,0.8)">
                 {state?.panStorage?.name}
               </Typography>
             </a>
             <CloseOutlined
               onClick={()=>setState({...state,panStorageName: '',panStorage:null})}
               sx={{ cursor: "pointer" }}
               fontSize="small"
             />
           </StyledAttachment>
         ) : (
           <UploadImage
             name="panCard"
             type="organization"
             label="* Upload PAN"

             accept={["image/jpg", "image/jpeg", "application/pdf", "image/png"]}
             widthoutIcon
             sx={{
               minHeight: "80px",
             }}
             onChange={(id, fileData) => setState({ ...state, panStorageName: fileData?.name, panStorage: { ...fileData, type: "organization", show: false } })}

           />
         )}
      
    
        <Button  onClick={handleSubmit} type="submit" variant="contained" size="large" sx={{ mt: 2 }}>
          Submit
        </Button>
      </Box>
    ) : null}

    {/* {state.selectedType === dscTypes.Apply_DSC_Organization?(
      <Box p={3} maxWidth={600} sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
<InputLabel id="org-type-label">Organization Type</InputLabel>
      <Select
        labelId="org-type-label"
        id="org-type"
        value={state.organizationTypeValue}
        onChange={(e) => handleChange(e)}
        name="organizationTypeValue"
        label="OrganizationType"
        style={{ width: '300px' }}
      >
        {orgTypesArray.map((type, index) => (
          <MenuItem key={index} value={type}>
            {getTitle(type)}
          </MenuItem>
        ))}
      </Select>

{state.organizationTypeValue ==="Government/PSU"? (
  <Box>Hello 1</Box>
):null}
        </Box>
    ): null} */}
  </Box>
  )
};

const IdentityCard = ({ title, value, file, onClose }) => {
    return (
      <Box
        sx={{
          background: "#F5F5F5",
          borderRadius: 2,
          py: 1,
          px: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Box>
          <Typography variant="body2" color="rgba(0,0,0,0.5)">
            {title}
          </Typography>
          <Typography variant="body1">{value ? value : "NA"}</Typography>
        </Box>
        {file && (
          <Box display="flex" gap={1}>
            <a href={file} target="_blank" rel="noopener noreferrer">
              <IconButton>
                <DownloadOutlined color="secondary" />
              </IconButton>
            </a>
            <IconButton onClick={onClose}>
              <Close color="secondary" />
            </IconButton>
          </Box>
        )}
      </Box>
    );
  };

export default AddApplyDsc