import { Box } from "@mui/material";
import { getOrganizationPreference } from "api/services/common";
import { snack } from "components/toast";
import useTitle from "hooks/useTitle";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { ResType } from "types";
import EproceedingFya from "views/atom-pro/eProceedingFYA";
import EproceedingFyi from "views/atom-pro/eProceedingFYI";
import { StyledProfileNavItem } from "views/clients/styles";

const IncomeTaxEProceedings = () => {
  useTitle("ATOM Pro | e-Proceedings");
  const navigate = useNavigate()
  const location = useLocation();
  const state = location.state as { tab?: string };
  const [active, setActive] = useState(state?.tab || "FYA");

  const { data: incomeTaxAccess, isLoading: isLoadingIncomeTaxAccess }: ResType = useQuery(
    [
      "Income Tax Access Check"
    ],
    getOrganizationPreference, {
    onSuccess: (res: any) => {
      if (res?.data?.automationConfig?.incomeTax === "YES") {

      } else {
        snack.error("Please subscribe Atom Pro Income tax")
        navigate("/atom-pro/incomeTaxLimit")
      }
    }
  }
  );

  return (
    <>
      <Box
        mb={1}
        display="flex"
        gap="15px"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        bgcolor="#F5F5F5"
      >

        <StyledProfileNavItem
          variant="body1"
          color="rgba(0,0,0,0.7)"
          active={active === "FYA" ? 1 : 0}
          onClick={() => setActive("FYA")}
          sx={{ marginLeft: "10px", }}
        >
          For Your Action
        </StyledProfileNavItem>
        <StyledProfileNavItem
          variant="body1"
          color="rgba(0,0,0,0.7)"
          active={active === "FYI" ? 1 : 0}
          onClick={() => setActive("FYI")}
        >
          For Your Information
        </StyledProfileNavItem>


      </Box>

      {active === "FYA" && <EproceedingFya tab="FYA" />}
      {active === "FYI" && <EproceedingFyi tab="FYI" />}


    </>
  )
}

export default IncomeTaxEProceedings;





