import { Box, Card, Grid, Typography } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const UpdatesMycas = ({ myCas }) => {
    const navigate = useNavigate()
    // const handleBoxClick = (id: any) => {
    //     navigate(`/atom-pro/income-tax/e-challan/${id}`);
    // };
    return (
        <>
             {myCas && myCas?.newItem && myCas?.newItem?.length > 0 && (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5" color={"primary"}>New My Chartered Accounts : {myCas?.newItem?.length}</Typography>
                    </Grid>

                    {myCas?.newItem?.map(item => (
                        <Grid item xs={4} key={item.id}>
                            <Card >
                                <Grid item sx={{ marginBottom: 1 }} bgcolor={"#ffffff"} m={0.5} padding={0.5}>
                                    <Typography variant="body2"> <span style={{ fontWeight: "600", color: "#00264d" }}>CA Name : </span> {item?.caName}</Typography>
                                    <Typography variant="body2"> <span style={{ fontWeight: "600", color: "#00264d" }}>CA Membership # : </span> {item?.caMembershipNum}</Typography>
                                    <Typography variant="body2"> <span style={{ fontWeight: "600", color: "#00264d" }}>PAN : </span> {item?.panNumber}</Typography>
                                    <Typography variant="body2" ><span style={{ fontWeight: "600", color: "#00264d" }}>Assessment Year : </span> {item?.assmentYear}</Typography>
                                    <Typography variant="body2" ><span style={{ fontWeight: "600", color: "#00264d" }}>Assigned Date : </span> {moment(item?.assignedDate).format('MMM DD, YYYY')}</Typography>
                                    <Typography variant="body2" ><span style={{ fontWeight: "600", color: "#00264d" }}>Assigned Form : </span> {item?.formTypeCd}</Typography>
                                </Grid>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
        </>
    )
};

export default UpdatesMycas;