import { http } from "api/http";

const getClientPermissions = () => {
    return http.get("/client-permissions/permissions");
};

const getPermissionClient = ({ queryKey }: any) => {
    return http.get(`/client-permissions/${queryKey[1]}`);
};

const updateClientPermissions = ({ id, data }) => {
    return http.put(`/client-permissions/${id}`, data);
};
  

export {
    getClientPermissions,
    updateClientPermissions,
    getPermissionClient,
}