import { DatePicker, DesktopDatePicker } from "@mui/lab";
import { Autocomplete, Box, Button, Grid, TextField, Typography } from "@mui/material"
import { getInvoicePreference, updateWeekendData } from "api/services/invoice.preferencess";
import DrawerWrapper from "components/DrawerWrapper"
import { snack } from "components/toast";
import moment from "moment";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { DialogProps } from "types"
import { getHoursOptions, getMinutesOptions } from "utils";

function AddHoliday({ open, setOpen }: DialogProps) {
    const queryClient = useQueryClient();
    const [holiday, setHoliday] = useState("");
    const [date, setDate] = useState<any>(null)
    const [holidaysList, setHolidaysList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [forFocus, setForFocus] = useState(true);

    const handleChange = (e: any) => {
        setHoliday(e.target.value)
    }

    const { mutate, isLoading: insertLoad } = useMutation(updateWeekendData, {
        onSuccess: () => {
            snack.success("Preferences Updated");
            queryClient.invalidateQueries("attendance-preferences");
            setOpen(false);
            setDate(null);
            setHoliday("");
            setIsLoading(false);
        },
        onError: (err: any) => {
            snack.error(err);
            setIsLoading(false);
        }
    });

    const addedHoliday = () => {
        if (!holiday.length) {
            return snack.error("Please Add Holiday");
        }
        if (!date) {
            return snack.error("Please Add Holiday Date");
        }
        if (holiday?.trim().length < 5) {
            return snack.error("Holiday Minimum 5 Character !")
        }
        if (date !== null) {
            const DatesList = holidaysList.map((item: any) => moment(item.date).format("DD-MM-YYYY"))
            const filteredDate = DatesList.filter((item) => item === moment(date)?.format("DD-MM-YYYY"))
            if (filteredDate.length) {
                return snack.error("Holiday Date Already Exists");
            } else {
                setIsLoading(true);
                const apiData = { typeofdata: "addholiday", data: { holiday: holiday.trim(), date } }
                mutate({ data: apiData });
            }
        }
    }

    const { data: invoicePreferences, isLoading: isInvoicePreferencesLoading } = useQuery(
        "attendance-preferences",
        getInvoicePreference,
        {
            onSuccess: (res: any) => {
                if (res?.data?.holidayPreferences?.addholiday === undefined) {
                    setHolidaysList([]);
                } else {
                    setHolidaysList(res?.data?.holidayPreferences?.addholiday);
                }
            },
            enabled: open,
        }
    );

    const handleSelect = () => {
        setIsDatePickerOpen(false);
        setForFocus(false);
    };

    const handleFocus = () => {
        if (forFocus)
            setIsDatePickerOpen(true);
        setForFocus(true);
    };

    return (
        <DrawerWrapper open={open} title="Add Holiday" setOpen={setOpen}>
            <Grid container>
                <Grid item xs={12}>
                    <Box sx={{ mt: 1 }}>
                        <DesktopDatePicker
                            label="Date"
                            inputFormat="dd-MM-yyyy"
                            value={date}
                            onChange={(value) => {
                                setDate(value);
                                handleSelect();
                            }}
                            open={isDatePickerOpen}
                            renderInput={(params) => (
                                <TextField
                                    sx={{ mt: 1 }}
                                    required
                                    fullWidth size="small"
                                    {...params}
                                    onKeyDown={(e) => {
                                        if (!(e.key === "Tab" || e.key === "Backspace" || e.key === "Delete")) {
                                            e.preventDefault();
                                        }
                                    }}
                                    onClick={() => { setIsDatePickerOpen(true); }}
                                    onFocus={handleFocus}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <TextField
                            sx={{ mt: 2 }}
                            variant="outlined"
                            fullWidth
                            name="holiday"
                            required
                            onChange={handleChange}
                            value={holiday}
                            size="small"
                            label="Holiday Description"
                            inputProps={{ maxLength: 50, minLength: 5 }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box>
                <Button sx={{ mt: 2 }} disabled={isLoading} fullWidth variant="contained" onClick={addedHoliday} color="secondary">Add</Button>
            </Box>
        </DrawerWrapper>
    )
}
export default AddHoliday;
