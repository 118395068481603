export const formatToRupees = (number) => {
    if (typeof number !== 'number' || isNaN(number)) {
        return 'Invalid input';
    }
    return number.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 2, maximumFractionDigits: 2
    });
};

export const formatToRupeess = (number) => {
    if (typeof number !== 'number' || isNaN(number)) {
        return '-.-';
    }
    return number.toLocaleString('en-IN', {
        currency: 'INR',
        minimumFractionDigits: 0, maximumFractionDigits: 0
    });
};


export const formatToRupeesWithOutDecemils = (number) => {
    if (typeof number !== 'number' || isNaN(number)) {
        return 'Invalid input';
    }
    return number.toLocaleString('en-IN', {
        currency: 'INR',
    });
};




export const formattedAmount = (amount) => {
    const parsedAmount = parseFloat(amount);
    const normalizedAmount = Math.abs(parsedAmount) < 0.005 ? 0 : parsedAmount;
    return normalizedAmount.toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'INR',
    });
};



