import { Box, Breadcrumbs, Divider, Grid, IconButton, styled, Typography } from "@mui/material";
import { LinkRouter } from "components/BreadCrumbs";
import useTitle from "hooks/useTitle";
import { useNavigate, useParams } from "react-router-dom";

import { useState } from "react";
import { ResType } from "types";
import { useQuery } from "react-query";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

import { StyledText1 } from "views/atom-pro/styles";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { formatToRupeess } from "utils/formattedAmount";
import Loader from "components/Loader";
import { getDemandData } from "api/services/automation/income-tax";
import moment from "moment";
import AccessDenied from "./Access/accessDenide";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


interface StyledSpanProps {
  bgColor?: string; // Define bgColor prop here
}

const StyledSpan = styled("span")<StyledSpanProps>(({ theme, bgColor }) => ({
  backgroundColor: bgColor || "#fffff",
  padding: "3px",
  fontSize: "16px",
  fontWeight: "500",
  borderRadius: "5px",
  color: "#222222",
}));

function OutstandingDemandView() {
  useTitle("Demands");
  const params = useParams();
  const navigate = useNavigate();
  const [state, setInitialState] = useState<any>({});

  const { data, isLoading: demandIsLoading }: ResType = useQuery(
    ["income-demands", params.id],
    getDemandData, {
    onSuccess: (res) => {
      setInitialState(res?.data)
    }
  });

  const generateAssessmentYear = (assessmentYear) => {
    if (!assessmentYear || assessmentYear === "0" || assessmentYear === "") {
      return "NA";
    } else if (!isNaN(assessmentYear) && assessmentYear.length === 4) {
      const year = parseInt(assessmentYear);
      const nextYear = (year + 1).toString().slice(-2);
      return `AY ${year}-${nextYear}`;
    } else {
      return assessmentYear;
    }
  };

  const TimelineComponent = ({ dateOfDemandRaised }) => {
    return (
      <Timeline position="left">
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {state?.currentStatus ? "Current Status" : ""}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot
              sx={{
                backgroundColor: "#182F53",
                borderRadius: "50%",
                width: "16px",
                height: "16px",
                boxShadow: "inset 0 0 0 2px #fff",
              }}
            />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h6" component="span">
              {state?.currentStatus ? state?.currentStatus : ""}
            </Typography>
          </TimelineContent>
        </TimelineItem>
        {dateOfDemandRaised.map((item, index) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent
              sx={{ m: "auto 0" }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              {Object.keys(item)[0] || ""}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot
                sx={{
                  backgroundColor: "#182F53",
                  borderRadius: "50%",
                  width: "16px",
                  height: "16px",
                  boxShadow: "inset 0 0 0 2px #fff",
                }}
              />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography variant="h6" component="span">
                {moment(item[Object.keys(item)[0]]).format("DD MMM YYYY") || ""}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    );
  };
  if (demandIsLoading) return <Loader />;

  return (

    <>
      {!state && (
        <AccessDenied />
      )}

      {state && (
        <>
          <Box height="80vh" overflow='auto'>
          <IconButton onClick={() => navigate(-1)}>
                  <ArrowBackIcon fontSize="small" /> 
                  <Typography variant="subtitle2" ml={1}>Outstanding Demand List</Typography>
                </IconButton>
            <Grid container >
              <Grid item xs={8} sx={{ borderRight: "1px solid #d9d9d9" }}>
                <Typography
                  sx={{ marginBottom: "20px", color: "#2F343A", paddingLeft: "10px" }}
                  variant="subtitle1"
                >
                  Demand Info (DIN):{state.demandReferenceNo}
                </Typography>
                <Typography sx={{ paddingLeft: "10px" }} variant="subtitle2">
                  Rectification Right
                </Typography>

                <Grid item xs={12} p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box
                        style={{
                          border: "1.5px solid #d1d1e0",
                          padding: "10px",
                          marginBottom: "10px",
                          borderRadius: "7px",
                        }}
                      >
                        <StyledText1 variant="h6" color="primary" mb={1}>
                          PAN
                        </StyledText1>
                        <StyledSpan
                        // bgColor={getRandomColor()}
                        >{state?.pan}</StyledSpan>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box
                        style={{
                          border: "1.5px solid #d1d1e0",
                          padding: "10px",
                          marginBottom: "10px",
                          borderRadius: "7px",
                        }}
                      >
                        <StyledText1 variant="h6" color="primary" mb={1}>
                          Assessment Year
                        </StyledText1>
                        <StyledSpan
                        // bgColor={getRandomColor()}
                        >
                          {generateAssessmentYear(state.assessmentYear)}
                        </StyledSpan>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box
                        style={{
                          border: "1.5px solid #d1d1e0",
                          padding: "10px",
                          marginBottom: "10px",
                          borderRadius: "7px",
                        }}
                      >
                        <StyledText1 variant="h6" color="primary" mb={1}>
                          Current Status
                        </StyledText1>
                        <StyledSpan
                        // bgColor={getRandomColor()}
                        >
                          {state?.currentStatus}
                        </StyledSpan>
                      </Box>
                    </Grid>

                    <Grid item xs={4}>
                      <Box
                        style={{
                          border: "1.5px solid #d1d1e0",
                          padding: "10px",
                          marginBottom: "10px",
                          borderRadius: "7px",
                        }}
                      >
                        <StyledText1 variant="h6" color="primary" mb={1}>
                          Demand Raised Date
                        </StyledText1>
                        <StyledSpan
                        // bgColor={getRandomColor()}
                        >
                          {state?.demandRaisedDate ? moment(state?.demandRaisedDate).format("DD MMM YYYY") : '--'}
                        </StyledSpan>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box
                        style={{
                          border: "1.5px solid #d1d1e0",
                          padding: "10px",
                          marginBottom: "10px",
                          borderRadius: "7px",
                        }}
                      >
                        <StyledText1 variant="h6" color="primary" mb={1}>
                          Section
                        </StyledText1>
                        <StyledSpan
                        //  bgColor={getRandomColor()}
                        >
                          {state?.sectionCodeText ? state?.sectionCodeText : "--"}
                        </StyledSpan>
                      </Box>
                    </Grid>

                  </Grid>
                </Grid>


                <Box>
                  <Box padding={1} sx={{ backgroundColor: "#F6F6F6" }}>
                    <Typography variant="subtitle2">Demand Amount Breakup</Typography>
                  </Box>

                  <Box display="flex" justifyContent="space-between" p={1} pl={2} pr={2}>
                    <Typography variant="body2" sx={{ color: "#2F2F2F", fontWeight: 600 }}>
                      Original Demand Amount
                    </Typography>
                    <Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{ backgroundColor: "#EAFFE7", fontWeight: 600 }}
                      >
                        <CurrencyRupeeIcon sx={{ width: "15px" }} />{" "}
                        {formatToRupeess(state?.originalOutstandingDemandAmount)}
                      </Box>
                    </Typography>
                  </Box>

                  <Box display="flex" justifyContent="space-between" p={1} pl={2} pr={2}>
                    <Typography sx={{ color: "#A4A4A4" }} variant="body2">
                      <span style={{ color: "#2F2F2F", fontWeight: 600 }}>Rate of Interest </span>(
                      {state?.rateOfInterest} % for month or part of month)
                    </Typography>
                    <Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{ backgroundColor: "#EAFFE7", fontWeight: 600 }}
                      >
                        <CurrencyRupeeIcon sx={{ width: "15px" }} />{" "}
                        {formatToRupeess(state?.accruedInterestComputed)}
                      </Box>
                    </Typography>
                  </Box>
                  <Divider sx={{ my: 1 }} />

                  <Box display="flex" justifyContent="space-between" p={1} pl={2} pr={2}>
                    <Typography sx={{ color: "#2F2F2F", fontWeight: 600 }} variant="body2">
                      Interest Start Date
                    </Typography>
                    <Typography>{moment(state?.interestStartDate).format("DD MMM YYYY")}</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" p={1} pl={2} pr={2}>
                    <Typography sx={{ color: "#2F2F2F", fontWeight: 600 }} variant="body2">
                      Accrued interest computation updated as on
                    </Typography>
                    <Typography>{moment().format("DD MMM YYYY")}</Typography>
                  </Box>


                  <Box display="flex" justifyContent="space-between" p={1} pl={2} pr={2}>
                    <Typography sx={{ color: "#2F2F2F", fontWeight: 600 }} variant="body2">
                      Time Period
                    </Typography>
                    <Typography>{state?.timePeriod} Months</Typography>
                  </Box>

                  <Box display="flex" justifyContent="space-between" p={1} pl={2} pr={2}>
                    <Typography sx={{ color: "#2F2F2F", fontWeight: 600 }} variant="body2">
                      Accrued Interest Computed
                    </Typography>
                    <Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{ backgroundColor: "#EAFFE7", fontWeight: 600 }}
                      >
                        <CurrencyRupeeIcon sx={{ width: "15px" }} />{" "}
                        {formatToRupeess(state?.accruedInterestComputed)}
                      </Box>
                    </Typography>
                  </Box>

                  <Box display="flex" justifyContent="space-between" p={1} pl={2} pr={2}>
                    <Typography sx={{ color: "#2F2F2F", fontWeight: 600 }} variant="body2">
                      Outstanding Demand Amount
                    </Typography>
                    <Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{ backgroundColor: "#EAFFE7", fontWeight: 600 }}
                      >
                        <CurrencyRupeeIcon sx={{ width: "15px" }} />{" "}
                        {formatToRupeess(state?.outstandingDemandAmount)}
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              </Grid>


              <Grid item xs={4} p={2}>
                <Typography variant="subtitle2" sx={{ color: "#667085" }}>
                  Timeline
                </Typography>
                {state && state.dateOfDemandRaised && (
                  <TimelineComponent dateOfDemandRaised={state?.dateOfDemandRaised} />
                )}
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>

  );
}

export default OutstandingDemandView;
