import { http } from "api/http";

const saveToken = (data: any) => {
  return http.post("/notifications/token", data);
};

const getNotifications = ({ queryKey }) => {
  return http.get("/notifications", {
    params: {
      ...queryKey[1],
    },
  });
};

const updateNotifications = (data) => {
  return http.patch("/notifications/update", data);
};

const getNotificationsCount = () => {
  return http.get("/notifications/count");
};

export { saveToken, getNotifications, updateNotifications, getNotificationsCount };
