import { http } from "api/http";

interface IUpdateData {
  data: {};
}

const getInvoicePreference = () => {
  return http.get(`/organization-preferences/getInvoicePreferences`, {});
};

const insertOrganizationpreference = ({ data }: IUpdateData) => {
  return http.post(`/organization-preferences/update`, data);
};

const insertHolidaypreference = ({ data }: IUpdateData) => {
  return http.post("/organization-preferences/updateholiday", data);
};

const updateWeekendData = ({ data }: IUpdateData) => {
  return http.post("/organization-preferences/updateWeekendData", data);
};

const deleteHoliday = (data) => {
  return http.patch("/organization-preferences/deleteHoliday", data);
};

const getTaskPreferences = ({ queryKey }: any) => {
  return http.get(`/organization-preferences/getTaskPreferences/${queryKey[1]}`, {});
};

const updateTaskPreferences = ({ data }) => {
  return http.post("/organization-preferences/update-task-preferences", data);
};

const getClientAction = () => {
  return http.get("/organization-preferences/getClientAction");
};

const getClientPreferences = () => {
  return http.get("/organization-preferences/getClientPreferences");
};

const updateClientPreferences = ({ data }) => {
  return http.put("/organization-preferences/getClientPreferences", data);
};

const getClientPrefix = () => {
  return http.get("/organization-preferences/clientPrefix");
};

const addClientPrefix = ({ data }) => {
  return http.post("/organization-preferences/addClientPrefix", data);
};

const getClientPrefixId = () => {
  return http.get("/organization-preferences/clientPrefixId");
};

const getClientGroupPrefixId = () => {
  return http.get("/organization-preferences/clientGroupPrefixId");
};

export {
  getInvoicePreference,
  insertOrganizationpreference,
  insertHolidaypreference,
  updateWeekendData,
  deleteHoliday,
  getClientAction,
  getClientPreferences,
  updateClientPreferences,
  getClientPrefix,
  addClientPrefix,
  getClientPrefixId,
  updateTaskPreferences,
  getTaskPreferences,
  getClientGroupPrefixId,
};
