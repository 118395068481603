import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import atomByViderLogo from "assets/images/atomByViderLogo.png"
import { useContext, useState } from "react";
import PanDetails from "views/signup/PanDetails";
import ViderServer from "./viderserver";
import CustomSmtp from "./customsmtp";
import { useImmer } from "use-immer";
import BottomBar from "components/BottomBar";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getOrganization, getSmtp, updateOrganization, updateSmtp } from "api/services/organization";
import { snack } from "components/toast";
import { ResType } from "types";
import { UserProfileContext } from "context/UserProfile";
import BlockIcon from "@mui/icons-material/Block";
import { useConfirm } from "context/ConfirmDialog";
import AllCustomSMTPMail from "./all-custom-mail";
import SMTPPreferences from "./smtp-preferences";

const CommunicationDetails = () => {
    const [state, setState] = useState<any>(true);
    const [showButton, setShowButton] = useState(true);
    const confirm = useConfirm();
    const [initialData, setInitialData] = useState<any>({
        "pass": "",
        "user": "",
        "host": "",
        "service": "",
        "port": "",
        "name": ""
    });
    const [smtp, setSmtp] = useState<any>({
        "pass": "",
        "user": "",
        "host": "",
        "service": "",
        "port": "",
        "name": ""
    });
    const { data: uData } = useContext(UserProfileContext);

    const queryClient = useQueryClient();
    const [role, setRole] = useState(uData?.role?.name);
    const [stateMails, setStateMail] = useState<any>({});
    const [mailsData, setMailsData] = useState([]);
    const { data: orgData, isLoading: orgLoading }: ResType = useQuery(["organization"], getOrganization, {
        onSuccess: (res) => {
            setMailsData(res?.data?.smtp);
            setStateMail(res?.data);
            setSmtp(initialData)
        }
    });

    const { data }: ResType = useQuery(["organizationSmtp"], getSmtp, {

        onSuccess: (res: any) => {
            if (res.data === "" || res.data === null || res.data === undefined) {
                setState(true);
                setSmtp({});
                setShowButton(true);
            } else {
                setState(false);
                setShowButton(false);
            }
        },
        onError: (err: any) => {
            if (!err?.error) {
                snack.error(`An error occurred while fetching organization details`);
            }
        },
        cacheTime: 0,
        enabled: true
    });

    const { mutateAsync, isLoading } = useMutation(updateSmtp, {
        onSuccess: () => {
            snack.success("A New SMTP server configuration Added !");
            queryClient.invalidateQueries("organization");
            queryClient.invalidateQueries("organizationSmtp");
        },
        onError: (err: any) => {
            if (err && err?.response && err?.response?.data && err?.response?.data?.message) {
                snack.error(err.response.data.message);
            } else if (!err?.error) {
                snack.error(`An error occurred while Updating Organization Profile`);
            } else {
                snack.error("Please Enter Valid Smtp Details");
            }
        },
    });

    const onSubmit = async () => {
        const { ...data } = { ...uData.organisation, ...smtp };
        if (state) {
            try {
                confirm({
                    msg: "Make sure all the information you entered for your customized SMTP server is correct ?",
                    action: async () => {
                        await mutateAsync({ data: {} });
                    },
                });
            } catch (error: any) {
                snack.error(error);
            }
        } else {
            if (data.service === "Gmail" || data.service === "outlook") {
                if (data.name.trim().length === 0) {
                    snack.error("Please Enter Valid From Name")
                } else if (data.user.trim().length === 0) {
                    snack.error("Please Enter Valid Email")
                } else if (data.pass.trim().length === 0) {
                    snack.error("Please Enter Valid Password")
                } else {
                    try {
                        confirm({
                            msg: "Make sure all the information you entered for your customized SMTP server is correct ?",
                            action: async () => {
                                await mutateAsync({ data })
                            },
                        });
                    } catch (error) {
                        snack.error("");
                    }
                }
            } else if (data.service === "yahoo") {
                if (data.name.trim().length === 0) {
                    snack.error("Please Enter Valid From Name")
                } else if (data.user.trim().length === 0) {
                    snack.error("Please Enter Valid Email")
                } else if (data.pass.trim().length === 0) {
                    snack.error("Please Enter Valid Password")
                } else {
                    try {
                        confirm({
                            msg: "Make sure all the information you entered for your customized SMTP server is correct ?",
                            action: async () => {
                                await mutateAsync({ data })
                            },
                        });
                    } catch (error) {
                        console.error("Error occurred during mutateAsync:", error);
                    }
                }
            }
        }
    };
    return (
        <Box sx={{ display: 'flex', justifyContent: 'start', marginLeft: "50px" }}>
            {role === 'Admin' ? (
                <Grid container mr={5}>
                    <Grid item xs={12} mt={3} >
                        <FormControl>
                            <FormLabel sx={{ fontSize: '20px', fontWeight: 'bold' }}>Send Email From</FormLabel>
                            <RadioGroup
                                onChange={(event) => {
                                    setState(event.target.value === 'true')
                                }}
                                value={state ? 'true' : 'false'}
                                row
                            >
                                <FormControlLabel
                                    value="true"
                                    control={<Radio />}
                                    label="Vider Server"
                                />
                                <FormControlLabel
                                    value="false"
                                    control={<Radio />}
                                    label="Custom SMTP"
                                />
                            </RadioGroup>
                        </FormControl>
                        {!state && <CustomSmtp smtp={smtp} setSmtp={setSmtp} onSubmit={onSubmit} />}
                        {!state && <>
                            <Divider sx={{ m: 5 }} />
                            <AllCustomSMTPMail mailsData={mailsData} setMailsData={setMailsData} isLoading={orgLoading} />
                            <Divider sx={{ m: 5 }} />
                            <SMTPPreferences stateMails={stateMails} setStateMail={setStateMail} isLoading={orgLoading} />
                        </>}
                        {state && !showButton && <Box sx={{ mt: 5 }}>
                            <Button
                                onClick={onSubmit}
                                disabled={isLoading}
                                size="large"
                                color="secondary"
                                variant="contained"
                            >
                                Update
                            </Button>
                        </Box>}
                    </Grid>
                </Grid>
            ) : (
                <Box
                    minHeight="80vh"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginLeft={60}
                >
                    <Box
                        p={2}
                        borderRadius={2}
                        minWidth={300}
                        textAlign="center"
                        bgcolor="rgb(253, 237, 237)"
                    >
                        <BlockIcon
                            fontSize="large"
                            sx={{
                                color: "rgb(95, 33, 32)",
                            }}
                        />
                        <Typography variant="subtitle2" color="rgb(95, 33, 32)">
                            Permission Denied
                        </Typography>
                    </Box>
                </Box>
            )}
        </Box>
    );


};
export default CommunicationDetails;