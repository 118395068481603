import { Box } from "@mui/material"
import { getQuantumConfig } from "api/services/quantum";
import { useState } from "react";
import { useQuery } from "react-query";
import { ResType } from "types";
import Loader from "components/Loader";
import QuantumPage from "./quantum-page";
import ClientWiseQuantumDocuments from "./client-qtm-documents";

const ClientQuantumDocuments = () => {
    const [load, setLoad] = useState(true)
    const [quantumConfig, setQauntumConfig] = useState(false)
    const { data, isLoading }: ResType = useQuery("quantum-config",
        getQuantumConfig, {
        onSuccess: (result: any) => {
            if (result?.data) {
                const quantumCheck = result?.data?.quantumConfig?.quantum
                if (quantumCheck) {
                    setQauntumConfig(quantumCheck)
                }
            }
            setLoad(false)
        },
        onError: (err: any) => {
            setLoad(false)
        }
    });

    if (load) return <Loader />
    return (
        <Box>
            {
                quantumConfig ?
                    <Box>
                        <ClientWiseQuantumDocuments />
                    </Box>
                    :
                    <QuantumPage />
            }
        </Box>
    )
}

export default ClientQuantumDocuments