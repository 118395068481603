import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

interface IState {
    expenseNature: any[],
    expenseType: any[];
    users: any[],

};


const filterState: IState = {
    expenseNature: [],
    expenseType: [],
    users: [],

};


interface InitialState {
    selectedFilters: IState;
    appliedFilters: IState;
}

const initialState: InitialState = {
    selectedFilters: filterState,
    appliedFilters: filterState,
}


const expenditureSlice = createSlice({
    name: "expenditure",
    initialState,
    reducers: {
        handleFieldChange(state, action: PayloadAction<{ key: string, value: any }>) {
            state.selectedFilters[action.payload.key] = action.payload.value
        },

        addFilters(state, action: PayloadAction<{ values: Partial<IState> }>) {
            const updates = action.payload.values;
            Object.keys(updates).forEach(key => {
                if (state.selectedFilters.hasOwnProperty(key)) {
                    state[key] = updates[key];
                }
            });
        },


        handleApply(state: InitialState) {
            state.appliedFilters = state.selectedFilters;

        },

        resetState(state) {
            state.selectedFilters = filterState;
            state.appliedFilters = filterState;
        },

        removeIndexFilter(state, action: PayloadAction<{ filterKey: string, filterItemIndex: number }>) {
            const selectedFilterArray = state.selectedFilters[action.payload.filterKey];
            const appliedFilterArray = state.appliedFilters[action.payload.filterKey];

            if (selectedFilterArray && selectedFilterArray.length > 0) {
                selectedFilterArray.splice(action.payload.filterItemIndex, 1);
            }

            if (appliedFilterArray && appliedFilterArray.length > 0) {
                appliedFilterArray.splice(action.payload.filterItemIndex, 1);
            }
        }

    }


});

export const { handleFieldChange, resetState, addFilters, handleApply, removeIndexFilter } = expenditureSlice.actions

export const selectExpenditure = (states: RootState) => states.expenditure;
export default expenditureSlice.reducer
