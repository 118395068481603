import { object, string, mixed, ValidationError } from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import countries from 'data/countries';


let createClientDefaultValues = {
  displayName: "",
  category: "",
  subCategory: "",
  clientGroup: "",
  clientManager: null,
  authorizedPerson: "",
  designation: "",
  email: "",
  mobileNumber: "",
  gstRegistered: "yes",
  gstNumber: "",
  panNumber: "",
  firstName: "",
  lastName: "",
  fullName: "",
  legalName: "",
  tradeName: "",
  placeOfSupply: "",
  constitutionOfBusiness: "",
  gstVerified: false,
  panVerified: false,
  clientPortalAccess: "no",
  clientNumber: "",
  countryCode: 'IN',
  dialCode: "91",
};

let CreateClientSchema = ({ subCategoriesExist }) =>
  object().shape({
    displayName: string()
      .required("Display Name is required")
      .max(100, "Display Name must be less than 100 characters"),
    tradeName: string().notRequired(),
    clientManager: object().nullable().notRequired(),
    category: string().required("Category is required"),
    subCategory: mixed().when("category", {
      is: (category: any) => subCategoriesExist(category),
      then: string().required("Sub Category is required"),
      otherwise: string().notRequired(),
    }),
    clientGroup: object().nullable().notRequired(),
    email: string()
      .test(
        "email",
        "Invalid email address",
        (value) =>
          value === undefined ||
          /^[a-zA-Z0-9~!#$%^&*_+{}|?.'=`]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
      )
      .required("Email is required"),
    mobileNumber: string()
      .required("Mobile number is required")
      .test("mobileNumber", "Invalid mobile number", function (value): boolean | ValidationError {
        const { countryCode, dialCode } = this.parent;
        if (!countryCode || !dialCode) {
          return false;
        }
        const code: any = countries.find((c) => c.code === countryCode);
        if (code?.phone) {
          const phoneNumber = parsePhoneNumberFromString(`+${dialCode}${value}`, code?.code);
          if (phoneNumber && phoneNumber.isValid()) {
            return true
          } else {
            return false;
          }
        }
        throw new ValidationError("Country and dialCode are required");
      }),
    authorizedPerson: string()
      .max(50, "Authorized Person must be less than 50 characters")
      .notRequired(),
    designation: string().max(50, "Designation must be less than 50 characters").notRequired(),
    gstRegistered: string().notRequired(),
    gstNumber: string().notRequired(),
    legalName: string().notRequired(),
    placeOfSupply: string().notRequired(),
    constitutionOfBusiness: string().notRequired(),
    panNumber: string().notRequired(),
    firstName: string().notRequired(),
    lastName: string().notRequired(),
    fullName: string().notRequired(),
    middleName: string().notRequired(),
    clientPortalAccess: string().required("Client Portal Access is required"),
    clientNumber: string().notRequired(),
    dialCode: string()
      .required("Dial Code is required"),
    countryCode: string().required("Country is required"),
  });

export { createClientDefaultValues, CreateClientSchema };
