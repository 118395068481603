import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";

interface Props {
  label?: string;
  name: string;
  size?: "small" | "medium";
  control: any;
  options: Array<{ label: string; value: string | boolean }>;
  row?: boolean;
  onChange?: (value: any) => void;
  required?: boolean;
  disabled?: boolean;
}

function FormRadio(props: Props) {
  const {
    name,
    size = "small",
    control,
    label = "",
    options,
    row = false,
    onChange,
    required = false,
    disabled = false,
  } = props;

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <FormControl size={size} fullWidth sx={{ marginBottom: "10px" }}>
              <div style={{ display: 'flex', alignItems: 'space-between' }}>
                <FormLabel id={name} style={{ marginRight: '20px', marginTop: "10px" }}>
                  {label} {required ? "*" : ""}
                </FormLabel>
                <RadioGroup
                  row={row}
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    onChange && onChange(e.target.value);
                  }}
                  value={field.value}
                >
                  {options.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Radio
                          color="secondary"
                          size="small"
                          value={item.value}
                          disabled={disabled}
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </RadioGroup>
              </div>
            </FormControl>
            {error && (
              <Typography
                variant="caption"
                sx={{ pl: "2px" }}
                color="rgb(211, 47, 47)"
              >
                {error.message}
              </Typography>
            )}
          </>
        )}
      />
    </>
  );
}

export default FormRadio;
