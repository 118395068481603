import { http } from "api/http";

const createdTemplates = ({ queryKey }) => {
  return http.get(`/quantum/createdTemplates`, { params: { ...queryKey[1] } });
};

const syncToQuantum = () => {
  return http.get("/quantum/syncToQuantum");
};

const downloadQuantumDocument = (data: any) => {
  return http.post("/quantum/downlaod-document", data);
};

const deleteQuantumDocument = (data: any) => {
  return http.delete(`quantum/delete-document/${data?.id}`);
};

const getPreviewDetails = ({ queryKey }: any) => {
  return http.get(`/quantum/getPreviewDetails/${queryKey[1]}`);
};

const getQuantumTemplates = ({ queryKey }) => {
  return http.get("/quantum/qtm-tempaltes", { params: { ...queryKey[1] } });
};

const getQtmCategories = () => {
  return http.get("/quantum/qtm-categories");
};

const getClientDocuments = ({ queryKey }: any) => {
  return http.get(`/quantum/client-documents`, { params: { ...queryKey[1] } });
};

const getQuantumAnalytics = () => {
  return http.get(`/quantum/quantum-analytics`);
};

const getQuantumDocumentAnalytics = () => {
  return http.get("/quantum/quantum-user-analytics");
};

const getQuantumConfig = () => {
  return http.get("/quantum/config");
};

const quantumRequest = () => {
  return http.post("/quantum/sendRequest");
};

export {
  createdTemplates,
  getPreviewDetails,
  syncToQuantum,
  getQuantumTemplates,
  getQtmCategories,
  downloadQuantumDocument,
  getClientDocuments,
  getQuantumAnalytics,
  getQuantumDocumentAnalytics,
  getQuantumConfig,
  quantumRequest,
  deleteQuantumDocument,
};
