import { Autocomplete, Box, Button, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, TextField, Typography } from "@mui/material";
import { getUsersForReport } from "api/services/users";
import Loader from "components/Loader";
import { useQuery } from "react-query";
import { ResType } from "types";
import { DesktopDatePicker } from "@mui/lab";
import moment from "moment";
import { useState } from "react";
import EventIcon from "@mui/icons-material/Event";
import { IState } from "pages/reports/employee-attendance-report";
import { snack } from "components/toast";


interface Props {
    state: IState;
    setState: (state: IState) => void;
    onSubmit: () => void;
    isloading: boolean;
}

function Filters({ state, setState, isloading, onSubmit }: Props) {
    const { data, isLoading }: ResType = useQuery(["users"], getUsersForReport,{
        onSuccess: (res: any) =>{
            setState({
                ...state,
                users: res?.data?.map((item: any) => item?.id),
            });
        }
    });

    const startDate = new Date('2000-01-01');
    const endDate = new Date(moment().format("YYYY-MM-DD"));
    const monthYearOptions: any = [];
    const currentDate = new Date(endDate);

    while (currentDate >= startDate) {
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();

        const formattedDate = `${(currentMonth + 1).toString().padStart(2, '0')}-${currentYear}`;
        monthYearOptions.push(formattedDate);
        currentDate.setMonth(currentDate.getMonth() - 1);
    }

    const handleMonthChange = (event: any) => {
        if (event.target.name === "fromMonth") {
            if(moment(event.target.value, "MM-YYYY") > moment(state.toMonth,"MM-YYYY")){
                return snack.error("Please Select Vaild Month !")
            } else {
                setState({ ...state, fromMonth: event.target.value })
            }
        } else if (event.target.name === "toMonth") {
            if(moment(event.target.value, "MM-YYYY") < moment(state.fromMonth,"MM-YYYY")){
                return snack.error("Please Select Vaild Month !")
            } else {
                setState({ ...state, toMonth: event.target.value })
            }
        }
    };

    if (isLoading) return <Loader />;

    return (
        <Paper sx={{ mt: 2, p: 2, py: 4 }}>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
                    <TextField
                        size="small"
                        select
                        label="From Month"
                        fullWidth
                        value={state.fromMonth}
                        name="fromMonth"
                        onChange={handleMonthChange}
                    >
                        {monthYearOptions.map((option, index) => (
                            <MenuItem key={index} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <TextField
                        size="small"
                        select
                        label="To Month"
                        fullWidth
                        value={state.toMonth}
                        name="toMonth"
                        onChange={handleMonthChange}
                    >
                        {monthYearOptions.map((option, index) => (
                            <MenuItem key={index} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6} sm={6} lg={6}>
                    <FormControl fullWidth size="small" sx={{ flex: 1 }}>
                        <InputLabel id="demo-multiple-checkbox-label">Users*</InputLabel>
                        <Select
                            multiple
                            fullWidth
                            value={state.users}
                            onChange={(e, v: any) => {
                                setState({ ...state, users: e.target.value as string[] });
                            }}
                            input={<OutlinedInput label="Users *" />}
                            renderValue={(selected) => {
                                return data?.data
                                    ?.filter((item: any) => selected.includes(item.id))
                                    .map((item: any) => item.fullName)
                                    .join(", ");
                            }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        maxHeight: "300px",
                                    },
                                },
                            }}
                        >
                            <Box display={"flex"} alignItems={"center"} m={1} >
                                <Checkbox
                                    checked={data?.data?.length === state.users.length}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setState({
                                                ...state,
                                                users: data?.data?.map((item: any) => item?.id),
                                            });
                                        } else {
                                            setState({ ...state, users: [] });
                                        }
                                    }}
                                    sx={{ marginRight: "10px" }}
                                />
                                <Typography> Select All  </Typography>
                            </Box>

                            {data?.data?.map((item: any) => (
                                <Box display={"flex"} alignItems={"center"} m={1} >
                                    <Checkbox
                                        checked={state.users.indexOf(item?.id) > -1}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                if (state.users.includes(item.id)) {
                                                    const userList = state.users.filter((eachitem) => eachitem !== item.id);
                                                    setState({
                                                        ...state,
                                                        users: [...userList]
                                                    })
                                                } else {
                                                    setState({
                                                        ...state,
                                                        users: [...state.users, item.id]
                                                    })
                                                }
                                            } else {
                                                const userList = state.users.filter((eachitem) => eachitem !== item.id);
                                                setState({
                                                    ...state,
                                                    users: [...userList]
                                                })
                                            }
                                        }}
                                        sx={{ marginRight: "10px" }}
                                    />
                                    <Typography>{item?.fullName}</Typography>
                                </Box>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Box>
                <Button sx={{ mt: 4 }} disabled={isloading} onClick={onSubmit} color="secondary" variant="contained">
                    Generate Report
                </Button>
            </Box>
        </Paper>
    );
}

export default Filters;