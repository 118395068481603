import { SortDirection } from "data/constants";
import { ColumnType } from "components/Table";

interface SortParams {
    key: string;
    columns: Array<ColumnType>;
    sortState: any;
    setSortState: React.Dispatch<React.SetStateAction<any>>;
    setColumns: React.Dispatch<React.SetStateAction<ColumnType[]>> | any;
}

const sortHandler = ({
    key, columns, sortState, setSortState, setColumns
}: SortParams) => {
    const updatedColumns = columns.map((col) => {
        if (col.key === key) {
            const direction =
                col.direction === SortDirection.ASC
                    ? SortDirection.DESC
                    : SortDirection.ASC;

            setSortState({ column: col.key, direction });

            return {
                ...col,
                active: true,
                direction,
            };
        } else {
            return {
                ...col,
                active: false,
                direction: SortDirection.ASC,
            };
        }
    });

    setColumns(updatedColumns);
};

export default sortHandler