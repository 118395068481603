import { MenuItem, TextField, Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getRoles } from "api/services/roles";
import { getAllUsers } from "api/services/users";
import Loader from "components/Loader";
import SearchContainer from "components/SearchContainer";
import { snack } from "components/toast";
import { useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { ResType } from "types";
import Table, { ColumnType } from "components/Table";
import CreateIcon from '@mui/icons-material/Create';
import { useNavigate } from "react-router-dom";
import { Visibility } from "@mui/icons-material";
import { getCountryCode } from "validations/phoneNumbr";
import sortHandler from "components/Table/SortHandler";
import _ from "lodash";
import { SortDirection } from "data/constants";

function NotificationUsers() {
    const [search, setSearch] = useState<string>("");
    const [role, setRole] = useState<string>("");
    const [allUsersData, setAllUsersData] = useState<any>([]);
    const [rolesData, setRolesData] = useState<any>([]);
    const navigate = useNavigate()
    const [sort, setSort] = useState<any>({});
    // useQuery(["deleted-gstr", {
    //     limit: pageCount,
    //     offset: page * pageCount,
    //     search: search,sort
    //   }], getDeletedGstrClients, {
    //     onSuccess: (res) => {
    //       setDeletedUsers(res?.data?.result);
    //       setTotalCount(res?.data?.count)
    //     },

    const { data, isLoading }: ResType = useQuery(["all-users", {
        sort
    }], getAllUsers, {
        onSuccess: (response) => {
            if (response && response?.data && response?.data?.length) {
                setAllUsersData(response.data);
            } else {
                setAllUsersData([]);
            }
        },
        onError: (err) => {
            if (!err?.error) {
                toast.error("An error occurred while fetching users");
            }

        }
    });

    const { data: roles, isLoading: rolesLoading }: ResType = useQuery("roles", getRoles, {
        onSuccess: (response) => {
            if (response && response?.data && response?.data?.length) {
                setRolesData(response?.data[0]);
            } else {
                setRolesData([]);
            }
        },
        onError: (err) => {
            if (!err?.error) {
                snack.error("An error occurred while fetching roles");
            }

        }
    });

    const defaultColumns: Array<ColumnType> = [
        {
            key: "fullName",
            title: "Full Name",
            sort: true,
            active: false,
            direction: SortDirection.ASC, default: true,
        },
        {
            key: "role.name",
            title: "Role",
            sort: true,
            active: false,
            direction: SortDirection.ASC, default: true,
        },
        {
            key: "mobileNumber",
            title: "Mobile Number",
            render: (row: any) => <Typography>{`+${getCountryCode(row?.countryCode) || 91} ${row?.mobileNumber}`}</Typography>

        },
        {
            key: "email",
            title: "Email ID",
        },
        {
            key: "Action",
            title: "Action",
            render: (item: any) => {
                return (
                    <Button
                        size="small"
                        onClick={() => addPreferencesClick(item)}
                    >
                        <Visibility />
                    </Button>
                );
            },
        },
    ]

    const [columns, setColumns] = useState(_.cloneDeep(defaultColumns));
    const handleSort = (v: any) => {
        sortHandler({
            key: v.key,
            columns,
            sortState: sort,
            setSortState: setSort,
            setColumns: setColumns,
        });
    };

    const getData = () => {
        let result = allUsersData || [];
        if (search) {
            result = result?.filter((user: any) => {
                return user.fullName.toLowerCase().includes(search.toLowerCase());
            });
        }

        if (role) {
            result = result?.filter((user: any) => {
                return user.role?.name === role;
            });
        }

        return result;
    };

    const addPreferencesClick = (data) => {
        navigate(`/settings/preferences/${data?.id}`)
    }

    if (isLoading || rolesLoading) return <Loader />;

    return getData()?.length ? (
        <Box p={1} sx={{ height: "80vh" }}>
            <Typography variant="h5" mb={2}>Notification</Typography>
            <Box display="flex" justifyContent={"space-between"}>
                <Box style={{ display: "flex", justifyContent: "space-between", width: "360px" }}>
                    <TextField
                        size="small"
                        select
                        onChange={(e) => setRole(e.target.value)}
                        label="Filter by Role"
                        value={role}
                        sx={{ minWidth: 200 }}
                    >
                        <MenuItem value="">- None -</MenuItem>
                        {rolesData.map((item: any, index: number) => (
                            <MenuItem value={item?.name} key={index}>
                                {item?.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
                <SearchContainer
                    value={search}
                    placeHolder="Search by User Name"
                    onChange={(v) => setSearch(v)}
                />

            </Box>

            <Box >
                <Table
                    sx={{ mt: 3, height: "67vh" }}
                    loading={isLoading}
                    data={getData() || []}
                    columns={columns}
                    sortHandler={handleSort}
                />
            </Box>
        </Box>) : (<Box p={1}>
            <Typography variant="h5" mb={2}>Notification</Typography>
            <Box display="flex" justifyContent="space-between">
                <Box style={{ display: "flex", justifyContent: "space-between", width: "360px" }}>

                    <TextField
                        size="small"
                        select
                        onChange={(e) => setRole(e.target.value)}
                        label="Filter by role"
                        value={role}
                        sx={{ minWidth: 200 }}
                    >
                        <MenuItem value="">- None -</MenuItem>
                        {rolesData.map((item: any, index: number) => (
                            <MenuItem value={item?.name} key={index}>
                                {item?.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
                <SearchContainer
                    value={search}
                    placeHolder="Search by User Name"
                    onChange={(v) => setSearch(v)}
                />

            </Box>
            <Box textAlign="center" mt={20}>
                <Typography variant="subtitle1" color="rgba(0,0,0,0.5)">
                    No data
                </Typography>
            </Box>
        </Box>)


}

export default NotificationUsers;