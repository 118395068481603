import { Box, Grid, List, Typography } from "@mui/material";
import { getOrganization, updateSmtp, updateSmtpMail } from "api/services/organization";
import { gmail, outlook, yahoo } from "assets";
import CloseIcon from '@mui/icons-material/Close';
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ResType } from "types";
import { snack } from "components/toast";

const AllCustomSMTPMail = ({ mailsData, setMailsData, isLoading }) => {
    const queryClient = useQueryClient();

    const { mutateAsync, isLoading: updateLoading } = useMutation(updateSmtpMail, {
        onSuccess: () => {
            snack.success("The SMTP server is Deleted !");
            queryClient.invalidateQueries("organization");
        },
        onError: (err: any) => {
            console.log(err);
            if (err && err?.response && err?.response?.data && err?.response?.data?.message) {
                snack.error(err.response.data.message);
            } else if (!err?.error) {
                snack.error(`An error occurred while Updating Organization Profile`);
            }
        },
    });

    const handleDelete = (key,item) => {
        const data = {[`${key}`]: item}
        mutateAsync({ data });
    }
    return <>{isLoading ? null :
        <Grid container sx={{ mt: 5 }}>
            <Grid item xs={2}>
                <Typography>List of Mail's Added</Typography>
            </Grid>
            <Grid item xs={9}>
                {mailsData && Object.entries(mailsData).length ? <Grid container sx={{ borderColor: "#C4C4C4", borderWidth: "2px", borderStyle: "solid", borderRadius: "10px", p: 1 }}>
                    {Object.entries(mailsData).map(([key, item]: [string, any]) => <Grid item xs={6} >
                        <Box display="flex" justifyContent="space-between" sx={{ p: 1, borderColor: "#F1F1F1", borderWidth: "3px", borderStyle: "solid", borderRadius: "6px", m: 1 }}>
                            <Typography>{item?.auth?.user}</Typography>
                            <Typography>
                                <img src={item.service === 'Gmail' ? gmail : item.service === 'outlook' ? outlook : item.service === 'yahoo' ? yahoo : ""} alt="down" height="20px" style={{ marginRight:10 }} />
                                <CloseIcon fontSize="small" onClick={() => handleDelete(key,item)} />
                            </Typography>
                        </Box>
                    </Grid>)}
                </Grid>: <Typography>No SMTP's</Typography>}
            </Grid>
        </Grid>
    }
    </>;
}

export default AllCustomSMTPMail
