import { Box, Typography } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";




const AccessDenied = () => {
    return (
        <Box
            minHeight="80vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Box
                p={2}
                borderRadius={2}
                minWidth={300}
                textAlign="center"
                bgcolor="rgb(253, 237, 237)"
            >
                <BlockIcon
                    fontSize="large"
                    sx={{
                        color: "rgb(95, 33, 32)",
                    }}
                />
                <Typography variant="subtitle2" color="rgb(95, 33, 32)">
                    Access Denied
                </Typography>
            </Box>
        </Box>
    )
};

export default AccessDenied;