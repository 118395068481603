import { FormControl, Grid, MenuItem, Select, TextField } from "@mui/material";
import SectionWrapper from "./SectionWrapper";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { useState } from "react";
import FormSelect from "components/FormFields/FormSelect";
import { STORAGE_SYSTEMS } from "data/constants";

function OrgConfigDetails({ state, setState }) {
  console.log("demooooo", state?.config?.demo)
  //const [demo, setDemo] = useState(state?.config?.demo || '');
  const handleChange = (e: any) => {
    setState((draft: any) => {
      if (e.target.name === "userslimit") {
        if (!isNaN(e.target.value))
          draft['config'][e.target.name] = e.target.value;
      } else {
        draft['config'][e.target.name] = e.target.value;
      }

    });
  };

  return (
    <SectionWrapper title="Configuration Details">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DesktopDatePicker
            label="Expiry Date"
            inputFormat="dd-MM-yyyy"
            disabled
            value={state?.config?.expirydate || ''}
            onChange={(v) => setState((draft: any) => {
              draft['config']['expirydate'] = v
            })
            }
            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            label="Users Limit"
            name="userslimit"
            disabled
            onChange={handleChange}
            value={state?.config?.userslimit || ''}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            label="Storage"
            name="storage"
            disabled
            onChange={handleChange}
            value={STORAGE_SYSTEMS.find(v => v.value === state.storageSystem)?.label || ''}
          />
        </Grid>

      </Grid>
    </SectionWrapper>
  );
}

export default OrgConfigDetails;
