import React, { useState } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Button, FormControl, Grid, IconButton, InputLabel, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import FileDrop from 'components/FileDrop';
import { Box } from '@mui/system';
import useTitle from 'hooks/useTitle';
import { Add} from '@mui/icons-material';
import UploadImage from 'components/UploadImage';
import { getAppliedDsc, postApplyDsc } from 'api/services/clients/dsc-register';
import { snack } from 'components/toast';
import { handleError } from 'utils/handleError';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ResType } from 'types';
import Table from "components/Table";
import { useNavigate } from 'react-router';


const ApplyDsc = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [search, setSearch] = useState("");

  const { isLoading,data, error }: ResType = useQuery(
    ["dsc", { search }],
    getAppliedDsc,
    { retry: false }
  );
 

 

  useTitle("Apply DSC");


  return (
    <Box px={1}  sx={{ pt:isSmallScreen?13: 3 }}>
      <Box sx={{display:"flex",justifyContent:"end",marginRight:"40px"}}>
    <Button
              onClick={() => navigate("/dsc-register/apply-dsc/add")}
              variant="outlined"
              color="secondary"
              startIcon={<Add />}
            >
              Apply
            </Button>
            </Box>
            <Box sx={{ mt:1  }}>

    <Table
    data={data?.data || []}
    columns={[
      { key: "applicantName", title: "Applicant Name" },
      { key: "mobileNumber", title: "Mobile Number" },
      { key: "selectedType", title: "Type" },

    //   { key: "organization", title: "Org Name",
    //   render: (rowData) => rowData.organization ? rowData.organization.legalName : "" 
    // },
      { key: "email", title: "email" },
      { key: "applicationStatus", title: "Application Status" },
    //   { key: "dscAadhar", title: "Aadhar",
    //   render: (rowData) => rowData.dscAadhar ? rowData.dscAadhar.fileUrl
    //   : "" 
    // },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (item) => {
    //     return (
    //       <Box display="flex" gap={1}>
    //         <IconButton
    //           onClick={() => {
    //             navigate(`/dsc-apply/${item?.id}`);
    //           }}
    //           size="small"
    //         >
    //           <Visibility fontSize="small" />
    //         </IconButton>
    //       </Box>
    //     );
    //   },
    // },
    
    ]}
    loading={isLoading}
    // pagination={{
    //   totalCount: data?.data?.totalCount || 0,
    //   pageCount: limit,
    //   setPageCount: setLimit,
    //   page: offset,
    //   setPage: setOffset,
    // }}
  />
   </Box>
    </Box>
  );
};


export default ApplyDsc;

