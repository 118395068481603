import { Box } from "@mui/material"

const IncomeTaxOrder = () => {
    return (
        <Box>
            This is income tax Order
        </Box>
    )
}

export default IncomeTaxOrder