import { cronHttp } from "api/cronHttp";
import { http } from "api/http";

const LogHoursReport = (data: any) => {
  return http.post("/reports/log-hours", data);
};

const exportLogHoursReport = (data: any) => {
  return http.post("/reports/log-hours/export", data);
};

const LogHoursFeeReport = (data: any) => {
  return http.post("/reports/log-hours-fee", data);
};

const exportLogHoursFeeReport = (data: any) => {
  return http.post("/reports/log-hours-fee/export", data);
};

const getCommonBilling = (data: any) => {
  return http.post("/common/queryapi", data?.queryKey[1]);
};

const getClientListInvoice = (data) => {
  return http.post("/common/client-lsit-export", data);
};
const getClientGroupListInvoice = (data) => {
  return http.post("/common/client-group-lsit-export", data);
};


const getCommonBilledTaskexport = (data) => {
  return http.post("common/clientbilled-export", data);
};

const getCommonGroupBilledTaskexport = (data) => {
  return http.post("common/client-group-billed-export", data);
};

const getClientInvoiceBilling = (data) => {
  return http.post("common/client-invoice-export", data);
};

const getClientGroupInvoiceBilling = (data) => {
  return http.post("common/client-group-invoice-export", data);
};
const getClientProformaInvoiceBilling = (data) => {
  return http.post("common/client-proforma-invoice-export", data)
}

const getCommonUnBilledTaskexport = (data) => {
  return http.post("common/clientunbilled-export", data);
};

const getCommonGroupUnBilledTaskexport = (data) => {
  return http.post("common/client-group-unbilled-export", data);
};

const getCommonReceiptsexport = (data) => {
  return http.post("common/clientreceipts-export", data);
};

const getCommonGroupReceiptsexport = (data) => {
  return http.post("common/client-group-receipts-export", data);
};

const getCommonReport = (data: any) => {
  return http.post("/common/queryapi", data);
};

const exportCommonReport = (data: any) => {
  return http.post("/common/exportqueryapi", data);
};

const getEmployeeLogHoursReport = (data: any) => {
  return http.post("/reports/employee-log-hours", data);
};

const getEmployeeTimeSheetReport = (data: any) => {
  return http.post("/reports/employee-time-sheet", data);
};

const exportEmployeeTimeSheetReport = (data: any) => {
  return http.post("/reports/employee-time-sheet/export", data);
};

const getEmployeeAttendanceReport = (data: any) => {
  return http.post("/reports/employee-attendance", data);
};
const getEmployeeAttendanceandTimesheet = (data: any) => {
  return http.post("/reports/employee-attendance-and-timesheet", data);
};

const exportEmployeeAttendanceReport = (data: any) => {
  return http.post("/reports/employee-attendance/export", data);
};
const exportEmployeeAttendanceandTimesheet = (data: any) => {
  return http.post("/reports/employee-attendance-and-timesheet/export", data);
};

const exportEmployeeLogHoursReport = (data: any) => {
  return http.post("/reports/employee-log-hours/export", data);
};

const getClientsReport = (data: any) => {
  return http.post("/reports/clients", data);
};

const exportClientsReport = (data: any) => {
  return http.post("/reports/clients/export", data);
};

const getLeadsReport = (data: any) => {
  return http.post("/reports/leads", data);
};

const exportLeadsReport = (data: any) => {
  return http.post("/reports/leads/leadsexport", data);
};

const getUsersReport = (data: any) => {
  return http.post("/reports/all", data);
};

const exportUsersReport = (data: any) => {
  return http.post("/reports/all/userexports", data);
};

const getUsersInReport = (data: any) => {
  return http.post("/reports/all", data);
};

const exportUsersInReport = (data: any) => {
  return http.post("/reports/all/userexports", data);
};

const getInviteUsersReport = (data: any) => {
  return http.post("/reports/invited", data);
};

const exportInviteUsersReport = (data: any) => {
  return http.post("/reports/invited/userexports", data);
};

const getExpenditureReport = (data: any) => {
  return http.post("/reports/expenditure", data);
};

const exportExpenditureReport = (data: any) => {
  return http.post("/reports/expenditure/expenditureexport", data);
};
const getUserExpenditureReport = (data: any) => {
  return http.post("/reports/expenditure", data);
};

const getUserTaskReport = (data: any) => {
  return http.post("/reports/tasks/user", data);
};
const exportUserTaskReport = (data: any) => {
  return http.post("/reports/tasks/user/taskuserexport", data);
};

const exportUserExpenditureReport = (data: any) => {
  return http.post("/reports/expenditure/expenditureuserexport", data);
};
const getLoghoursReport = (data: any) => {
  return http.post("/reports/log-hours", data);
};

const exportLoghoursReport = (data: any) => {
  return http.post("/reports/log-hours/loghoursexport", data);
};

const exportTimeSheetReport = (data: any) => {
  return http.post("/reports/log-hours/timesheetexport", data);
};

const getUserLoghoursReport = (data: any) => {
  return http.post("/reports/log-hours", data);
};

const exportUserLoghoursReport = (data: any) => {
  return http.post("/reports/log-hours/loghoursuserexport", data);
};

const getDscReport = (data: any) => {
  return http.post("/reports/dsc-register", data);
};

const exportDscRegisterReport = (data: any) => {
  return http.post("/reports/dsc-register/dscregisterexport", data);
};

const getClientsPageReport = (data: any) => {
  return http.post("/reports/clients", data);
};
const exportClientsPageReport = (data: any) => {
  return cronHttp.post("/reports/clients/clientexport", data);
};
const exportClientsGroupPageReport = (data: any) => {
  return http.post("/reports/clientgroup/clientsgroupexport", data);
};

const getTasksReport = (data: any) => {
  return http.post("/reports/tasks", data);
};

const getSubTasksReport = (data: any) => {
  return http.post("/reports/subtasks", data);
};

const getTasksPageReport = (data: any) => {
  return http.post("/reports/tasks", data);
};

const exportTasksReport = (data: any) => {
  return http.post("/reports/tasks/export", data);
};
const exportSubTasksReport = (data: any) => {
  return http.post("/reports/subtasks/export", data);
};
//SETTTINGS USER REPORT
const getUserTasksPageReport = (data: any) => {
  return http.post("/reports/tasks/user", data);
};
const exportUserTasksPageReport = (data: any) => {
  return http.post("/reports/tasks/userTasksexport", data);
};

//manage user card report
const getUserCardTasksPageReport = (data: any) => {
  return http.post("/reports/tasks/user", data);
};
const exportUserCardTasksPageReport = (data: any) => {
  return http.post("/reports/tasks/userCardTasksexport", data);
};

//Upcoming recurring tasks report
const getUserRecurringTasksPageReport = (data: any) => {
  return http.post("/reports/tasks/recurring", data);
};
const exportUserRecurringTasksPageReport = (data: any) => {
  return http.post("/reports/tasks/recurring/upcomingrecurringreport", data);
};

//Completed tasks report
const getCompletedTasksReport = (data: any) => {
  return http.post("/reports/tasks/completedtasks", data);
};
const exportCompletedTasksReport = (data: any) => {
  return http.post("/reports/tasks/completedtasksreport", data);
};

//Deleted tasks report
const getDeletedTasksReport = (data: any) => {
  return http.post("/reports/tasks/deletedtasks", data);
};
const exportDeletedTasksReport = (data: any) => {
  return http.post("/reports/tasks/deletedtasksreport", data);
};

//deleted clients report
const getDeletedClientsPageReport = (data: any) => {
  return http.post("/reports/clients", data);
};
const exportDeletedClientsPageReport = (data: any) => {
  return http.post("/reports/clients/deletedclients", data);
};

const exportDeletedClientsGroupPageReport = (data: any) => {
  return http.post("/reports/clients/deletedclientsgroup", data);
};

//get Users Report
const geDeletedtUsersReport = (data: any) => {
  return http.post("/reports/users", data);
};

const exportDeletedUsersReport = (data: any) => {
  return http.post("/reports/users/deletedUsersReport", data);
};

//get client pending tasks report
const getClientPendingTasksReport = (data: any) => {
  return http.post("/reports/tasks", data);
};

const exportClientPendingTasksReport = (data: any) => {
  return http.post("/reports/tasks/clientPendingTasksReport", data);
};

const exportTasksDelayReport = (data: any) => {
  return http.post("/reports/tasks-delay/export", data);
};

const exportTasksPageReport = (data: any) => {
  return http.post("/reports/tasks/tasksexport", data);
};

const exportErrorList = (data: any) => {
  return http.post("/reports/errors", data);
};

export {
  exportEmployeeAttendanceandTimesheet,
  getEmployeeAttendanceandTimesheet,
  getClientListInvoice,
  getClientInvoiceBilling,
  getClientProformaInvoiceBilling,
  getCommonBilledTaskexport,
  getCommonReceiptsexport,
  getCommonUnBilledTaskexport,
  getClientPendingTasksReport,
  exportClientPendingTasksReport,
  geDeletedtUsersReport,
  exportDeletedUsersReport,
  getDeletedClientsPageReport,
  exportDeletedClientsPageReport,
  getDeletedTasksReport,
  exportDeletedTasksReport,
  getCompletedTasksReport,
  exportCompletedTasksReport,
  getEmployeeLogHoursReport,
  exportEmployeeLogHoursReport,
  getCommonReport,
  getCommonBilling,
  exportCommonReport,
  getClientsReport,
  exportClientsReport,
  exportClientsPageReport,
  getTasksReport,
  exportTasksReport,
  LogHoursReport,
  exportLogHoursReport,
  exportErrorList,
  LogHoursFeeReport,
  exportLogHoursFeeReport,
  getTasksPageReport,
  exportTasksPageReport,
  getClientsPageReport,
  getLeadsReport,
  exportLeadsReport,
  getDscReport,
  exportDscRegisterReport,
  getUsersReport,
  exportUsersReport,
  getUsersInReport,
  exportUsersInReport,
  getExpenditureReport,
  exportExpenditureReport,
  getLoghoursReport,
  exportLoghoursReport,
  exportInviteUsersReport,
  getInviteUsersReport,
  getEmployeeAttendanceReport,
  exportEmployeeAttendanceReport,
  getUserExpenditureReport,
  exportUserExpenditureReport,
  getUserLoghoursReport,
  exportUserLoghoursReport,
  getUserTaskReport,
  exportUserTaskReport,
  getUserTasksPageReport,
  exportUserTasksPageReport,
  getUserCardTasksPageReport,
  exportUserCardTasksPageReport,
  getUserRecurringTasksPageReport,
  exportUserRecurringTasksPageReport,
  exportTasksDelayReport,
  getSubTasksReport,
  exportSubTasksReport,
  getEmployeeTimeSheetReport,
  exportEmployeeTimeSheetReport,
  exportTimeSheetReport,
  getClientGroupListInvoice,
  getCommonGroupUnBilledTaskexport,
  getCommonGroupBilledTaskexport,
  getClientGroupInvoiceBilling,
  getCommonGroupReceiptsexport,
  exportClientsGroupPageReport,
  exportDeletedClientsGroupPageReport,
};
