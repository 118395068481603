import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import { exportIncomeTaxReturns, getIncomeTaxForms, getIncomeTaxReturns } from "api/services/automation/income-tax";
import SearchContainer from "components/SearchContainer";
import Table, { ColumnType } from "components/Table";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { ResType } from "types";
import { calculateAssessmentYear, clientCategories } from "./constants";
import moment from "moment";
import CategoryIcon from "@mui/icons-material/Category";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import useTitle from "hooks/useTitle";
import { getAutFinancialYears } from "utils/getFinancialYears";
import { snack } from "components/toast";
import { getOrganizationPreference } from "api/services/common";
import { getCurrentFormattedDateTime } from "utils";
import { handleError } from "utils/handleError";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import _ from "lodash";
import sortHandler from "components/Table/SortHandler";
import { SortDirection } from "data/constants";

const itrTypes = {
  "1": "ITR-1",
  "2": "ITR-2",
  "2A": "ITR-2A",
  "3": "ITR-3",
  "4": "ITR-4",
  "4S": "ITR-4S",
  "5": "ITR-5",
  "6": "ITR-6",
  "7": "ITR-7",
};

const filingTypes = {
  O: "Original Return",
  D: "Defective Return",
  R: "Revised Return",
  T: "Rectification Return",
  U: "Updated Return",
};

const verificationStatus = {
  Y: "Verified",
  N: "Not Verified", //red
  X: "NA",
  empty: "None"

};

const IncomeTaxReturns = () => {
  useTitle("ATOM Pro | Returns");
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [filters, setFilters] = useState<any>({
    itrType: null,
    filingType: null,
    assessmentYear: null,
    status: null,
    clientCategory: null,
  });

  const location = useLocation();
  const state: any = location.state;

  const { data: incomeTaxAccess, isLoading: isLoadingIncomeTaxAccess }: ResType = useQuery(
    [
      "Income Tax Access Check"
    ],
    getOrganizationPreference, {
    onSuccess: (res: any) => {
      if (res?.data?.automationConfig?.incomeTax === "YES") {

      } else {
        snack.error("Please subscribe Atom Pro Income tax")
        navigate("/atom-pro/incomeTaxLimit")
      }
    }
  }
  );
  const [sort, setSort] = useState<any>({});

  const { data, isLoading }: ResType = useQuery(
    [
      "income-tax-returns",
      {
        search: search,
        limit: pageCount,
        offset: page * pageCount,
        ...filters,
        sort,
      },
    ],
    getIncomeTaxReturns,
    {
      onSuccess: (res) => {
        setTotalCount(res?.data?.count);
      },
    }
  );

  useEffect(() => {
    if (state?.filingType) {
      setFilters({ ...filters, filingType: state?.filingType, assessmentYear: state?.assessmentYear });
      setPage(0);
    } else if (state?.status) {
      setFilters({ ...filters, status: state?.status, assessmentYear: state?.assessmentYear });
      setPage(0);
    } else if (state?.itrType) {
      setFilters({ ...filters, itrType: state?.itrType, assessmentYear: state?.assessmentYear });
      setPage(0);
    }


  }, [state])

  const getFilingType = (type) => {
    const abc = filingTypes[type];
    return abc;
  };

  const getformType = (type) => {
    const abc = itrTypes[type];
    return abc;
  };

  const getCategoryBackgroundColor = (category) => {
    switch (category) {
      case "individual":
        return "#FCF4C5";
      case "huf":
        return "#E0F7FA";
      case "partnership_firm":
        return "#FFE0B2";
      case "llp":
        return "#D1C4E9";
      case "company":
        return "#C8E6C9";
      case "aop":
        return "#FFCCBC";
      case "boi":
        return "#C5CAE9";
      case "trust":
        return "#F8BBD0";
      case "government":
        return "#B2DFDB";
      case "local_authority":
        return "#FFD54F";
      case "artificial_judicial_person":
        return "#F0F4C3";
      case "corporations":
        return "#D7CCC8";
      default:
        return "#FFFFFF";
    }
  };

  let defaultColumns: Array<ColumnType> = [
    {
      key: "assmentYear",
      title: "Assessment Year",
      sort: true,
      active: false,
      direction: SortDirection.ASC, default: true,
      render: (row: any) => (
        <Typography
          variant="body2"

        >
          AY {calculateAssessmentYear(row.assmentYear)}
        </Typography>
      ),
    },
    {
      key: "category",
      sort: true,
      active: false,
      direction: SortDirection.ASC, default: true,
      title: (
        <Box display="flex" alignItems="center">
          <CategoryIcon style={{ width: "20px", marginRight: "5px" }} />
          Category
        </Box>
      ),
      render: (v) => {
        const categoryLabel =
          clientCategories.find((category) => category.value === v?.client?.category)?.label || "";
        const backgroundColor = getCategoryBackgroundColor(v?.client?.category);

        return (
          <Typography
          >
            {categoryLabel}
          </Typography>
        );
      },
    },
    {
      key: "clientId",
      sort: true,
      active: false,
      direction: SortDirection.ASC, default: true,
      title: (
        <Box display="flex" alignItems="center">
          <PersonOutlineOutlinedIcon style={{ width: "20px", marginRight: "5px" }} /> Name | PAN
        </Box>
      ),
      render: (row) => (
        <Typography
        >
          {row?.client?.displayName} (
          <span
          >
            {row?.autClientCredentials?.panNumber}
          </span>
          )
        </Typography>
      ),
    },

    {
      key: "formtypeCd",
      title: "ITR",
      sort: true,
      active: false,
      direction: SortDirection.ASC, default: true,
      render: (row: any) => {
        return (
          <Typography
          >
            {getformType(row?.formtypeCd)}
          </Typography>
        );
      },
    },
    {
      key: "filingTypeCd",
      title: "Filing Type",
      sort: true,
      active: false,
      direction: SortDirection.ASC, default: true,
      render: (row: any) => {
        return (
          <Typography
          >
            {getFilingType(row?.filingTypeCd)}
          </Typography>
        );
      },
    },
    {
      key: "ackNum",
      title: "Acknowledgement #",
    },

    {
      key: "id",
      title: "Filing Date",
      sort: true,
      active: false,
      direction: SortDirection.ASC, default: true,
      render: (row: any) => {
        return (
          <Typography variant="body1">{moment(row.submitTmstmp).format("DD MMM YYYY")}</Typography>
        );
      },
    },
    {
      key: "verStatus",
      title: "Verification Status",
      sort: true,
      active: false,
      direction: SortDirection.ASC, default: true,

      render: (row: any) => (
        <Typography
          sx={{
            backgroundColor: row?.verStatus === 'Y' ? "#79c97a" : (row?.verStatus === 'N' ? "#ff3535" : ""),
            display: "inline-block",
            padding: "1px 5px",
            border: row?.verStatus ? `1px solid ${row?.verStatus === 'Y' ? "#79c97a" : (row?.verStatus === 'N' ? "#ff3535" : "")}` : "",
            borderRadius: "4px",
            color: "#222222",
            fontSize: "12px",
          }}
        >
          {row?.verStatus ? verificationStatus[row?.verStatus] : '--'}
        </Typography>
      ),
    },
  ];
  const [columns, setColumns] = useState(_.cloneDeep(defaultColumns));
  const handleSort = (v: any) => {
    sortHandler({
      key: v.key,
      columns,
      sortState: sort,
      setSortState: setSort,
      setColumns: setColumns,
    });
  };
  const handleRowClick = (v: any) => {
    navigate(`/atom-pro/income-tax/return/${v?.id}`);
  };

  const handleItrTypeChange = (e: any) => {
    setFilters({ ...filters, itrType: e.target.value });
    setPage(0);
  };

  const handleFilingTypeChange = (e: any) => {
    setFilters({ ...filters, filingType: e.target.value });
    setPage(0);
  };

  const handleStatusChange = (e: any) => {
    setFilters({ ...filters, status: e.target.value });
    setPage(0);
  };

  const onClientCategoryChange = (e: any) => {
    setFilters({ ...filters, clientCategory: e.target.value });
    setPage(0);
  };

  const handleAssessmentYearChange = (e: any) => {
    setFilters({ ...filters, assessmentYear: e.target.value });
    setPage(0);
  };

  const handleSearch = (e: any) => {
    setSearch(e)
    setPage(0)
  }

  const assessmentYear = getAutFinancialYears();
  const { mutate: mutate1 } = useMutation(exportIncomeTaxReturns, {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res.data?.data);
      const blob = new Blob([arr], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const file = window.URL.createObjectURL(blob);
      const formattedDateTime = getCurrentFormattedDateTime();

      let link = document.createElement("a");
      link.href = file;
      link.download = `ATOM Pro - Income Tax Returns ${formattedDateTime}.xlsx`;
      link.click();
      snack.success("Exported Successfully");
    },
    onError: (err: any) => {
      snack.error(err);
      snack.error(handleError(err));
    },
  });
  const handleExport = () => {
    mutate1({
      ...state,
      search: search,
      limit: pageCount,
      offset: page * pageCount,
      ...filters,
    });
  };
  return (
    <Box m={1}>
      <Box pr={2} pl={2} sx={{ display: "flex" }}>
        <Box display="flex">
          <Box ml={1}>
            <TextField
              label="Assessment Year"
              value={filters.assessmentYear || ""}
              sx={{ width: 120 }}
              onChange={handleAssessmentYearChange}
              size="small"
              select
            >
              <MenuItem key={""} value={""}>
                Select
              </MenuItem>
              {assessmentYear?.map((year, index) => (
                <MenuItem key={index} value={year.split("-")[0]}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box ml={1}>
            <TextField
              label="ITR"
              value={filters.itrType || ""}
              sx={{ width: 80 }}
              onChange={handleItrTypeChange}
              size="small"
              select
            >
              <MenuItem key={""} value={""}>
                Select
              </MenuItem>
              {Object.keys(itrTypes).map((key, index) => (
                <MenuItem key={index} value={key}>
                  {itrTypes[key]}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box ml={1}>
            <TextField
              label="Filing Type"
              value={filters.filingType || ""}
              sx={{ width: 100 }}
              onChange={handleFilingTypeChange}
              size="small"
              select
            >
              <MenuItem key={""} value={""}>
                Select
              </MenuItem>
              {Object.keys(filingTypes).map((key, index) => (
                <MenuItem key={index} value={key}>
                  {filingTypes[key]}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box ml={1}>
            <TextField
              name="clientCategory"
              value={filters.clientCategory || ""}
              onChange={onClientCategoryChange}
              size="small"
              label="Category"
              select
              sx={{ width: "180px" }}
            >
              <MenuItem key={""} value={""}>
                Select
              </MenuItem>
              {clientCategories.map((category) => (
                <MenuItem key={category.value} value={category.value}>
                  {category.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box ml={1} mr={1}>
            <TextField
              label="Status"
              value={filters.status || ""}
              sx={{ width: 100 }}
              onChange={handleStatusChange}
              size="small"
              select
            >
              <MenuItem key={""} value={""}>
                Select
              </MenuItem>
              {Object.keys(verificationStatus).map((key, index) => (
                <MenuItem key={index} value={key}>
                  {verificationStatus[key]}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
        <SearchContainer
          minWidth="250px"
          value={search}
          debounced
          placeHolder="Search by Client Name | PAN"
          onChange={handleSearch}
        />
        <Button
          onClick={() => {
            setFilters({})
            setSearch("")
          }}
          sx={{ marginLeft: '5px', marginRight: '5px' }}
          size="small"
          color="secondary"
          variant="outlined"
        >
          Clear Filters
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleExport}
          startIcon={<FileDownloadOutlinedIcon />}
        >
          Export
        </Button>
      </Box>

      <Box mt={1}>
        <Table
          pagination={{ totalCount, pageCount, setPageCount, page, setPage }}
          data={data?.data.result || []}
          columns={columns}
          sortHandler={handleSort}
          loading={isLoading}
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  );
};

export default IncomeTaxReturns;
