import { Close } from "@mui/icons-material";
import { Box, Dialog, IconButton, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

interface IDrawerWrapperProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  editOpen?: boolean;
  setEdit?: (editOpen: boolean) => void;
  title: string;
  children: React.ReactNode;
  width?: "xs" | "sm" | "md" | "lg" | "xl";
  showDot?: boolean;
  color?: string;
  edit?:boolean;
}

function DialogWrapper(props: IDrawerWrapperProps) {
  const { open, setOpen, title, children, width = "sm", showDot = false, color = "" } = props;

  return (
    <Dialog maxWidth={width} fullWidth open={open} onClose={() => setOpen(false)}>
      <Box
        sx={{
          display: "flex",
          boxShadow: "none",
          borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
          p: "0.3rem 1rem",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box display="flex">
          {showDot && (
            <span
              style={{
                background: color,
                marginTop: 9,
                width: 10,
                height: 10,
                borderRadius: "50%",
              }}
            ></span>
          )}
          <Typography variant="subtitle2" ml={1}>
            {title}
          </Typography>
        </Box>

        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </Box>
      <Box p={2}>{children}</Box>
    </Dialog>
  );
}

export default DialogWrapper;
