import Table, { ColumnType } from "components/Table";
import { getBroadcastActivityDetails } from "api/services/communication";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ResType } from "types";
import { getTitle } from "utils";
import AccessDenied from "../clientGroups/access-denied";
import Loader from "components/Loader";
import { Box, Typography } from "@mui/material";
import SearchContainer from "components/SearchContainer";
import { debounce } from "lodash";

const BroadcastMessageDetails = () => {
  const [profileData, setProfileData] = useState<any>("");
  const [search, setSearch] = useState("");
  const [accessCheck, setAccessCheck]= useState(true)

  const { broadcastActivityId } = useParams();

 
  const { data, isLoading, error }: ResType = useQuery(
    ["broadcastactivitydetails",{ id:broadcastActivityId, search:search}],
    getBroadcastActivityDetails,
    {
      onSuccess: (data) => {
        setProfileData(data?.data);
        setAccessCheck(data?.data?.checkAccess);
      },
    }
  );
console.log(data?.data?.checkAccess,'aaa')

  const handleSearch = (e) => {
    setSearch(e);
    // setPage(0);
  }
  if (isLoading) return <Loader />;
  return (
    <>
{!accessCheck  &&   (
      <AccessDenied />
  )}      {accessCheck && (
        <Box p={3} pb={0} px={1}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Box>
              <Typography mb={1} variant="subtitle2">
                Group Name - {data?.data?.getOneEmailTemplate?.groupName}
              </Typography>
              <Typography mb={1} variant="subtitle2">
                Email Template Name - {data?.data?.getOneEmailTemplate?.templateName}
              </Typography>
            </Box>
            <SearchContainer
              value={search}
              placeHolder="Search by Client Name | Email"
              onChange={handleSearch}
              debounced
              autoFocus
            />
          </Box>
          <Table sx={{ mt: 2 }} data={data?.data?.getOneEmailTemplate?.details || []} columns={defaultColumns} />
        </Box>
        // </Box>
      )}
    </>
  );
};
const defaultColumns = [
  {
    key: "clientName",
    title: "Client Name",
    render: (rowData) => getTitle(rowData?.clientName),
  },
  {
    key: "email",
    title: "Email",
    render: (rowData) => (rowData?.email),
  },
];
export default BroadcastMessageDetails;
