import { DateFilters, PriorityEnum, TaskStatus, TaskStatusFilter } from "data/constants";

type Filter = {
  title: string;
  key: string;
  options: Array<{ label: string; value: string }>;
};

export const getFilters = ({ users, labels }): Filter[] => {
  return [
    {
      title: "Created by",
      key: "createdBy",
      options: users?.data?.map((item: any) => ({
        label: item?.fullName,
        value: item?.id?.toString(),
      })),
    },
    {
      title: "Created on",
      key: "createdOn",
      options: Object.values(DateFilters).map((item) => ({
        label: item,
        value: item,
      })),
    },
    {
      title: "Billing Type",
      key: "billable",
      options: [
        {
          label: "Billable",
          value: "billable",
        },
        {
          label: "Non-Billable",
          value: "nonbillable",
        },
      ],
    },
    {
      title: "Assignee",
      key: "assignee",
      options: users?.data?.map((item: any) => ({
        label: item?.fullName,
        value: item?.id.toString(),
      })),
    },
    {
      title: "Task Leader",
      key: "taskLeader",
      options: users?.data?.map((item: any) => ({
        label: item?.fullName,
        value: item?.id?.toString(),
      })),
    },
    {
      title: "Priority",
      key: "priority",
      options: Object.values(PriorityEnum).map((item) => ({
        label: item,
        value: item,
      })),
    },
    {
      title: "Start Date",
      key: "startDate",
      options: Object.values(DateFilters).map((item) => ({
        label: item,
        value: item,
      })),
    },
    {
      title: "Task Type",
      key: "taskType",
      options: [
        {
          label: "Recurring",
          value: "recurring",
        },
        {
          label: "Non-Recurring",
          value: "non_recurring",
        },
      ],
    },
    {
      title: "Task Status",
      key: "status",
      options: Object.values(TaskStatusFilter).map((item) => ({
        label: item,
        value: item,
      })),
    },
    {
      title: "Expected Completion Date",
      key: "expectedCompletionDate",
      options: Object.values(DateFilters).map((item) => ({
        label: item,
        value: item,
      }))
    },
    {
      title: "Statutory Due Date",
      key: "dueOn",
      options: Object.values(DateFilters).map((item) => ({
        label: item,
        value: item,
      })),
    },

    {
      title: "Completed by",
      key: "completedBy",
      options: users?.data?.map((item: any) => ({
        label: item?.fullName,
        value: item?.id?.toString(),
      })),
    },
    {
      title: "Billing Status",
      key: "billingType",
      options: [
        {
          label: "Billed",
          value: "BILLED",
        },
        {
          label: "Un-Billed",
          value: "UNBILLED",
        }
      ],
    },
    // {
    //   title: "Tags",
    //   key: "tags",
    //   options: labels?.data?.map((item: any) => ({
    //     label: item?.name,
    //     value: item?.id?.toString(),
    //   })),
    // },
    // {
    //   title: "Completed on",
    //   key: "completedOn",
    //   options: Object.values(DateFilters).map((item) => ({
    //     label: item,
    //     value: item,
    //   })),
    // },
  ];
};
