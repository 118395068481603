import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

interface IState {
    category: any,
    subCategory: any[],
    jurisdiction: any,
    monthAdded: string,
    labels: any[],
    search: string;
    status: any;
};


const filterState: IState = {
    category: [],
    jurisdiction: [],
    subCategory: [],
    monthAdded: "",
    labels: [],
    search: "",
    status: ''


};


interface InitialState {
    selectedFilters: IState;
    appliedFilters: IState;


}

const initialState: InitialState = {
    selectedFilters: filterState,
    appliedFilters: filterState,

}


const gstRegisterSlice = createSlice({
    name: "gstRegister",
    initialState,
    reducers: {
        handleFieldChange(state, action: PayloadAction<{ key: string, value: any }>) {
            if (action.payload.key === "search") {
                state.selectedFilters[action.payload.key] = action.payload.value
                state.appliedFilters[action.payload.key] = action.payload.value
            }
            state.selectedFilters[action.payload.key] = action.payload.value
        },

        handleRemove(state, action: PayloadAction<{ key: string }>) {
            state.appliedFilters[action.payload.key] = filterState[action.payload.key];
            state.selectedFilters[action.payload.key] = filterState[action.payload.key];

        },

        addFilters(state, action: PayloadAction<{ values: Partial<IState> }>) {
            const updates = action.payload.values;
            Object.keys(updates).forEach(key => {
                if (state.selectedFilters.hasOwnProperty(key)) {
                    state[key] = updates[key];
                }
            });
        },

        handleApply(state: InitialState) {
            state.appliedFilters = state.selectedFilters;
        },

        resetState(state) {
            state.selectedFilters = filterState;
            state.appliedFilters = filterState;
        },
    }


});

export const { handleFieldChange, resetState, addFilters, handleApply, handleRemove } = gstRegisterSlice.actions

export const selectGstRegister = (states: RootState) => states.gstRegister;
export default gstRegisterSlice.reducer
