import countries from "data/countries";
import parsePhoneNumberFromString from "libphonenumber-js";

export const isValidMobile = (mobileNumber: Number, countryCode: any) => {
  let country = countries.find((c) => c.code === countryCode);
  const phoneNumber = parsePhoneNumberFromString(`+${country?.phone}${mobileNumber}`, countryCode);
  if (phoneNumber?.isValid()) {
    return true;
  } else {
    return false;
  }
};

export const getCountryCode = (code: any) => {
  const selectedCountry: any = countries.find((c) => c?.code === code);
  return selectedCountry?.phone;
};
