import { http } from "api/http";


const getWalletBalance = () => {
    return http.get("wallet/balance")
};

const recentTransactions = () => {
    return http.get("wallet/activity")
};

const recentdata=()=>{
    return http.get("wallet/history")
}

const getBalance = () => {
    return http.get("wallet/balance")
};

const getTotalAmount = () => {
    return http.get("wallet/debit-balance")
};

export {
    getWalletBalance,
    recentTransactions,
    getBalance,
    getTotalAmount,
    recentdata


};