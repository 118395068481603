import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import React, { useState } from "react";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

interface StateProps {
  msg: React.ReactNode;
  action: () => void;
  noAction: () => void;
}

type ContextProps = (args: StateProps) => void;

export const ConfirmClientDialogContext = React.createContext<ContextProps>(() => { });

function ConfirmClientDialogProvider({ children }: any) {
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState<StateProps>({
    msg: "",
    action: () => { },
    noAction: () => { },
  });

  const handleClose = () => {
    setLoading(true);
    state.noAction()
    setOpen(false);
  };

  const onOk = () => {
    setLoading(true);
    state.action();
    setOpen(false);
  };

  const confirm = (args: StateProps) => {
    const { msg, action, noAction } = args;
    setLoading(false);
    setOpen(true);
    setState({
      msg,
      action,
      noAction,
    });
  };

  return (
    <ConfirmClientDialogContext.Provider value={confirm}>
      {children}
      <Dialog maxWidth="xs" fullWidth open={open} onClose={handleClose}>
        <Box p={2}>
          <Box>
            <Typography color="primary" gutterBottom variant="subtitle1" display="flex" gap={1} alignItems="center" justifyContent="center">
              Warning  <WarningAmberRoundedIcon color="secondary" fontSize="medium" />
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {state.msg}
            </Typography>
          </Box>
          <Box mt={3} display="flex" gap={2} justifyContent="flex-end">
            <Button
              variant="outlined"
              color="secondary"
              sx={{ minWidth: 70 }}
              size="small"
              onClick={onOk}
              disabled={isLoading}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              sx={{ minWidth: 70 }}
              size="small"
              color="secondary"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Dialog>
    </ConfirmClientDialogContext.Provider>
  );
}

export const useClientConfirm = () => React.useContext(ConfirmClientDialogContext);

export default ConfirmClientDialogProvider;
