import { Divider, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { Visibility } from "@mui/icons-material";
import moment from "moment";
import Table from "components/Table";

type Props = {
  data: any;
};

function EproceedingFyiNoticesList({ data }: Props) {

  let columns = [
    {
      key: "documentIdentificationNumber",
      title: "DIN",
    },
    {
      key: "issuedOn",
      title: "Issued On",
      render: (item: any) => (<Typography>{item?.issuedOn ? moment(item?.issuedOn).format('DD MMM YYYY HH:mm') : "--"}</Typography>)
    },
    {
      key: "responseDueDate",
      title: "Response Due Date",
      render: (item: any) => (<Typography>{item?.responseDueDate ? moment(item?.responseDueDate).format('DD MMM YYYY HH:mm') : "--"}</Typography>)

    },
    {
      key: "",
      title: "Total Responses",
      render: (item: any) => (<Typography>{item?.responses?.length ? item?.responses?.length  : "0"}</Typography>)

    },
    {
      key: "id",
      title: "Actions",
      render: (rowData: any) => <Actions data={rowData} />,
    },


  ];

  const Actions = (props: any) => {
    const navigate = useNavigate();
    const handlerowClick = () => {
      navigate(`/atom-pro/income-tax/proceeding-information/notice-fyi/${props.data?.id}`);
    };
    return (
      <>
        <Box display="flex" gap={1}>
          <>
            <IconButton onClick={handlerowClick} size="small">
              <Visibility />
            </IconButton>
          </>
        </Box>
      </>
    );
  };

  return (


    <Box m={1}>
      <Table
        data={data || []}
        sx={{ height: "300px" }}
        columns={columns}
      />
      <Divider />
    </Box>
  );
}

export default EproceedingFyiNoticesList;
