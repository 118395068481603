import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { newlogo, signup } from "assets";
import { atom_logo } from "assets";
import { BackgroundImage, LogoContainer, ResponsiveImageContainer } from "views/login/styles";

function BackgroundWrapper({ children }: any) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} sm={6} lg={6} className="logo-in-page-container">
      <BackgroundImage>
          <LogoContainer>
            <img src={atom_logo} alt="" />
            <Box mt={6}>
              <img src={signup} alt="" />
            </Box>
            <Typography mt={2} variant="subtitle2" color="white">
              Vider Practice Management software
            </Typography>
            <Typography mt={1} variant="body2" color="rgba(255,255,255,0.7)">
              Vider is a technology-driven smart discovery that offers services addressing the growing needs of
              Professionals(CA/CMA/CS) & Clients in enhancing businesses in India.
            </Typography>
          </LogoContainer>
        </BackgroundImage>
      </Grid>
      <Grid xs={12} md={6} sm={6} lg={6} item>
      <ResponsiveImageContainer>
      <img src={atom_logo} alt="" style={{ width: '200px', height: '100px' }} />
          </ResponsiveImageContainer>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={isMobile ? "70vh" : "100vh"}
          sx={{ overflowY: "auto" }}
        >
          <Box maxWidth="400px" width="100%"  pt={isMobile?2:8} pb={isMobile?2:4} pr={isMobile?2:""} pl={isMobile?2:""}>
            {children}
          </Box>
        </Box>
        <ResponsiveImageContainer>
      <img src={signup} alt="" style={{ width: '200px', height: 'auto' }} />
          </ResponsiveImageContainer>
      </Grid>
    </Grid>
  );
}

export default BackgroundWrapper;
