import { http } from "api/http";

type AddAttachment = {
  taskId: number;
  data: FormData;
  collectId: number;
  origin: string;
};

const getActivity = ({ queryKey }) => {
  return http.get("/activity", { params: { ...queryKey[1] } });
};

const getStates = () => {
  return http.get("/common/states");
};

const invoiceUpload = (invoiceData: any) => {
  return http.post("/common/invoiceUpload", invoiceData);
};

const invoiceProformaUpload = (invoiceData: any) => {
  return http.post("/common/invoiceProformaUpload", invoiceData);
};

const receiptUpload = (invoiceData: any) => {
  return http.post("/common/receiptUpload", invoiceData);
};

const collectDataAdd = (data: any) => {
  console.log("Api", data.fields)
  return http.post("/collect-data", data);
};

const getCollectDataRefDocs = ({ queryKey }: any) => {
  return http.get(`/collect-data/reference/${queryKey[1]}`)
}

const updateCollectData = ({ id, data }: any) => {
  return http.put(`/collect-data/${id}`, data);
};

const getTaskCollectData = ({ queryKey }: any) => {
  return http.get(`/collect-data/task/${queryKey[1]}`);
};

const getCollectData = ({ queryKey }: any) => {
  return http.get(`/collect-data/${queryKey[1]}`);
};

const getCollectAttachments = ({ queryKey }: any) => {
  return http.get(`/collect-data/attachments/${queryKey[1]}`);
};

const addCollectAttachment = ({ origin, taskId, data, collectId }: AddAttachment) => {
  return http.post(`/collect-data/${taskId}/${collectId}/${origin}/attachments`, data);
};

const addConfirmAttachment = ({ origin, taskId, data, collectId }: AddAttachment) => {
  return http.post(`/collect-data/confirm-attachments/confirm`, data);
};

const collectDataUpdateComments = (data: any) => {
  return http.post("/collect-data/comments", data);
};

const invoiceReminder = (invoiceData: any) => {
  return http.post("/common/invoiceReminder", invoiceData);
};

const getOrganizationPreference = () => {
  return http.get("/common/getOrganizationPreference");
};

export {
  getActivity,
  getStates,
  invoiceUpload,
  receiptUpload,
  collectDataAdd,
  getCollectData,
  addCollectAttachment,
  getCollectAttachments,
  getTaskCollectData,
  updateCollectData,
  invoiceReminder,
  getOrganizationPreference,
  invoiceProformaUpload,
  addConfirmAttachment,
  collectDataUpdateComments,
  getCollectDataRefDocs
};
