import { Visibility } from "@mui/icons-material";
import { Box, Breadcrumbs, IconButton, Tooltip, Typography } from "@mui/material"
import { getClientDemand, getClientForm } from "api/services/automation/income-tax";
import Table, { ColumnType } from "components/Table";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ResType } from "types";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { formatToRupeess } from "utils/formattedAmount";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { LinkRouter } from "components/BreadCrumbs";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import moment from "moment";
import { StyledClientFilterItem } from "views/tasks/Filters/styles";
import ClientEproceedingFya from "./clientEProceedingFYA ";
import ClientEproceedingFyi from "./clientEProceedingFYI";

const ClientProceeding = () => {


  const [active, setActive] = useState("FYA");

  return (
    <Box pl={1} pr={1} mt={0} pt={0}>
      <Box pl={2} pr={2} mb={0} mt={0} pt={0} pb={0} display="flex" justifyContent="center" alignItems="center">
        <Box display="flex" gap="15px" flexWrap="wrap" alignItems="center">
          <StyledClientFilterItem
            variant="body1"
            color="rgba(0,0,0,0.7)"
            active={active === "FYA" ? 1 : 0}
            onClick={() => setActive("FYA") }
          >
            For Your Action
          </StyledClientFilterItem>
          <StyledClientFilterItem
            variant="body1"
            color="rgba(0,0,0,0.7)"
            active={active === "FYI" ? 1 : 0}
            onClick={() => setActive("FYI")}
          >
            For Your Information
          </StyledClientFilterItem>
        </Box>      
      </Box>
      {active === "FYA" && <ClientEproceedingFya tab="FYA" />}
      {active === "FYI" && <ClientEproceedingFyi tab="FYI" />}
    </Box>
  )
}


export default ClientProceeding;