import axios from "axios";
import { snack } from "components/toast";
import moment from "moment";
import { setGlobalLoading } from "redux/reducers/globalSlice";
import store from "redux/store";

export const skipurls = [
  "quantum",
  "/notifications/token",
  "/notifications/count",
  "/get-started",
  "/permissions/mine",
  "/users/profile",
  "/users/signin",
];

window["expirydate"] = false;
window["demo"] = false;

const trailExpired = (function () {
  let executed = false;
  let previousArgument = "";

  return function (arg: any) {
    if (!executed || arg !== previousArgument) {
      executed = true;
      previousArgument = arg;
      snack.error("Your Trial Period has expired");
    }
  };
})();

export const cronHttp = axios.create({
  baseURL: process.env.REACT_APP_CRON_API || "",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}` || "",
  },
});

cronHttp.interceptors.request.use(
  function (config) {
    store.dispatch(setGlobalLoading(true));
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

cronHttp.interceptors.response.use(
  function (response: any) {
    const config = response?.data?.organization?.config;

    if (response?.config?.url.includes("users/profile")) {
      let today = moment().format("YYYY-MM-DD");
      const expiryDate = moment(config?.expirydate, "YYYY-MM-DD");

      const duration = moment.duration(expiryDate.diff(today));
      const daysDifference = duration.asDays();
      window["expirydate"] = daysDifference < -1 ? true : false;
      window["demo"] = config?.demo == "yes" ? true : false;
    }

    const skipurl = skipurls.indexOf(response?.config?.baseURL + response?.config?.url) >= 0;

    if (!skipurl && window["expirydate"] && window["demo"]) {
      setTimeout(() => {
        trailExpired(window.location.href);
      }, 1000);

      store.dispatch(setGlobalLoading(false));

      return Promise.reject({ error: "Trail expired" });
    }
    if (
      (config?.subscriptionmode == "monthly" || config?.subscriptionmode == "yearly") &&
      window["expirydate"]
    ) {
      snack.error("Your subscription ended");

      if (!window.location.href.includes("subscription")) {
        window.location.href = "/subscription";
      }
    } else {
      store.dispatch(setGlobalLoading(false));
      return response;
    }
  },
  function (err) {
    store.dispatch(setGlobalLoading(false));

    if (err.message === "Network Error") {
      alert("Network Error");
    }
    // use for expiry date
    // if (
    //   err.response.data.statusCode === 422
    // ) {
    //   snack.error(err.response.data.message);
    // }
    if (err.response.data.statusCode === 401 && err.response.config.method === "get") {
      snack.error("Session expired. Please login again.");
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      window.location.href = "/login";
    }
    return Promise.reject(err);
  }
);
