import { Box, Card, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const UpdatesReturns = ({ returns }) => {
    const navigate = useNavigate()
    const handleBoxClick = (id: any) => {
        navigate(`/atom-pro/income-tax/return/${id}`);
    };
    return (
        <>
            {
                returns?.updateItem && returns?.updateItem?.length > 0 && (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h5" color={"primary"}>Updated Returns : {returns?.updateItem?.length}</Typography>
                        </Grid>
                        {returns?.updateItem?.map(item => (
                            <Grid item xs={4} >
                                <Card key={item.id} sx={{ padding: 1 }} onClick={() => handleBoxClick(item.id)} >

                                    <Typography variant="body2"><span style={{ fontWeight: "600", color: "#004d99" }}>Acknowledgment Number : </span>{item?.ackNum}</Typography>
                                    {item.changes && (
                                        <Box sx={{ marginTop: 1 }}>
                                            {item?.changes?.itrPanDetlList?.newList?.length > 0 && (
                                                <Box>
                                                    <Typography variant="body2"><span style={{ fontWeight: "600", color: "#004d99" }}>New Status : </span> {item?.changes?.itrPanDetlList?.newList?.[0]?.statusDesc}</Typography>
                                                </Box>
                                            )}
                                            {item?.changes?.itrPanDetlList?.oldList?.length > 0 && (
                                                <Box>
                                                    <Typography variant="body2"> <span style={{ fontWeight: "600", color: "#004d99" }}>Old Status : </span> {item?.changes?.itrPanDetlList?.oldList[0]?.statusDesc}</Typography>
                                                </Box>
                                            )}
                                            {item?.changes?.storageFiles?.lengthChange && (
                                                <Typography><span style={{ fontWeight: "600", color: "#004d99" }}>Attachments added : </span> New count : {item?.changes?.storageFiles?.lengthChange?.newLength} - Old count {item?.changes?.storageFiles?.lengthChange?.oldLength}</Typography>
                                            )}
                                        </Box>
                                    )}
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                )
            }

            {
                returns?.newItem && returns?.newItem?.length > 0 && (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h5" color={"primary"}>New Returns : {returns?.newItem?.length}</Typography>
                        </Grid>

                        {returns?.newItem?.map(item => (
                            <Grid item xs={4} key={item.id}>
                                <Card >
                                    <Grid item sx={{ marginBottom: 1 }} onClick={() => handleBoxClick(item.id)} bgcolor={"#ffffff"} m={0.5} padding={0.5}>
                                        <Typography variant="body2"> <span style={{ fontWeight: "600", color: "#00264d" }}>Acknowledgment Number : </span> {item?.ackNum}</Typography>
                                        <Typography variant="body2" ><span style={{ fontWeight: "600", color: "#00264d" }}>Assessment Year : </span> {item?.assmentYear}</Typography>
                                    </Grid>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                )
            }
        </>
    )
};

export default UpdatesReturns;