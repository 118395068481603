import { Box, Button, Divider, Typography } from "@mui/material";
import { quantumRequest } from "api/services/quantum";
import { Quantum, quantumRequestImg } from "assets";
import Loader from "components/Loader";
import { snack } from "components/toast";
import { useConfirm } from "context/ConfirmDialog";
import { useMutation } from "react-query";
import { handleError } from "utils/handleError";
import "pages/GridStyling.css";


const QuantumPage = () => {
  const confirm = useConfirm();
  const { mutate, isLoading } = useMutation(quantumRequest, {
    onSuccess: (res) => {
      snack.success("Quantum request sent successfully");
    },
    onError: (err: any) => {
      snack.error(handleError(err));
    },
  });

  const handleSendRequest = () => {
    confirm({
      msg: (
        <>
          <Typography mb={1}>
            Would you like to enhance your document drafting workflow with Quantum subscription. A representative will contact your Admin to discuss the details and ensure it aligns with your specific needs.
          </Typography>
          <Divider />
          <Typography mt={1} fontSize={"15px"}>
            Do you want to continue ?
          </Typography>
        </>
      ),
      action: () => {
        mutate();
      },
    });
  };

  if (isLoading) return <Loader />;

  return (
    <Box className="quantumPagePadding">
      <Typography variant="subtitle1" color="primary">
        Quantum
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="70vh"
        textAlign="center"
      >
        <Box maxWidth="500px" width="100%">
          <Typography variant="h5" mb={2}>
            REVOLUTIONIZE DOCUMENT CREATION WITH{" "}
            <span>
              {" "}
              <img src={Quantum} alt="quantum" width="100px" />
            </span>
          </Typography>
        </Box>
        <Box maxWidth="500px" width="100%">
          <Typography variant="h6">
            A Complete New Innovative Way For Creating Or Drafting Of Documents
            Through An Automated System.
          </Typography>
        </Box>
        <Box width="250px" maxWidth="100%">
          <img src={quantumRequestImg} alt="quantumRequest" width="100%" />
        </Box>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleSendRequest}
          style={{ minWidth: "150px" }}
        >
          SUBSCRIBE NOW
        </Button>
      </Box>
    </Box>
  );
};

export default QuantumPage;
