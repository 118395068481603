import { http } from "api/http";

const getDscRegisters = ({ queryKey }) => {
  return http.get("/dsc-register", { params: { ...queryKey[1] } });
};

const getClientDscRegistersexport = (sendata) => {
  const data = sendata;
  return http.post("/dsc-register/client-dsc-register-export", data);
};


const getClients = ({ queryKey }) => {
  return http.get("/dsc-register/clients", { params: { ...queryKey[1] } });
};

const createDscRegister = (data: any) => {
  return http.post("/dsc-register", data);
};

const updateDscRegister = ({ id, data }: any) => {
  return http.put(`/dsc-register/${id}`, data);
};

const deleteDscRegister = (id: number) => {
  return http.delete(`/dsc-register/${id}`);
};

const getDscRegister = ({ queryKey }) => {
  return http.get(`/dsc-register/${queryKey[1]?.id}/details`, { params: { ...queryKey[1] } });
};

const issueOrReceiveDsc = ({ id, data }: any) => {
  return http.post(`/dsc-register/${id}/issue-receive`, data);
};

const importDscRegisters = (data: any) => {
  return http.post(`/dsc-register/import`, data);
};

const postApplyDsc = (data) => {
  return http.post(`/dsc-register/apply-dsc`, data);

}


const getAppliedDsc = (data) => {
  return http.get("/dsc-register/get-applied-dsc");
};

const removeDscClients = (data: any) => {
  return http.post("/dsc-register/clients-delete", data);
};

const addDscClients = (data: any) => {
  return http.post("/dsc-register/clients-add", data);
};

export {
  getDscRegisters,
  createDscRegister,
  updateDscRegister,
  deleteDscRegister,
  getDscRegister,
  issueOrReceiveDsc,
  getClients,
  importDscRegisters,
  postApplyDsc,
  getAppliedDsc,
  getClientDscRegistersexport,
  removeDscClients,
  addDscClients
};
