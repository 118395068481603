import { cronHttp } from "api/cronHttp";
import { http } from "api/http";
import { QueryType } from "api/types";
import { TaskStatus } from "data/constants";

let abortController: any;

type UpdateStatus = {
  id: number;
  status: TaskStatus;
  sourceItemsOrder: number[];
  destinationItemsOrder: number[];
};

type AddComment = {
  taskId: string;
  data: {
    text: string;
    parentId?: number;
  };
};

type AddAttachment = {
  taskId: number;
  data: FormData;
};

type AddAttachmentFromStorage = {
  taskId: number;
  data: {
    fileIds: number[];
  };
};

const createTask = (data: any) => {
  return http.post("/tasks", data);
};

const createRecurringTask = (data: any) => {
  return http.post("/tasks/recurring", data);
};

const getTasks = ({ queryKey }) => {
  console.log("QueryKdsccccccccy",queryKey)
  let { limit, offset, sort,query } = queryKey[1] || {};

  if (abortController) {
    abortController.abort();
  }

  abortController = new AbortController();

  return http.get("/tasks", {
    params: {
      queryType: "ORGANIZATION",
      ...queryKey[1],
      limit,
      sort,
      offset,
      ...query,
    },
    signal: abortController.signal,
  });
};

const getGroupTasks = ({ queryKey }) => {
  let { limit, offset, query } = queryKey[1] || {};

  if (abortController) {
    abortController.abort();
  }

  abortController = new AbortController();

  return http.get("/tasks/group-tasks", {
    params: {
      queryType: "ORGANIZATION",
      ...queryKey[1],
      limit,
      offset,
      ...query,
    },
    signal: abortController.signal,
  });
};

const getSubTasks = ({ queryKey }) => {
  let { limit, offset, query } = queryKey[1] || {};

  if (abortController) {
    abortController.abort();
  }

  abortController = new AbortController();

  return http.get("/tasks/sub-tasks", {
    params: {
      queryType: "ORGANIZATION",
      ...queryKey[1],
      limit,
      offset,
      ...query,
    },
    signal: abortController.signal,
  });
};

const getClientTasksExport = (data) => {
  if (abortController) {
    abortController.abort();
  }
  abortController = new AbortController();
  return http.post("/tasks/taskexport", data);
};

const getClientGroupTasksExport = (data) => {
  if (abortController) {
    abortController.abort();
  }
  abortController = new AbortController();
  return http.post("/tasks/grouptaskexport", {
    queryType: "ORGANIZATION",
    ...data,
  });
};

const getTaskViewexport = (data) => {
  if (abortController) {
    abortController.abort();
  }
  abortController = new AbortController();
  return http.post("/tasks/taskexportview", data);
};

const getCalanderTasks = ({ queryKey }) => {
  let { limit, offset, query } = queryKey[1] || {};

  if (abortController) {
    abortController.abort();
  }

  abortController = new AbortController();

  return http.get("/tasks/calender-tasks", {
    params: {
      queryType: "ORGANIZATION",
      ...queryKey[1],
      limit,
      offset,
      ...query,
    },
    signal: abortController.signal,
  });
};

const getPendingTasks = ({ queryKey }) => {
  const ids = queryKey[1]?.selected.map((item) => item.id);
  return http.get("/tasks/pending", {
    params: {
      selected: ids,
    },
  });
};

const getGroupPendingTasks = ({ queryKey }) => {
  const ids = queryKey[1]?.selected.map((item) => item.id);
  return http.get("/tasks/group-pending", {
    params: {
      selected: ids,
    },
  });
};

const getUserTasks = ({ queryKey }) => {
  let { limit, offset, ...query } = queryKey[1] || {};

  return http.get("/tasks/user", {
    params: {
      ...queryKey[1],
      limit,
      offset,
      ...query,
    },
  });
};

const getUserUpcomingTasks = ({ queryKey }) => {
  let { limit, offset, search, ...query } = queryKey[1] || {};
  return http.get(`/tasks/user-upcoming-tasks`, {
    params: {
      limit,
      offset,
      search,
      ...query,
    },
  });
};
const exportUserUpcomingAssignedTasks = (data: any) => {
  return http.post("/tasks/export-usercard-assaignedtasks", data);
};

const exportuserUpcomingAssaignedTasks = (data: any) => {
  return http.post("/tasks/export-user-assaignedtasks", data);
};

const getUserApprovalTasks = ({ queryKey }) => {
  let { limit, offset, query } = queryKey[1] || {};
  return http.get("/tasks/approval", {
    params: {
      ...queryKey[1],
      limit,
      offset,
      ...query,
    },
  });
};

const getUserApprovalReports = (data) => {
  return http.post("/tasks/approvaltask-export", data);
};

const getUserTasksReport = (data) => {
  return http.post("/tasks/usertask-export", data);
};

const getUserCardTasksReport = (data) => {
  return http.post("/tasks/user-card-task-export", data);
};

const getUserTasksexport = (data) => {
  return http.post("/tasks/user-export", data);
};

const getTaskLeaderTasks = ({ queryKey }) => {
  let { limit, offset, query } = queryKey[1] || {};

  return http.get("/tasks/task-leader", {
    params: {
      ...queryKey[1],
      limit,
      offset,
      ...query,
    },
  });
};

const getTaskLeaderUpcomingTasks = ({ queryKey }) => {
  let { limit, offset, query } = queryKey[1] || {};

  return http.get("/tasks/upcoimg-task-leader", {
    params: {
      ...queryKey[1],
      limit,
      offset,
      ...query,
    },
  });
};
const exportUsercardUpcomingLeaderTasks = (data) => {
  return http.post("/tasks/upcoimg-usertask-leader-export", data);
};

const exportuserUpcomingLeaderTasks = (data) => {
  return http.post("/tasks/upcoimg-task-leader-export", data);
};

const getTaskLeaderTasksexport = (data) => {
  return http.post("/tasks/task-leader-export", data);
};

const getTasksAsOptions = () => {
  return http.get("/tasks/as-options");
};

const getTask = ({ queryKey }: any) => {
  return http.get(`/tasks/task-details/${queryKey[1]}`);
};

const importTasks = (data: any) => {
  return http.post(`/tasks/import`, data);
};

const reorderTasks = (items: number[]) => {
  return http.put("/tasks/reorder/", { items });
};

const updateStatus = (data: UpdateStatus) => {
  const { id, ...body } = data;
  return http.put(`/tasks/update-status/${id}`, body);
};

const updateTask = ({ id, data }: { id: number; data: any }) => {
  return http.put(`/tasks/${id}`, data);
};

const updateTaskMemebers = ({ queryKey }: any) => {
  return http.get(`/tasks/updateTaskMembers/${queryKey[0]}`);
};

const addRemark = ({ id, data }: { id: number; data: any }) => {
  return http.post(`/tasks/${id}/remarks`, data);
};

const getTaskComments = ({ queryKey }: QueryType) => {
  return http.get(`/tasks/comments`, { params: { taskId: queryKey[1] } });
};

const addComment = ({ taskId, data }: AddComment) => {
  return http.post(`/tasks/${taskId}/comments`, data);
};

const getTaskAttachments = ({ queryKey }: QueryType) => {
  return http.get(`/tasks/attachments`, { params: { taskId: queryKey[1] } });
};

const addAttachment = ({ taskId, data }: AddAttachment) => {
  return http.post(`/tasks/${taskId}/attachments`, data);
};

const addAttachmentsFromStorage = (args: AddAttachmentFromStorage) => {
  let { taskId, data } = args;
  return http.post(`/tasks/${taskId}/attachments/from-storage`, data);
};

const createSubTask = ({ taskId, data }: any) => {
  return http.post(`/tasks/${taskId}/subtasks`, data);
};

const getChecklists = ({ queryKey }: QueryType) => {
  return http.get(`/tasks/checklists`, { params: { taskId: queryKey[1] } });
};

const addChecklist = ({ taskId, data }: any) => {
  return http.post(`/tasks/${taskId}/checklists`, data);
};

const updateChecklist = ({ data }: any) => {
  return http.put(`/tasks/checklists/update`, data);
};

const addChecklistItems = ({ checklistId, data }: any) => {
  return http.post(`/tasks/checklists/${checklistId}`, data);
};

const updateChecklistItem = ({ data }: any) => {
  return http.put(`/tasks/checklists/checklist-items/update`, data);
};

const deleteChecklist = ({ id }: { id: number }) => {
  return http.delete(`/tasks/checklists/${id}`);
};

const deleteChecklistItem = ({ id, taskId }: { id: number; taskId: number }) => {
  return http.delete(`/tasks/checklists/checklist-items/${id}`, { params: { taskId: taskId } });
};

const getMilestones = ({ queryKey }: QueryType) => {
  return http.get(`/tasks/milestones`, { params: { taskId: queryKey[1] } });
};

const addMilestone = ({ taskId, data }: any) => {
  return http.post(`/tasks/${taskId}/milestones`, data);
};

const updateMilestone = ({ id, data }: any) => {
  return http.put(`/tasks/milestones/${id}`, data);
};

const deleteMilestone = (id: number) => {
  return http.delete(`/tasks/milestones/${id}`);
};

const getDDForms = ({ queryKey }: any) => {
  return http.get(`/due-diligence`, {
    params: {
      taskUid: queryKey[1]?.taskUid,
      taskId: queryKey[1]?.taskId,
    },
  });
};

const createDDForm = ({ data }: any) => {
  return http.post(`/due-diligence`, data);
};

const addDDFormField = ({ formId, data }: any) => {
  return http.post(`/due-diligence/${formId}/fields`, data);
};

const deleteDDFormField = (formFieldId: number) => {
  return http.delete(`/due-diligence/fields/${formFieldId}`);
};

const updateDDFormField = ({ data }: any) => {
  return http.put(`/due-diligence/fields/`, data);
};

const reorderDDFormFields = (items: number[]) => {
  return http.put(`/due-diligence/fields/reorder`, { items });
};

const terminateTask = ({ id, reason }: any) => {
  return http.post(`/tasks/${id}/terminate`, { reason });
};

const deleteTask = ({ id }: any) => {
  return http.post(`/tasks/${id}/delete`);
};

const getStageOfWork = ({ queryKey }: QueryType) => {
  return http.get(`/tasks/stage-of-work`, { params: { taskId: queryKey[1] } });
};

const addStageOfWork = ({ taskId, data }: any) => {
  return http.post(`/tasks/${taskId}/stage-of-work`, data);
};

const updateStageOfWork = ({ id, data, taskId }: any) => {
  return http.put(`/tasks/stage-of-work/${id}`, data);
};

const deleteStageOfWork = ({ id, taskId }) => {
  return http.delete(`/tasks/stage-of-work/${id}`, { params: { taskId } });
};

const getBulkTasks = (data: any) => {
  return cronHttp.post("/tasks/bulk-tasks", data);
};

const taskBulkUpdate = (data: any) => {
  return cronHttp.post("/tasks/bulk-edit", data);
};

const getTaskActivity = ({ queryKey }) => {
  return http.get("/tasks/activity", { params: { ...queryKey[1] } });
};
const getUdinRegisterTasks = ({ queryKey }) => {
  let { limit, offset, search, selectedTaskType, query } = queryKey[1] || {};
  return http.get("udin-task", {
    params: {
      limit,
      offset,
      search,
      selectedTaskType,
      ...queryKey[1],
      ...query,
    },
  });
};
const exportUdinTasksPageReport = (data: any) => {
  return http.post("/udin-task/udin-task/udin-tasksexport", data);
};
const getUdinTaskDetails = ({ queryKey }: QueryType) => {
  return http.get(`/udin-task/${queryKey[1]}`, { params: { taskId: queryKey[1] } });
};

const updateUdinDetails = ({ data }: any) => {
  return http.put(`/udin-task`, data);
};

const createUdinTask = (data: any) => {
  return http.post("/udin-task", data);
};

const updateUdinTask = ({ data, id }: any) => {
  return http.put(`/udin-task/${id}`, data);
};

const getAllStatusTasks = ({ queryKey }: any) => {
  return http.get("/tasks/allStatusTasks", { params: { ...queryKey[1] } });
};

export {
  exportUsercardUpcomingLeaderTasks,
  exportUserUpcomingAssignedTasks,
  exportuserUpcomingLeaderTasks,
  exportuserUpcomingAssaignedTasks,
  exportUdinTasksPageReport,
  getUserTasksReport,
  getUserCardTasksReport,
  getUserApprovalTasks,
  getUserApprovalReports,
  getTasks,
  getTaskViewexport,
  getTasksAsOptions,
  updateTask,
  reorderTasks,
  updateStatus,
  createTask,
  getTask,
  createRecurringTask,
  getTaskComments,
  addComment,
  addAttachment,
  getTaskAttachments,
  createSubTask,
  addAttachmentsFromStorage,
  getChecklists,
  addChecklist,
  addChecklistItems,
  updateChecklistItem,
  updateChecklist,
  deleteChecklist,
  deleteChecklistItem,
  getMilestones,
  addMilestone,
  deleteMilestone,
  updateMilestone,
  getDDForms,
  createDDForm,
  addDDFormField,
  reorderDDFormFields,
  deleteDDFormField,
  updateDDFormField,
  terminateTask,
  deleteTask,
  getUserTasks,
  getStageOfWork,
  updateStageOfWork,
  deleteStageOfWork,
  addStageOfWork,
  addRemark,
  updateTaskMemebers,
  getPendingTasks,
  importTasks,
  getCalanderTasks,
  getClientTasksExport,
  getTaskLeaderTasks,
  getTaskLeaderTasksexport,
  getUserTasksexport,
  getSubTasks,
  taskBulkUpdate,
  getBulkTasks,
  getUserUpcomingTasks,
  getTaskLeaderUpcomingTasks,
  getTaskActivity,
  getUdinRegisterTasks,
  updateUdinDetails,
  createUdinTask,
  getUdinTaskDetails,
  updateUdinTask,
  getGroupTasks,
  getClientGroupTasksExport,
  getGroupPendingTasks,
  getAllStatusTasks,
};
