import { Box, IconButton } from "@mui/material";
import { useState } from "react";
import { Visibility } from "@mui/icons-material";
import DocumentPreview from "./preview";

const Actions = ({ data }) => {
    const [infoOpen, setInfoOpen] = useState(false);

    return (
        <>
            <Box display="flex" gap={1}>
                <IconButton
                    size="medium"
                    onClick={() => {
                        setInfoOpen(true);
                    }}
                >
                    <Visibility />
                </IconButton>

            </Box>
            <DocumentPreview open={infoOpen} setOpen={setInfoOpen} data={data} />
        </>
    );
};

export default Actions;
