import { Box } from "@mui/material"
import { Outlet } from "react-router-dom";
import Nav from "./incomeTaxNav";


const ClientIncomeTaxView = () => {
    return (
        <Box >
            <Nav />
            <Outlet />
        </Box>
    )
}

export default ClientIncomeTaxView
