import React from 'react';
import { Box, Button, Checkbox, FormControl, Grid, InputLabel, OutlinedInput, Paper, Select, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { DesktopDatePicker } from '@mui/lab';
import { ResType } from 'types';
import { useQuery } from 'react-query';
import { getUsersForReport } from 'api/services/users';

function Filters({ isloading, state, setState, onSubmit }) {
    const { data, isLoading }: ResType = useQuery(["users"], getUsersForReport);

    return (
        <Paper sx={{ mt: 2, p: 2, py: 4 }}>
     <Grid container spacing={2}>
        <Grid item xs={12} sm={3} md={3} lg={3}>
            {/* Filters for selecting from and to date */}
            <DesktopDatePicker
                label="From Date"
                inputFormat="dd-MM-yyyy"
                value={state.fromDate}
                maxDate={state?.toDate ? state?.toDate : new Date()}
                onChange={(value) => setState({ ...state, fromDate: value })}
                renderInput={(params) => <TextField {...params} fullWidth size="small" />}
            />
             </Grid>
             <Grid item xs={12} sm={3} md={3} lg={3}>
            <DesktopDatePicker
                label="To Date"
                inputFormat="dd-MM-yyyy"
                value={state.toDate}
                maxDate={state?.toDate ? state?.toDate : new Date()}
                onChange={(value) => setState({ ...state, toDate: value })}
                renderInput={(params) => <TextField {...params} fullWidth size="small" />}
            />
             </Grid>
             <Grid item xs={12} md={6} sm={6} lg={6}>
                    <FormControl fullWidth size="small" sx={{ flex: 1 }}>
                        <InputLabel id="demo-multiple-checkbox-label">Users*</InputLabel>
                        <Select
                            multiple
                            fullWidth
                            value={state.users}
                            onChange={(e, v: any) => {
                                setState({ ...state, users: e.target.value as string[] });
                            }}
                            input={<OutlinedInput label="Users *" />}
                            renderValue={(selected) => {
                                return data?.data
                                    ?.filter((item: any) => selected.includes(item.id))
                                    .map((item: any) => item.fullName)
                                    .join(", ");
                            }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        maxHeight: "300px",
                                    },
                                },
                            }}
                        >
                            <Box display={"flex"} alignItems={"center"} m={1} >
                                <Checkbox
                                    checked={data?.data?.length === state.users.length}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setState({
                                                ...state,
                                                users: data?.data?.map((item: any) => item?.id),
                                            });
                                        } else {
                                            setState({ ...state, users: [] });
                                        }
                                    }}
                                    sx={{ marginRight: "10px" }}
                                />
                                <Typography> Select All  </Typography>
                            </Box>

                            {data?.data?.map((item: any) => (
                                <Box display={"flex"} alignItems={"center"} m={1} >
                                    <Checkbox
                                        checked={state.users.indexOf(item?.id) > -1}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                if (state.users.includes(item.id)) {
                                                    const userList = state.users.filter((eachitem) => eachitem !== item.id);
                                                    setState({
                                                        ...state,
                                                        users: [...userList]
                                                    })
                                                } else {
                                                    setState({
                                                        ...state,
                                                        users: [...state.users, item.id]
                                                    })
                                                }
                                            } else {
                                                const userList = state.users.filter((eachitem) => eachitem !== item.id);
                                                setState({
                                                    ...state,
                                                    users: [...userList]
                                                })
                                            }
                                        }}
                                        sx={{ marginRight: "10px" }}
                                    />
                                    <Typography>{item?.fullName}</Typography>
                                </Box>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            {/* Generate Report Button */}
            <Box>
                <Button sx={{ mt: 4,ml:3 }} disabled={isloading} onClick={onSubmit} color="secondary" variant="contained">
                    Generate Report
                </Button>
            </Box>
            </Grid>
            </Paper>
    );
}

export default Filters;
