import { Box, Button, Grid, Switch, TextField, Typography } from "@mui/material";
import { issueOrReceiveDsc } from "api/services/clients/dsc-register";
import { cloneService } from "api/services/services";
import DialogWrapper from "components/DialogWrapper";
import { snack } from "components/toast";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { DialogProps } from "types";

interface Props extends DialogProps {
  serviceId: number;
  type:boolean;
  serviceIsActive:boolean;
}

const PREFERENCE_TEXT = {
    ADD: "add preference",
    DELETE: "delete preference",
    NONE: "none",
  };

function ServiceClonePopUp(props: Props) {
  const { open, setOpen, serviceId,type,serviceIsActive } = props;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [active, setActive] = useState(type);

    useEffect(()=>{
        if(open && !serviceIsActive ){
            setActive(true)
        }else if(open && serviceIsActive){
            setActive(false)
        }
    },[open])

    const getPreferenceText = () => {
        if (active && !serviceIsActive) {
          return PREFERENCE_TEXT.ADD;
        } else if (active && serviceIsActive) {
          return PREFERENCE_TEXT.DELETE;
        } else {
          return PREFERENCE_TEXT.NONE;
        }
      };
    
    const preferenceText = getPreferenceText();

  const { mutate, isLoading } = useMutation(cloneService, {
    onSuccess: (res) => {
      navigate(`add/?serviceId=${res?.data?.id}`);
      if (active) {
        snack.success("Service status updated to active");
      } else {
        snack.success("Service status updated to inactive");
      }
      queryClient.invalidateQueries("services");
      setOpen(false);
      setActive(false);
    },
    onError: (err: any) => {
      snack.error(err.response.data.message);
    },
  });
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate({
      id: serviceId,
      data: { serviceIsactive: preferenceText },
    });
  };

  const handleChange = (e: any) => {
    setActive(e.target.checked);
  };


  return (
    <DialogWrapper
      title={"Are you sure you want to clone this service?"}
      open={open}
      setOpen={setOpen}
    >
      <form onSubmit={handleSubmit}>
        <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
          <Switch checked={active} name={"active"} onChange={handleChange} />
          <Typography>{serviceIsActive ? 'Do you want to Active the Existing Selected Service?' :'Do you want to Inactive the Existing Selected Service?'  }</Typography>{" "}
        </Box>
       
        <Box mt={3} textAlign="center">
          <Button
            disabled={isLoading}
            type="submit"
            size="large"
            color="primary"
            variant="contained"
          >
            {"Clone Service"}
          </Button>
        </Box>
      </form>
    </DialogWrapper>
  );
}

export default ServiceClonePopUp;
