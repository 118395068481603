import { Box, Typography } from "@mui/material";
import { getPreviewDetails } from "api/services/quantum";
import DialogWrapper from "components/DialogWrapper";
import Loader from "components/Loader";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { DialogProps, ResType } from "types";

interface Props extends DialogProps {
    data: any;
}

function DocumentPreview({ open, setOpen, data }: Props) {
    const [htmlString, setHtmlString] = useState("")
    const { data: previewData, isLoading: loadingTemplate }: ResType = useQuery(
        ["created-templates", data?.processInstanceId],
        getPreviewDetails, {
        enabled: open
    }
    );

    useEffect(() => {
        setHtmlString(previewData?.data)
    }, [previewData])

    return (
        <DialogWrapper
            width="md"
            open={open}
            setOpen={setOpen}
            title="Document Preview"
        >
            {loadingTemplate ?
                <Loader />
                : <>
                    <Box mt={10}
                        padding={5}
                        margin={2}
                        bgcolor={"#ffffff"}
                        style={{
                            overflowY: "auto",
                            overflowX: "hidden", height: "75vh", width: "60vw", userSelect: "none"
                        }}
                        fontSize={"30px"}
                        // onContextMenu={handleContextMenu}
                        sx={{
                            maxWidth: "800px", // Set your desired maximum width
                            wordWrap: "break-word"
                        }}
                    >
                        <div dangerouslySetInnerHTML={{ __html: htmlString }} />
                    </Box>
                </>}

        </DialogWrapper>
    );
}

export default DocumentPreview
