import React, { useState, useContext, useEffect } from 'react';
import { Box, Breadcrumbs, Typography, Button } from "@mui/material";
import { LinkRouter } from "components/BreadCrumbs";
import { snack } from "components/toast";
import useTitle from "hooks/useTitle";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleError } from "utils/handleError";
import { UserProfileContext } from 'context/UserProfile';
import { exportEmployeeAttendanceandTimesheet, exportEmployeeAttendanceReport, getEmployeeTimeSheetReport } from 'api/services/reports';
import moment from 'moment';
import Filters from 'views/reports/EmployeeAttendanceandTimesheetReport/Filters';
import { getInvoicePreference } from 'api/services/invoice.preferencess';
import { regularMonths } from 'data/periods';
import { ResType } from 'types';
import { getCurrentFormattedDateTime } from 'utils';

export interface IState {
    users: Array<any>;
    fromDate: null | string | Date;
    toDate: null | string | Date;
}

function EmployeeAttendanceandTimesheetReport() {
    useTitle("Reports");

    const [state, setState] = useState<IState>({
        users: [],
        fromDate: null, // default to today's date
        toDate: null, // default to today's date
    });
    var currentYearString = moment().format('YYYY');

    var currentYearInt = parseInt(currentYearString, 10);

    const [weekendData, setWeekendData] = useState<any>([]);
    const [holidays, setHolidays] = useState<any>([]);
    const [totalHolidays, setTotalHolidays] = useState<any>([]);
    const [year, setYear] = useState<any>(currentYearInt);
    const [regularMonthsData, setRegularMonthsData] = useState<any[]>(regularMonths);
    const [daysInYear, setDaysInYear] = useState<any>([]);
    const [startDate, setStartDate] = useState<any>(moment(`${year}-01-01`));
    const [filteredDates, setFilteredDates] = useState<any>([])
    const queryClient = useQueryClient();

    const { data: invoicePreferences, isLoading: isInvoicePreferencesLoading } = useQuery(
        "attendance-preferences",
        getInvoicePreference,
        {
            onSuccess: (res: any) => {
                res?.data?.holidayPreferences?.addholiday.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());
                setTotalHolidays(res?.data?.holidayPreferences?.addholiday);
                const Yearholidays = res?.data?.holidayPreferences?.addholiday.filter((item: any) => moment(item?.date).format("YYYY") === String(year))
                setHolidays(Yearholidays);
                const days: any = []
                for (let day = 0; day < 365; day++) {
                    const currentDay = startDate.clone().add(day, 'days');
                    days.push(currentDay.format('YYYY-MM-DD'));
                }
                setDaysInYear(days);
                setFilteredDates(holidays.map((item) => moment(item.date).format("YYYY-MM-DD")))
                setWeekendData(res?.data?.holidayPreferences?.updateweekend.map((item) => item.label));

            },
        }
    );

 
    const onChange = (e: any) => {
        setYear(e.target.value);
        setStartDate(moment(`${e.target.value}-01-01`))
        const days: any = []
        for (let day = 0; day < 365; day++) {
            const currentDay = startDate.clone().add(day, 'days');
            days.push(currentDay.format('YYYY-MM-DD'));
        }
        setDaysInYear(days);
        const Yearholidays = totalHolidays.filter((item: any) => moment(item?.date).format("YYYY") === String(e.target.value))
        setHolidays(Yearholidays);
        setRegularMonthsData(regularMonths);
        queryClient.invalidateQueries("attendance-preferences");
    };
    let allCombinedArrays: any = [];
    regularMonthsData.map((item) => {
        const filteredData = holidays.filter((holiday) => moment(holiday.date, 'YYYY-MM-DD').format("MMMM") === item);
        const filteredMOnthInyear = daysInYear.filter((days): any => moment(days).format("MMMM") === item && !filteredDates.includes(days) && weekendData.includes(moment(days).format("dddd")));
        const combinedArray = [...filteredData, ...filteredMOnthInyear];
        combinedArray.sort((a, b) => moment(a.date || a).valueOf() - moment(b.date || b).valueOf());

        allCombinedArrays.push(combinedArray);
    })
    useEffect(() => {
        const Yearholidays = totalHolidays.filter((item: any) => moment(item?.date).format("YYYY") === String(year))
        setHolidays(Yearholidays);
    }, [year])


    const { data: user } = useContext(UserProfileContext);

    const { mutate, isLoading: isMutating } = useMutation(exportEmployeeAttendanceandTimesheet, {
        onSuccess: (res: any) => {
            const arr = new Uint8Array(res.data?.data);
            const blob = new Blob([arr], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const formattedDateTime = getCurrentFormattedDateTime();
            const file = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.href = file;
            link.download = `Employee Attendance and Timesheet Report ${formattedDateTime}.xlsx`;
            link.click();
            snack.success("Report Exported to Excel");
        },
        onError: (err: any) => {
            snack.error(handleError(err));
        },
    });
    const formattedFromDate = moment(state.fromDate).format("YYYY-MM-DD");
    const formattedToDate = moment(state.toDate).format("YYYY-MM-DD");
    const fromDate = moment(formattedFromDate, "YYYY-MM-DD");
    const toDate = moment(formattedToDate, "YYYY-MM-DD");
    const daysBetween = toDate.diff(fromDate, 'days') + 1;

    // Flatten allCombinedArrays into a single array
    const flattenedArray = allCombinedArrays.flat().map((item) => {
        // Ensure item is a date string in the correct format
        return item.date ? moment(item.date).format("YYYY-MM-DD") : moment(item).format("YYYY-MM-DD");
    });

    // Filter dates between formattedFromDate and formattedToDate
    const filteredDatesCount = flattenedArray.filter((date) => {
        return moment(date).isBetween(formattedFromDate, formattedToDate, 'day', '[]');
    });
    const filteredDatesLength = filteredDatesCount.length



    const handleExport = () => {
        if (!state.fromDate) return snack.error("Please Select From Date");
        if (!state.toDate) return snack.error("Please Select To Date");

        const payload = {
            organizationid: user?.organization?.id,
            users: state.users,
            holidayscount: filteredDatesLength,
            countOfSelectedDates: daysBetween,
            fromDate: moment(state.fromDate).format("YYYY-MM-DD"),
            toDate: moment(state.toDate).format("YYYY-MM-DD"),
        };

        mutate(payload); // Trigger the export mutation
    };

    return (
        <Box p={2}>
            {/* Breadcrumb navigation */}
            <Breadcrumbs>
                <LinkRouter underline="hover" color="inherit" to="/reports">
                    Reports
                </LinkRouter>
                <Typography color="text.primary">Employee Attendance and Timesheet</Typography>
            </Breadcrumbs>

            {/* Filters component */}
            <Filters
                isloading={isMutating}
                state={state}
                setState={setState}
                onSubmit={handleExport} // Pass the export logic here
            />
        </Box>
    );
}

export default EmployeeAttendanceandTimesheetReport;
