import { Box, Typography, Divider, styled } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { StyledClientFilterItem } from "views/tasks/Filters/styles";
import atomCloudMenu from "../../assets/images/atomCloudMenu.png"
import { StyledProfileNavItem } from "views/clients/styles";


function Nav() {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const currentURL = window.location.href;
    const handleClick = (path) => {
        navigate(path);
    };

    const parts = currentURL.split("/");
    const url = parts[parts.length - 2];

    return (
        <>
            <Box
                mb={1}
                display="flex"
                gap="15px"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                bgcolor="#F5F5F5"
            >
                <StyledProfileNavItem
                    variant="body1"
                    color="rgba(0,0,0,0.7)"
                    active={location.pathname === `/atom-pro/income-tax/incometax/${params.incometaxid}` ? 1 : 0}
                    onClick={() => handleClick(`/atom-pro/income-tax/incometax/${params.incometaxid}`)}
                >
                    Basic Info
                </StyledProfileNavItem>
                <StyledProfileNavItem
                    variant="body1"
                    color="rgba(0,0,0,0.7)"
                    active={location.pathname === `/atom-pro/income-tax/incometax/${params.incometaxid}/return` ? 1 : 0}
                    onClick={() => handleClick(`/atom-pro/income-tax/incometax/${params.incometaxid}/return`)}
                    sx={{ marginLeft: "10px", }}
                >
                    Return
                </StyledProfileNavItem>
                <StyledProfileNavItem
                    variant="body1"
                    color="rgba(0,0,0,0.7)"
                    active={location.pathname === `/atom-pro/income-tax/incometax/${params.incometaxid}/my-cas` ? 1 : 0}
                    onClick={() => handleClick(`/atom-pro/income-tax/incometax/${params.incometaxid}/my-cas`)}
                >
                    My CA
                </StyledProfileNavItem>
                <StyledProfileNavItem
                    variant="body1"
                    color="rgba(0,0,0,0.7)"
                    active={location.pathname === `/atom-pro/income-tax/incometax/${params.incometaxid}/form` ? 1 : 0}
                    onClick={() => handleClick(`/atom-pro/income-tax/incometax/${params.incometaxid}/form`)}
                >
                    Forms
                </StyledProfileNavItem>
                <StyledProfileNavItem
                    variant="body1"
                    color="rgba(0,0,0,0.7)"
                    active={location.pathname === `/atom-pro/income-tax/incometax/${params.incometaxid}/challan` ? 1 : 0}
                    onClick={() => handleClick(`/atom-pro/income-tax/incometax/${params.incometaxid}/challan`)}
                >
                    e-Challan
                </StyledProfileNavItem>
                <StyledProfileNavItem
                    variant="body1"
                    color="rgba(0,0,0,0.7)"
                    active={location.pathname === `/atom-pro/income-tax/incometax/${params.incometaxid}/demand` ? 1 : 0}
                    onClick={() => handleClick(`/atom-pro/income-tax/incometax/${params.incometaxid}/demand`)}
                >
                    Outstanding Demands
                </StyledProfileNavItem>
                <StyledProfileNavItem
                    variant="body1"
                    color="rgba(0,0,0,0.7)"
                    active={location.pathname === `/atom-pro/income-tax/incometax/${params.incometaxid}/proceeding` ? 1 : 0}
                    onClick={() => handleClick(`/atom-pro/income-tax/incometax/${params.incometaxid}/proceeding`)}
                >
                    e-Proceedings
                </StyledProfileNavItem>
                <StyledProfileNavItem
                    variant="body1"
                    color="rgba(0,0,0,0.7)"
                    active={location.pathname === `/atom-pro/income-tax/incometax/${params.incometaxid}/activity` ? 1 : 0}
                    onClick={() => handleClick(`/atom-pro/income-tax/incometax/${params.incometaxid}/activity`)}
                >
                    Activity Log
                </StyledProfileNavItem>
                <StyledProfileNavItem
                    variant="body1"
                    color="rgba(0,0,0,0.7)"
                    active={location.pathname === `/atom-pro/income-tax/incometax/${params.incometaxid}/client-status` ? 1 : 0}
                    onClick={() => handleClick(`/atom-pro/income-tax/incometax/${params.incometaxid}/client-status`)}
                >
                    Sync Status
                </StyledProfileNavItem>
            </Box>
            {/* <Divider sx={{ borderBottomWidth: 1, }} /> */}
        </>

    );
}

export default Nav;
