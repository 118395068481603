import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import React, { useState } from "react";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

interface StateProps {
  msg: React.ReactNode;
  action: (restoreAtomProClients: boolean) => void;
  executeOnCancel?: boolean;
}

type ContextProps = (args: StateProps) => void;

export const ConfirmCancelDialogContext = React.createContext<ContextProps>(() => { });

function ConfirmCancelDialogProvider({ children }: any) {
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState<StateProps>({
    msg: "",
    action: () => { },
    executeOnCancel: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const onOk = () => {
    setLoading(true);
    state.action(true);
    handleClose();
  };

  const handleCnacel = () => {
    if (state.executeOnCancel) {
      setLoading(true);
      state.action(false); // Execute action on Cancel if the prop is true
    }
    handleClose()
  }

  const confirmCancel = (args: StateProps) => {
    const { msg, action, executeOnCancel = false } = args;
    setLoading(false);
    setOpen(true);
    setState({
      msg,
      action,
      executeOnCancel
    });
  };

  return (
    <ConfirmCancelDialogContext.Provider value={confirmCancel}>
      {children}
      <Dialog maxWidth="xs" fullWidth open={open} onClose={handleClose}>
        <Box p={2}>
          <Box>
            <Typography color="primary" gutterBottom variant="subtitle1" display="flex" gap={1} alignItems="center" justifyContent="center">
              Warning  <WarningAmberRoundedIcon color="secondary" fontSize="medium" />
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {state.msg}
            </Typography>
          </Box>
          <Box mt={3} display="flex" gap={2} justifyContent="flex-end">
            <Button
              variant="outlined"
              color="secondary"
              sx={{ minWidth: 70 }}
              size="small"
              onClick={onOk}
              disabled={isLoading}
            >
              Ok
            </Button>
            <Button
              variant="outlined"
              sx={{ minWidth: 70 }}
              size="small"
              color="secondary"
              onClick={handleCnacel}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Dialog>
    </ConfirmCancelDialogContext.Provider>
  );
}

export const useConfirmCancel = () => React.useContext(ConfirmCancelDialogContext);

export default ConfirmCancelDialogProvider;
