import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box, SystemStyleObject } from "@mui/system";
import { http } from "api/http";
import { useEffect, useState } from "react";
import { snack } from "./toast";
import { useParams, useSearchParams } from "react-router-dom";
import { useMutation } from "react-query";
import { uploadFile } from "api/services/storage";
import { handleError } from "utils/handleError";

export const UploadContainer = styled("div")(() => ({
  border: "1px dotted grey",
  display: "flex",
  borderRadius: "8px",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  minHeight: 150,
  textAlign: "center",
  cursor: "pointer",
  padding: "10px 0px",
}));

interface UploadProps {
  name: string;
  onChange: (id: any, fileData: any) => void;
  sx?: SystemStyleObject;
  label?: string;
  widthoutIcon?: boolean;
  accept?: string[];
  files?: File | null;
  type?: "client" | "organization" | "task" | "clientGroup";
  client?: number | null | string;
  existingFile?: string;
  errorMessage?: string;
  itemId?: string
}

function UploadImage({
  onChange,
  client,
  name = "upload",
  sx,
  label = "Drag and drop or Browse",
  widthoutIcon = false,
  accept,
  files,
  type,
  existingFile,
  errorMessage,
  itemId
}: UploadProps) {
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<any>("");
  const [fileData, setFileData] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const params = useParams();
  const clientId = params.clientId || searchParams.get("clientId") || "";

  useEffect(() => {
    if (files) {
      setFile(files ? files : null);
      setFileName(files ? files : "");
    }
  }, [files]);

  const { mutateAsync } = useMutation(uploadFile, {

    onSuccess: (res: any) => {

      onChange(1, res?.data);
      setFileData(res?.data || "");
    },
    onError: (err: any) => {
      console.log(err.authorizationUrl);
    }

  });

  const handleFile = async (file: any) => {
    if (!file) return;

    if (file.size > 10490000) {
      return snack.error("File Size should be less than 10 MB");
    }


    if (accept && !accept.includes(file.type)) {
      if (errorMessage) {
        return snack.error(errorMessage);
      }
      const formattedAccept = accept.map(type => type.replace("image/", ""));
      return snack.error(`Only ${formattedAccept.join(", ")} types are accepted`);
    }


    try {
      setLoading(true);
      setFile(file);
      setFileName(file.name);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("clientId", String(client ? client : clientId));
      formData.append("folderId", "");
      formData.append("type", type ?? "error");
      onChange(file.name, formData);

      setFileData(file || "");
      // await mutateAsync(formData);



    } catch (err: any) {
      snack.error(err.response.data.message);
      // snack.error(err?.message);


      if (err.response.data.authorizationUrl) {
        setTimeout(() => {
          window.location.href = err.response.data.authorizationUrl;
        }, 2000);

      }
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleChange = (e: any) => {
    handleFile(e.target.files[0]);
  };

  const handleDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    handleFile(e.dataTransfer.files[0]);
  };

  return (
    <>
      <input
        type="file"
        onChange={handleChange}
        name="upload"
        id={name}
        style={{ display: "none" }}
      />
      <label htmlFor={name}>
        <UploadContainer
          sx={sx}
          onDrop={(e) => handleDrop(e)}
          onDragOver={(e) => handleDragOver(e)}
          onDragEnter={(e) => handleDragEnter(e)}
          onDragLeave={(e) => handleDragLeave(e)}
        >
          <div>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                {!widthoutIcon && <CloudUploadOutlinedIcon color="disabled" fontSize="large" />}
                <Typography color="G rayText" sx={{ textAlign: "center" }}>
                  {label}
                </Typography>
                {(fileData || existingFile) && (
                  <Box mt={1} width="100%" borderRadius={8}>
                    <Typography variant="body1">{fileData?.name || existingFile}</Typography>
                  </Box>
                )}
              </>
            )}
          </div>
        </UploadContainer>
      </label>
    </>
  );
}

export default UploadImage;
