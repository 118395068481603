import { values } from "lodash";
import { Label } from "recharts";

export   const sectionCodeList = [
    { id: "", name: "All" },
    { id: "143(3)", name: "143(3)" },
    { id: "144", name: "144" },
    { id: "147", name: "147" },
    { id: "272A(1)(d)", name: "272A(1)(d)" },
    { id: "115_WE", name: "115_WE" },
    { id: "1431a", name: "1431a" },
    { id: "154", name: "154" },
    { id: "1433", name: "1433" },
    {id:'271B', name:'271B'},
    {id:'271F',name:'271F'},
    {id:'139(9)', name:'139(9)'},
    {id:'u/s 139(9)', name:'u/s 139(9)'},
    {id:'271(1)(c)',name:'271(1)(c)'},
    {id:'272A(1)(d)',name:'272A(1)(d)'},
    {id:'270A',name:'270A'},
    {id:'250',name:'250'},
    {id:'142(1)',name:'142(1)'},
    {id:'148',name:'148'},
    {id:'143(1)(a)',name:'143(1)(a)'}
  ];

  export const getAutFinancialYears = () => {
    let fiscalYears: string[] = [];
    for (let i = 0; i < 1; i++) {
      const year = new Date().getFullYear() + i + 1;
      const fiscalYear = `${year}-${(year + 1).toString().slice(-2)}`;
      fiscalYears.unshift(fiscalYear);
    } 
    for (let i = 0; i < 25; i++) {
      const year = new Date().getFullYear() - i;
      const fiscalYear = `${year}-${(year + 1).toString().slice(-2)}`;
      fiscalYears.push(fiscalYear);
    }

    return fiscalYears;
  };

  export const clientCategories = [
    { value: "individual", label: "Individual" },
    { value: "huf", label: "Hindu Undivided Family" },
    { value: "partnership_firm", label: "Partnership Firm" },
    { value: "llp", label: "Limited Liability Partnership" },
    { value: "company", label: "Company" },
    { value: "aop", label: "Association of Persons" },
    { value: "boi", label: "Body of Individuals" },
    { value: "trust", label: "Trust" },
    { value: "government", label: "Government" },
    { value: "local_authority", label: "Local Authority" },
    { value: "artificial_judicial_person", label: "Artificial Juridical Person" },
  ];

  export const sortFilter = [
    { value: 'AMOUNT_DESC', label: 'Amount High' },
    { value: 'AMOUNT_ASC', label: 'Amount Low' },
    { value: 'DATE_NEWEST', label: 'Date Newest' },
    { value: 'DATE_OLDEST', label: 'Date Oldest' },
  ];

export const incomeTaxFormsFilingTypes = {
    "Original": "Original",
    "Revised": "Revised"
};

export const incomeTaxForms = [
    "Form 10-IB",
    "Form 10-IC",
    "Form 10-ID",
    "Form 10-IF",
    "Form 10CCBBA",
    "Form 10CCBC",
    "Form 10CCBD",
    "Form 10CCC",
    "Form 10CCD",
    "Form 10CCE",
    "Form 10CCF",
    "Form 10DA",
    "Form 10H",
    "Form 10IE",
    "Form 29B",
    "Form 29C",
    "Form 3AC",
    "Form 3AD",
    "Form 3AE",
    "Form 3CA-3CD",
    "Form 3CB-3CD",
    "Form 3CE",
    "Form 3CEA",
    "Form 3CEAA",
    "Form 3CEAB",
    "Form 3CEAC",
    "Form 3CEAD",
    "Form 3CEAE",
    "Form 3CEB",
    "Form 3CEFA",
    "Form 3CEJ",
    "Form 3CEJA",
    "Form 3CEK",
    "Form 3CFA",
    "Form 3CLA",
    "Form 49C",
    "Form 49D",
    "Form 52A",
    "Form 56F",
    "Form 56FF",
    "Form 6B",
    "Form 62",
    "Form 64",
    "Form 64A",
    "Form 64D",
    "Form 64E",
    "Form 65",
    "Form 66",
    "Form 10-IA",
    "Form 10E",
    "Form 10IH",
    "Form 5C",
    "Form 1",
    "Form 10",
    "Form 10-II",
    "Form 10-IK",
    "Form 10-EE",
    "Form 10A",
    "Form 10AB",
    "Form 10B",
    "Form 10B (A.Y 2023-24 onwards)",
    "Form 10BA",
    "Form 10BB",
    "Form 10BB (A.Y. 2023-24 onwards)",
    "Form 10BBB",
    "Form 10BBD",
    "Form 10BD",
    "Form 10F",
    "Form 10FC",
    "Form 10IG",
    "Form 15CA",
    "Form 15CD",
    "Form 26QF",
    "Form 28A",
    "Form 3",
    "Form 3 DTVSV",
    "Form 35",
    "Form 3BB",
    "Form 3BC",
    "Form 3CED",
    "Form 3CEF",
    "Form 3CF",
    "Form 4 DTVSV",
    "Form 40C",
    "Form 41",
    "Form 49BA",
    "Form 58C",
    "Form 58D",
    "Form 5B",
    "Form 6",
    "Form 67",
    "Form 68",
    "Form 70",
    "Form 71",
    "Form 8",
    "Form 9",
    "Form 9A",
    "Form 3CEEA",
    "Form I SWF",
    "Form II SWF"
];

export function calculateAssessmentYear(startYear) {
    const endYear = parseInt(startYear) + 1;
    return startYear + "-" + endYear.toString().slice(2);
}

export const getGstrFinancialYears = () => {
  let fiscalYears: string[] = [];
  for (let i = 0; i < 1; i++) {
    const year = new Date().getFullYear() + i + 1;
    const fiscalYear = `${year}-${(year + 1).toString()}`;
    fiscalYears.unshift(fiscalYear);
  } 
  for (let i = 0; i < 9; i++) {
    const year = new Date().getFullYear() - i;
    const fiscalYear = `${year}-${(year + 1).toString()}`;
    fiscalYears.push(fiscalYear);
  }

  return fiscalYears;
};

export  const textBgColors = [{bg:'#FFEDE0',txt:'#F7964F'},{bg:'#FFECED',txt:'#EE363C'},{bg:'#F1EAFF',txt:'#673AB8'},{bg:'#CEE9FF',txt:'#266396'},{bg:'#FFE4EF',txt:'#91305a'},{bg:'#E1DDFF',txt:'#695EB3'},
  {bg:'#DADEFF',txt:'#455aff'},{bg:'#E4F0FF',txt:'#7DA6DC'},{bg:'#D8FFCC',txt:'#0E3601'},{bg:'#FFF2CF',txt:'#FCbF1B'},
  {bg:'#FFDBCD',txt:'#f54907'},{bg:'#FFD5EC',txt:'#f6bfq1b'},{bg:'#FFE1D8',txt:'#b04b2a'}];

export const CASETYPE = ["Determination of Tax", "Scrutiny of Returns", "Voluntary Payment","Appeal","Audit","Enforcement Case","Pre-GST Recovery","Tax Collected Not Deposited","Letter of Undertaking"];

export const FOLDERTYPE = ['Notices', "Orders","Intimations","Reports","DRC-07A","Upload Offline APL-04 Orders","Proceedings"];

export const challanPaymentTax = [
  { value: "Other Receipts", label: "Other Receipts (500)" },
  { value: "Outstanding Demand (Regular Assessment Tax)", label: "Outstanding Demand (Regular Assessment Tax) (400)" },
  { value: "TDS on Sale of Property", label: "TDS on Sale of Property (800)" },
  { value: "Self-Assessment Tax", label: "Self-Assessment Tax (300)" },
  { value: "Advance Tax", label: "Advance Tax (100)" },
];

export const udinFormStats = {
  'UDIN_APPLICABLE':'UDIN Applicable',
  'UDIN_NOT_APPLICABLE':'UDIN Not Applicable',
  'UDIN_COMPLETED':'UDIN Completed',
  'UDIN_PENDING':'UDIN Pending'
};