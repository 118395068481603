import { Autocomplete, Box, Button, TextField, Typography } from "@mui/material"
import { getInvoicePreference, updateWeekendData } from "api/services/invoice.preferencess";
import DrawerWrapper from "components/DrawerWrapper"
import { snack } from "components/toast";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { DialogProps } from "types"

function UpdateWeekend({ open, setOpen }: DialogProps) {
    const queryClient = useQueryClient();
    const [state, setState]: any = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { mutate, isLoading: insertLoad } = useMutation(updateWeekendData, {
        onSuccess: () => {
            snack.success("Weekend Preferences Updated");
            queryClient.invalidateQueries("attendance-preferences");
            setOpen(false);
            setIsLoading(false);
        }
    });

    const handleUpdateWeekend = () => {
        if(!state?.length){
            return snack.error("Please Select Weekend");
        }
        setIsLoading(true);
        const apiData = { typeofdata: "updateweekend", data: state }
        mutate({ data: apiData });
    }

    const { data: invoicePreferences, isLoading: isInvoicePreferencesLoading } = useQuery(
        "attendance-preferences",
        getInvoicePreference,
        {
            onSuccess: (res: any) => {
                setState(res.data.holidayPreferences.updateweekend);
            },
            enabled: open,
        }
    );

    return (
        <DrawerWrapper open={open} title="Update Weekend" setOpen={setOpen}>
            <Autocomplete
                multiple
                limitTags={3}
                id="tags-filled"
                onChange={(_, value: any) => {
                    setState(value);
                }}
                value={state}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                options={[
                    { label: "Saturday", value: "saturday" },
                    { label: "Sunday", value: "sunday" },
                ]}
                getOptionLabel={(option: any) => option?.label}
                renderInput={(params) => (
                    <TextField {...params} sx={{ my: 2 }} required variant="outlined" size="small" fullWidth label="Selected Weekends" />
                )}
            />
            <Box>
                <Button sx={{ m: 1 }} disabled={isLoading} fullWidth variant="contained" onClick={handleUpdateWeekend} color="secondary">Update</Button>
            </Box>
        </DrawerWrapper>
    )
}
export default UpdateWeekend