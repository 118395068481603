import { Box, Checkbox, Grid, Paper, Typography } from "@mui/material"
import { getQtmNotificationConstnats, updatePreferenceQtm } from "api/notifications-preferences-service";
import { snack } from "components/toast";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ResType } from "types";
import BottomBar from "components/BottomBar";

const AddPreferences = ({ data }) => {
    const [notificationPreferences, setNotificationPreferences] = useState(data)
    const [notificationActions, setNotificationActions] = useState<any>()
    const [isCheckedPush, setIsCheckedPush] = useState(false);
    const [isCheckedMail, setIsCheckedMail] = useState(false);
    const [isCheckedWhatsApp, setIsCheckedWhatsApp] = useState(false);
    const queryClient = useQueryClient();

    useEffect(() => {
        setNotificationPreferences(data)
    }, [data])

    const { data: anc, isLoading }: ResType = useQuery(
        "pushNotifications",
        getQtmNotificationConstnats, {
        onSuccess: (res: any) => {
            setNotificationActions(res?.data)

        },
        onError: (err: any) => {
            snack.error(err.response?.data?.message || "An error occurred");
        }
    }
    );

    const isCheckBoxChecked = (category, actionName, actionType, notificationPreferences, type) => {
        console.log(category, actionName, actionType, notificationPreferences, type,"category, actionName, actionType, notificationPreferences, type")
        if (notificationPreferences !== null) {
            return notificationPreferences?.[type]?.[actionType] !== undefined && notificationPreferences?.[type]?.[actionType] === notificationActions[category][actionName][actionType]
        }
        else {
            return false
        }

    };

    const handleCheckBoxChange = (category, actionName, actionType, notificationPreferences, type) => {
        console.log(category, actionName, actionType, notificationPreferences, type,"category, actionName, actionType, notificationPreferences, type");
        if (notificationPreferences?.[type]?.[actionType]) {
            // Create a deep copy of notificationPreferences
            const updatedPreferences = JSON.parse(JSON.stringify(notificationPreferences));
            // Delete the specified property
            delete updatedPreferences[type][actionType];
            // Update the state with the new object
            setNotificationPreferences(updatedPreferences);
        } else {
            setNotificationPreferences({
                ...notificationPreferences,
                [type]: {
                    ...notificationPreferences[type],
                    [actionType]: notificationActions[category][actionName][actionType],
                },
            });
        }
    };

    const handleSelectAll = (endChar, isChecked, setIsChecked, notificationPreferences, type) => {

        setIsChecked((prev) => !prev);

        if (!isChecked) {
            const endsWithObject = {};
            for (let key in notificationActions) {
                const abc = notificationActions[key];
                for (let innerKey in abc) {
                    const bcd = abc[innerKey]
                    for (let inkoInnerKey in bcd) {
                        if (inkoInnerKey.endsWith(endChar)) {
                            if (bcd[inkoInnerKey] !== 'none') {
                                endsWithObject[inkoInnerKey] = bcd[inkoInnerKey];
                            }

                        }
                    }

                }
            }

            setNotificationPreferences({
                ...notificationPreferences,
                [type]: endsWithObject
            })
        } else {
            setNotificationPreferences({
                ...notificationPreferences,
                [type]: {}
            })
        }
    };

    const { mutateAsync } = useMutation(updatePreferenceQtm, {
        onSuccess: () => {
            snack.success("Preferences Updated");
            queryClient.invalidateQueries("get-user-preferences");
        },
        onError: (err: any) => {
            snack.error(err.response.data.message);
        },
    });


    const handleUpdatePermissons = async () => {
        await mutateAsync({
            data: { notificationPreferences }
        });
    }
    console.log(notificationPreferences,"notificationPreferences");
    return (

        <Box sx={{ height: "40vh" }}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={6} justifyContent="center" alignItems="center">
                    <Box display={"flex"} >
                        <Typography variant="h6" ml={6}>Name</Typography>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Typography variant="h6">Push</Typography>
                        <Checkbox
                            onChange={() =>
                                handleSelectAll("_PUSH", isCheckedPush, setIsCheckedPush, notificationPreferences, 'push')
                            }
                            checked={isCheckedPush}
                            inputProps={{ 'aria-label': 'Select endsWith B' }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Typography variant="h6">Email</Typography>
                        <Checkbox
                            onChange={() =>
                                handleSelectAll("_MAIL", isCheckedMail, setIsCheckedMail, notificationPreferences, 'email')
                            }
                            checked={isCheckedMail}
                            inputProps={{ 'aria-label': 'Select endsWith B' }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Typography variant="h6">WhatsApp</Typography>
                        <Checkbox
                            onChange={() =>
                                handleSelectAll("_WHATSAPP", isCheckedWhatsApp, setIsCheckedWhatsApp, notificationPreferences, 'whatsapp')
                            }
                            checked={isCheckedWhatsApp}
                            inputProps={{ 'aria-label': 'Select endsWith B' }}
                        />
                    </Box>
                </Grid>
            </Grid>
            {notificationActions && notificationActions && (
                <Box overflow={"auto"} height={"57vh"} >
                    {Object.keys(notificationActions).map(category => (
                        <Paper key={category} elevation={1} style={{ padding: "10px", margin: "15px" }}>
                            <Box >
                                <Typography variant="h5" p={1}>{category}</Typography>

                                {Object.keys(notificationActions[category]).map((actionName, index) => (
                                    <Box ml={1} key={`${actionName}_${category}_${index}`}>
                                        <Box style={{ borderBottom: "1px dashed rgba(0,0,0,0.1)" }}>
                                            <Grid container justifyContent="center" alignItems="center">

                                                <Grid item xs={6} justifyContent="center" alignItems="center">
                                                    <Box display={"flex"} >
                                                        <Typography variant="body1">{actionName}</Typography>
                                                    </Box>
                                                </Grid>
                                                {Object.keys(notificationActions[category][actionName]).map(actionType => (

                                                    <Grid item xs={2}>
                                                        <Box key={actionType} display={"flex"} justifyContent="center" alignItems="center"  >
                                                            <Box  >
                                                                {actionType.endsWith("_PUSH") && (
                                                                    <Typography variant="body1">
                                                                        {notificationActions[category][actionName][actionType] === "none"
                                                                            ? "NA"
                                                                            : (
                                                                                <Checkbox
                                                                                    checked={isCheckBoxChecked(category, actionName, actionType, notificationPreferences, "push")}
                                                                                    onChange={() => handleCheckBoxChange(category, actionName, actionType, notificationPreferences, "push")}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Typography>
                                                                )}
                                                                {actionType.endsWith("_MAIL") && (
                                                                    <Typography variant="body1">
                                                                        {notificationActions[category][actionName][actionType] === "none"
                                                                            ? "NA"
                                                                            : (
                                                                                <Checkbox
                                                                                    checked={isCheckBoxChecked(category, actionName, actionType, notificationPreferences, "email")}
                                                                                    onChange={() => handleCheckBoxChange(category, actionName, actionType, notificationPreferences, "email")}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Typography>
                                                                )}
                                                                {actionType.endsWith("_WHATSAPP") && (
                                                                    <Typography variant="body1">
                                                                        {notificationActions[category][actionName][actionType] === "none"
                                                                            ? "NA"
                                                                            : (
                                                                                <Checkbox
                                                                                    checked={isCheckBoxChecked(category, actionName, actionType, notificationPreferences, "whatsapp")}
                                                                                    onChange={() => handleCheckBoxChange(category, actionName, actionType, notificationPreferences, "whatsapp")}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Typography>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Paper>
                    ))}
                </Box>
            )}

            <BottomBar
                isLoading={false}
                data={data}
                state={notificationPreferences}
                onCancel={() => setNotificationPreferences(data)}
                onUpdate={handleUpdatePermissons}
            />
        </Box>

    )
}

export default AddPreferences