import { Box, Button, Typography } from "@mui/material"
import { exportClientForm, getClientForm, getIncomeTaxProfile } from "api/services/automation/income-tax";
import Table from "components/Table";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ResType } from "types";
import { StyledSpan } from "./styles";
import { useState } from "react";
import AccessDenied from "./Access/accessDenide";
import Loader from "components/Loader";
import { getCurrentFormattedDateTime } from "utils";
import { snack } from "components/toast";
import { handleError } from "utils/handleError";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const ClientForm = (state) => {
    const params = useParams();
    const navigate = useNavigate()
    const [page, setPage] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(10);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [checkAccess, setCheckAccess] = useState(true)

    const { data, isLoading }: ResType = useQuery(
        ["get-form-data", params.incometaxid, { limit: pageCount, offset: page * pageCount }],
        getClientForm,
        {
            onSuccess: (res) => {
                setCheckAccess(res?.data?.accessDenied)
                setTotalCount(res?.data?.count);
            },
        }
    );


    function calculateAssessmentYear(startYear) {
        const endYear = parseInt(startYear) + 1;
        return startYear + "-" + endYear.toString().slice(2);
    }
    let columns = [
        {
            key: "id",
            title: "FY / AY",
            render: (row: any) => {
                return (
                    <Box color="#ffffff">
                        <StyledSpan bgColor={row?.refYearType === "FY" ? "#005580" : "#7e8bca"} >{row?.refYear ? `${row?.refYearType} ${calculateAssessmentYear(row?.refYear)}` : ""}</StyledSpan>

                    </Box>
                )
            },
        },
        {
            key: "filingTypeCd",
            title: "Filing Type",
        },
        {
            key: "formDesc",
            title: "Form Name",
        },
        {
            key: "ackNum",
            title: "Acknowledgement #",
        },
        {
            key: "ackDt",
            title: "Date of Filing",
        },
        {
            key: "udinNum",
            title: "UDIN",
            render: (row: any) => {
                return (
                    <Typography>{row?.udinNum ? row?.udinNum : '-'}</Typography>
                )
            }
        },



    ];

    const handleRowClick = (v: any) => {
        navigate(`/atom-pro/income-tax/incometax/${params.incometaxid}/form/${v?.id}`)
    }
    const { data: profiledata, isLoading: isprofileloading }: ResType = useQuery(
        ["get-income-tax-profile", params.incometaxid],
        getIncomeTaxProfile,
      );
      const clientName = profiledata?.data?.profileDetails?.name;
      const { mutate: mutate1 } = useMutation(exportClientForm, {
        onSuccess: (res: any) => {
          const arr = new Uint8Array(res.data?.data);
          const blob = new Blob([arr], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const file = window.URL.createObjectURL(blob);
          let link = document.createElement("a");
          const formattedDateTime = getCurrentFormattedDateTime();
          link.href = file;
          link.download = `ATOM Pro - ${clientName} Income Tax Forms ${formattedDateTime}.xlsx`;
          link.click();
          snack.success("Exported successfully");
        },
        onError: (err: any) => {
          snack.error(err);
          snack.error(handleError(err));
        },
      });
    
      const handleExport = () => {
        mutate1({
          incometaxid: params.incometaxid,
          ...state,
    
    
        });
    
      }

    if (isLoading) return <Loader />
    return (
        <>
            {!checkAccess && (
                <AccessDenied />
            )}
            <Box display="flex" justifyContent="flex-end" sx={{ p: 1 }}>
                <Button variant="outlined" color="secondary" onClick={handleExport}
                    startIcon={<FileDownloadOutlinedIcon />}>
                    Export
                </Button>
            </Box>
            {checkAccess && (
                <Box>
                    {data && data?.data.count > 0 ?
                        <>
                            <Table
                                // selection={{ selected, setSelected }}
                                pagination={{ totalCount, pageCount, setPageCount, page, setPage }}
                                data={data?.data?.result || []}
                                columns={columns}
                                loading={isLoading}
                                onRowClick={handleRowClick}
                            />
                        </> : <>
                            <Box textAlign="center" mt={20}>
                                <Typography variant="subtitle1" color="rgba(0,0,0,0.5)">
                                    No Data present
                                </Typography>
                            </Box>
                        </>}
                </Box>
            )}
        </>
    )
}

export default ClientForm