// import { AppBar, Autocomplete, Button, Dialog, IconButton, List, ListItemButton, TextField, Toolbar, Typography } from "@mui/material";
// import { Box } from "@mui/system";
// import { getLabels } from "api/services/labels";
// import DrawerWrapper from "components/DrawerWrapper";
// import { useEffect, useState } from "react";
// import { useQuery, UseQueryResult } from "react-query";
// import { DataResponse, DialogProps } from "types";
// import { CLIENT_CATEGORIES } from "data/constants";
// import { Close } from "@mui/icons-material";
// import Loader from "components/Loader";
// import { addFilters } from "redux/reducers/clientsSlice";
// import { useDispatch } from "react-redux";

// interface ClientFilterProps extends DialogProps {
//   filters: any;
//   setFilters: (v: any) => void;
// }

// function ClientFilter(props: ClientFilterProps) {
//   const { open, setOpen, filters, setFilters } = props;
//   const dispatch = useDispatch();


//   const { data: labels, isLoading: labelsLoading }: UseQueryResult<DataResponse, Error> = useQuery(
//     "labels",
//     getLabels
//   );

//   const [state, setState] = useState<any>({
//     category: [],
//     subCategory: [],
//     monthAdded: "",
//     labels: [],
//   });

//   useEffect(() => {
//     if (!open) return;
//     setState({
//       ...filters,
//     });
//   }, [open, filters]);

//   const handleResetFilters = () => {
//     setFilters({
//       ...filters,
//       category: [],
//       subCategory: [],
//       monthAdded: "",
//       labels: [],
//     });
//     setOpen(false);
//   };

//   const handleSubmit = (e: any) => {
//     e.preventDefault();
//     setOpen(false);
//     dispatch((addFilters(
//       {
//         values: {
//           ...state,
//           category:
//         }
//       }
//     )))
//   };

//   let getSubCategories = (): any[] => {
//     let result: any[] = [];
//     if (!state?.category?.subCategories) return result;
//     result = result.concat(state?.category?.subCategories);
//     return result?.flat();
//   };

//   return (
//     <Dialog
//       sx={{ alignItems: "flex-start", maxHeight: 600 }}
//       fullWidth
//       maxWidth="sm"
//       open={open}
//       PaperProps={{
//         elevation: 0,
//         sx: { display: "flex", flexDirection: "column" },
//       }}
//     >
//       <AppBar
//         position="static"
//         color="transparent"
//         sx={{ boxShadow: "none", borderBottom: "1px solid lightgrey" }}
//       >
//         <Toolbar sx={{ justifyContent: "space-between", alignItems: "center" }}>
//           <Typography variant="subtitle1">Filters</Typography>
//           <IconButton onClick={() => setOpen(false)}>
//             <Close color="primary" />
//           </IconButton>
//         </Toolbar>
//       </AppBar>

//       {labelsLoading ? (
//         <Loader minHeight="400px" />
//       ) : (
//         <>

//           <Box sx={{ overflowY: "auto", mt: 2, pt: 1, px: 2, pb: 4, flex: 1 }}>


//             <TextField
//               variant="outlined"
//               fullWidth
//               onChange={(e) => {
//                 setState({
//                   ...state,
//                   monthAdded: e.target.value !== "" ? [e.target.value] : "",
//                 });
//               }}
//               value={state.monthAdded}
//               name="monthAdded"
//               required
//               InputLabelProps={{ shrink: true }}
//               size="small"
//               label="Month Added"
//               type="month"
//             />
//             <Autocomplete
//               id="tags-standard"
//               onChange={(_, value) => {
//                 console.log(value);
//                 setState({
//                   ...state,
//                   category: value || [],
//                   subCategory: [], 
//                 });
//               }}
//               value={state.category || null}
//               options={CLIENT_CATEGORIES || []}
//               sx={{ mt: 3 }}
//               getOptionLabel={(option: any) => option?.label || ''}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   required
//                   variant="outlined"
//                   size="small"
//                   fullWidth
//                   label="Client Category"
//                 />
//               )}
//             />
//             {getSubCategories().length !== 0 && (
//               <Autocomplete
//                 id="tags-standard"
//                 multiple
//                 onChange={(_, value) => {
//                   setState({
//                     ...state,
//                     subCategory: value,
//                   });
//                 }}
//                 value={state.subCategory || []}
//                 options={getSubCategories() || []}
//                 sx={{ mt: 3 }}
//                 getOptionLabel={(option: any) => option?.label}
//                 renderInput={(params) => (
//                   <TextField
//                     {...params}
//                     required
//                     variant="outlined"
//                     size="small"
//                     fullWidth
//                     label="Client Sub Category"
//                   />
//                 )}
//               />
//             )}
//             <Autocomplete
//               id="tags-standard"
//               multiple
//               onChange={(_, value) => {
//                 setState({
//                   ...state,
//                   labels: value,
//                 });
//               }}
//               value={state.labels || []}
//               options={labels?.data?.[0] || []}
//               sx={{ mt: 3 }}
//               getOptionLabel={(option: any) => option?.name}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   required
//                   variant="outlined"
//                   size="small"
//                   fullWidth
//                   label="Labels"
//                 />
//               )}
//             />

//           </Box>

//         </>

//       )}

//       <AppBar
//         position="static"
//         color="transparent"
//         sx={{ boxShadow: "none", mb: 2, borderTop: "1px solid lightgrey" }}
//       >
//         <Toolbar sx={{ justifyContent: "flex-end", gap: 2, alignItems: "center" }}>

//           <Button
//             onClick={handleResetFilters}
//             sx={{ mt: 3 }}
//             variant="outlined"
//             color="secondary"
//             type="submit"
//           >
//             Reset
//           </Button>
//           <Button
//             sx={{ mt: 3 }}
//             variant="contained"
//             onClick={handleSubmit}
//             color="secondary"
//             type="submit"
//           >
//             Apply
//           </Button>

//         </Toolbar>
//       </AppBar>

//     </Dialog>
//   );
// }

// export default ClientFilter;




import { Close } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  IconButton,
  List,
  ListItemButton,
  Toolbar,
  Typography,
  Tooltip as Tp,
  TextField,
  Autocomplete,
  MenuItem
} from "@mui/material";
import { getLabels } from "api/services/labels";
import { getUsers } from "api/services/users";
import Loader from "components/Loader";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { handleApply, resetState, } from "redux/reducers/clientsSlice";
import { ResType } from "types";

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { getFilters } from "views/tasks/Filters/getFilters";
import { selectClient } from "redux/reducers/clientsSlice";
import { handleFieldChange } from "redux/reducers/clientsSlice";
import { CLIENT_CATEGORIES } from "data/constants";

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}




function AllFiltersDialog({ open, setOpen }: IProps) {
  const dispatch = useDispatch();
  const states = useSelector(selectClient);
  const { data: labels, isLoading: labelsLoading }: ResType = useQuery("labels", getLabels, {
    enabled: open,
  });

  const { data: users, isLoading: userLoading }: ResType = useQuery("users", getUsers, { enabled: open });

  const applyFilters = () => {
    dispatch(handleApply());
    setOpen(false);
  };

  const handleReset = () => {
    dispatch(resetState());
    setOpen(false);
  };

  const filters = getFilters({ users, labels });

  let getSubCategories = (): any[] => {
    let result: any[] = [];
    if (!states?.selectedFilters.category?.subCategories) return result;
    result = result.concat(states?.selectedFilters.category?.subCategories);
    return result?.flat();
  };

  interface StatusOption {
    name: string;
    value: string;
  };

  const options =
    [{ name: "ACTIVE", value: "ACTIVE" },
    { name: "INACTIVE", value: "INACTIVE" }]


  return (
    <Dialog
      sx={{ alignItems: "flex-start", maxHeight: 600 }}
      fullWidth
      maxWidth="sm"
      open={open}
      PaperProps={{
        elevation: 0,
        sx: { display: "flex", flexDirection: "column" },
      }}
    >
      <AppBar
        position="static"
        color="transparent"
        sx={{ boxShadow: "none", borderBottom: "1px solid lightgrey" }}
      >
        <Toolbar sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="subtitle1">Filters</Typography>
          <IconButton onClick={() => setOpen(false)}>
            <Close color="primary" />
          </IconButton>
        </Toolbar>
      </AppBar>
      {labelsLoading ? (
        <Loader minHeight="400px" />
      ) : (
        <>

          <Box sx={{ overflowY: "auto", mt: 2, pt: 1, px: 2, pb: 4, flex: 1 }}>


            <TextField
              variant="outlined"
              fullWidth
              onChange={(e) => dispatch(handleFieldChange({ key: "monthAdded", value: e.target.value }))}
              value={states.selectedFilters.monthAdded}
              name="monthAdded"
              required
              InputLabelProps={{ shrink: true }}
              size="small"
              label="Month Added"
              type="month"
            />
            <Autocomplete
              id="tags-standard"
              onChange={(_, value) => dispatch(handleFieldChange({ key: "category", value: value || [] }))}
              value={states.selectedFilters.category || null}
              options={CLIENT_CATEGORIES || []}
              sx={{ mt: 3 }}
              getOptionLabel={(option: any) => option?.label || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Client Category"
                />
              )}
            />
            {getSubCategories().length !== 0 && (
              <Autocomplete
                id="tags-standard"
                multiple
                // onChange={(_, value) => {
                //   setState({
                //     ...state,
                //     subCategory: value,
                //   });
                // }}
                onChange={(_, value) => dispatch(handleFieldChange({ key: "subCategory", value: value || [] }))}
                value={states.selectedFilters.subCategory || []}
                options={getSubCategories() || []}
                sx={{ mt: 3 }}
                getOptionLabel={(option: any) => option?.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    variant="outlined"
                    size="small"
                    fullWidth
                    label="Client Sub Category"
                  />
                )}
              />
            )}
            <Autocomplete
              id="tags-standard"
              multiple
              onChange={(_, value) => dispatch(handleFieldChange({ key: "labels", value: value || [] }))}
              value={states.selectedFilters.labels || []}
              options={labels?.data?.[0] || []}
              sx={{ mt: 3 }}
              getOptionLabel={(option: any) => option?.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Labels"
                />
              )}
            />

            <Autocomplete
              id="tags-standard"
              options={options || []}
              getOptionLabel={(option) => option.name || ""}
              onChange={(_, value) =>
                dispatch(handleFieldChange({ key: "status", value: value || '' }))
              }
              value={states?.appliedFilters?.status}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{ mt: 3 }}
                />
              )}
              fullWidth
              size="small"
            />
          </Box>
        </>)}
      <AppBar
        position="static"
        color="transparent"
        sx={{ boxShadow: "none", borderTop: "1px solid lightgrey" }}
      >
        <Toolbar sx={{ justifyContent: "flex-end", gap: 2, alignItems: "center" }}>
          <Button onClick={handleReset} variant="outlined" color="secondary">
            Reset
          </Button>
          <Button onClick={applyFilters} variant="contained" color="secondary">
            Apply
          </Button>
        </Toolbar>
      </AppBar>
    </Dialog>
  );
}

export default AllFiltersDialog;