import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useConfirm } from "context/ConfirmDialog";
import { snack } from "components/toast";
import { bulkDelete, bulkGroupDelete, bulkGroupUpdate, bulkUpdate } from "api/services/clients/clients";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ValidateAccess from "components/ValidateAccess";
import { Permissions } from "data/permissons";
import { handleError } from "utils/handleError";
import { getGroupPendingTasks, getPendingTasks, getTasks } from "api/services/tasks/tasks";
import { ResType } from "types";

interface Props {
  anchorEl: HTMLElement | null;
  setAnchorEl: (v: HTMLElement | null) => void;
  selected: any[];
  clearSelection: () => void;
  setSelected: ([]) => void;
}

type Type = HTMLElement | null;

function Actions(props: Props) {
  const { anchorEl, setAnchorEl, setSelected, selected, clearSelection } = props;
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const [nestedAnchorEl, setNestedAnchorEl] = useState<Type>(null);
  const [filterPendingTaks, setFilteredPendingTasks] = useState([]);


  const { mutate } = useMutation(bulkGroupDelete, {
    onSuccess: () => {
      snack.success("Client Group(s) deleted");
      queryClient.invalidateQueries("clients-group");
      queryClient.invalidateQueries("clients-group");
    },
    onError: (err: any) => {
      snack.error(handleError(err));
    },
  });

  const { mutate: updateClients } = useMutation(bulkGroupUpdate, {
    onSuccess: () => {
      snack.success("Client Group(s) Status Updated");
      clearSelection();
      queryClient.invalidateQueries("clients-group");
      queryClient.invalidateQueries("clients-group");
    },
    onError: (err: any) => {
      snack.error(err.response.data.message);
    },
  });

  const { data: taskData, isLoading: loading }: ResType = useQuery(
    ["tasks", {
      selected: selected,
    },
    ],
    getGroupPendingTasks,
    {
      enabled: Boolean(selected.length),
      onError: (err: any) => {
        if (!err.error) {
          snack.error(`An error occurred while fetching upcoming tasks`);
        }
      },
      cacheTime: 0,
    }
  );

  const handleDelete = () => {
    setAnchorEl(null);
    if (taskData?.data?.length ) {
      const listData = taskData?.data?.map((clientData: any, index: any) => {
        return (
          <ListItem sx={{ margin: 0, padding: 0, listStyleType: 'disc' }}>
            <ListItemText primary={`${index + 1}. ${clientData?.clientGroup?.displayName}`} sx={{ fontWeight: 'bold' }} />
          </ListItem>
        )
      });
      confirm({
        msg: <Box>
          <Typography>Are you sure you want to delete the Client Group(s) ?</Typography>
          <Typography>If you Delete these Clients Group, their Recurring Profiles will be Terminated</Typography>
          <hr/>
          <Typography>These are the List of Clients Group have Recurring Profile With Pending Tasks</Typography>
          <List>
            {listData}
          </List>
        </Box>,
        action: () => {
          mutate({
            ids: selected.map((c: any) => c.id),
          });
          setSelected([]);
        },
      });
    } else {
      confirm({
        msg: "Are you sure you want to delete the Client Group(s) ?",
        action: () => {
          mutate({
            ids: selected.map((c: any) => c.id),
          });
          setSelected([]);
        },
      });
    }

  };

  const handleStatus = (status: string) => {
    setNestedAnchorEl(null);
    setAnchorEl(null);
    if (taskData?.data?.length && status === "INACTIVE") {
      const listData = taskData?.data?.map((clientData: any, index: any) => {
        return (
          <ListItem sx={{ margin: 0, padding: 0, listStyleType: 'disc' }}>
            <ListItemText primary={`${index + 1}. ${clientData?.clientGroup?.displayName}`} sx={{ fontWeight: 'bold' }} />
          </ListItem>
        )
      });
      confirm({
        msg: <Box>
          <Typography>Are you sure you want to change the status of the Client Group(s) ?</Typography>
          <Typography>If you Inactivate these Clients Group, their Pending Recurring, Non-Recurring and Sub Tasks will be will be Terminated</Typography>
          <hr/>
          <Typography>These are the List of Clients Group having Pending Tasks</Typography>
          <List>
            {listData}
          </List>
        </Box>,
        action: () => {
          updateClients({
            ids: selected.map((c: any) => c.id),
            data: {
              status,
            },
          });
          setSelected([]);
        },
      });
    } else {
      confirm({
        msg: "Are you sure you want to change the status of the Client Group(s) ?",
        action: () => {
          updateClients({
            ids: selected.map((c: any) => c.id),
            data: {
              status,
            },
          });
          setSelected([]);
        },
      });
    }
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <ValidateAccess name={Permissions.EDIT_CLIENT_GROUPS}>
          <MenuItem onClick={(e) => setNestedAnchorEl(e.currentTarget)}>
            <Box alignItems="center" display="flex" gap={1}>
              Change status
              <ChevronRightOutlinedIcon fontSize="small" />
            </Box>
          </MenuItem>
        </ValidateAccess>
        <ValidateAccess name={Permissions.DELETE_CLIENT_GROUPS}>
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </ValidateAccess>
      </Menu>
      <NestedActions
        onClick={handleStatus}
        anchorEl={nestedAnchorEl}
        setAnchorEl={setNestedAnchorEl}
      />
    </>
  );
}

function NestedActions({ anchorEl, setAnchorEl, onClick }) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <MenuItem onClick={() => onClick("ACTIVE")}>Active</MenuItem>
      <MenuItem onClick={() => onClick("INACTIVE")}>Inactive</MenuItem>
    </Menu>
  );
}

export default Actions;
