import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { IAddService } from "./types";
import { CLIENT_CATEGORIES } from "data/constants";


const initialClientSubCategory = {}; // Initialize an empty object for clientSubCategory
CLIENT_CATEGORIES.forEach((clientCategory) => {
  initialClientSubCategory[clientCategory.label] = {
    label: clientCategory.label,
    value: clientCategory.value,
    subCategories: clientCategory?.subCategories,
  };
});

const initialState: IAddService = {
  name: "",
  description: "",
  category: null,
  subCategory: null,
  hourlyPrice: "",
  totalPrice: "",
  checklists: [],
  milestones: [],
  stageOfWork: [],
  subTasks: [],
  subtaskServices:[],
  clientSubCategory: initialClientSubCategory,
  state: "",
  serviceFaqs:[],
  serviceProcedure:[],
  linkedServices:[],
  labels:[],
  isRecurring:false,
  frequency:null,
  recurringFrequencyDetails:null,
};

export const addServiceSlice = createSlice({
  name: "addService",
  initialState,
  reducers: {
    setData: (state: IAddService, action: PayloadAction<any>) => {
      const { payload } = action;
      state.name = payload.name;
      state.description = payload.description || "";
      state.category = payload.category?.id || null;
      state.subCategory = payload.subCategory?.id || null;
      state.hourlyPrice = payload.hourlyPrice || "";
      state.totalPrice = payload.totalPrice || "";
      state.checklists = payload.checklists;
      state.milestones = payload.milestones;
      state.stageOfWork = payload.stageOfWorks;
      state.subTasks = payload.subTasks;
      state.subtaskServices = payload.subtaskServices || [];
      state.state = payload.state || "";
      state.clientSubCategory = payload.clientSubCategory || {};
      state.serviceFaqs = payload.serviceFaqs || [];
      state.serviceProcedure = payload.serviceProcedure || [];
      state.linkedServices = payload.linkedServices || [];
      state.labels = payload.labels || [];
      state.isRecurring = payload.isRecurring;
      state.frequency = payload.frequency;
      state.recurringFrequencyDetails= payload.recurringFrequencyDetails;
    },
    resetData: (state: IAddService) => {
      state.name = "";
      state.description = "";
      state.category = null;
      state.subCategory = null;
      state.hourlyPrice = "";
      state.totalPrice = "";
      state.checklists = [];
      state.milestones = [];
      state.stageOfWork = [];
      state.subTasks = [];
      state.subtaskServices=[];
      state.state = "";
      state.clientSubCategory = initialClientSubCategory;
      state.serviceFaqs=[];
      state.serviceProcedure = [];
      state.linkedServices = [];
      state.labels = [];
      state.isRecurring = false;
      state.frequency = null;
    },
    handleChange: (state: IAddService, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    addChecklist(state: IAddService, action) {
      state.checklists.push(action.payload);
    },
    updateChecklistName(state: IAddService, action) {
      state.checklists[action.payload.index].name = action.payload.name;
    },
    deleteChecklist(state: IAddService, action) {
      state.checklists.splice(action.payload, 1);
    },
    addChecklistItem(state: IAddService, action) {
      state.checklists[action.payload.index].checklistItems.push(
        ...action.payload.items
      );
    },
    deleteChecklistItem(state: IAddService, action) {
      state.checklists[action.payload.checklistIndex].checklistItems.splice(
        action.payload.itemIndex,
        1
      );
    },
    updateChecklistItem(state: IAddService, action) {
      const { itemIndex, checklistIndex, data } = action.payload;
      state.checklists[checklistIndex].checklistItems[itemIndex] = data;
    },
    addMilestone(state: IAddService, action) {
      state.milestones.push(action.payload);
    },
    updateMilestone(state: IAddService, action) {
      state.milestones[action.payload.index] = action.payload.data;
    },
    deleteMilestone(state: IAddService, action) {
      state.milestones.splice(action.payload, 1);
    },
    addStageOfWork(state: IAddService, action) {
      state.stageOfWork.push(action.payload);
    },
    updateStageOfWork(state: IAddService, action) {
      state.stageOfWork[action.payload.index] = action.payload.data;
    },
    deleteStageOfWork(state: IAddService, action) {
      state.stageOfWork.splice(action.payload, 1);
    },
    addSubTask(state: IAddService, action) {
      state.subTasks.push(action.payload);
    },
    updateSubTask(state: IAddService, action) {
      state.subTasks[action.payload.index] = action.payload.data;
    },
    deleteSubTask(state: IAddService, action) {
      state.subTasks.splice(action.payload, 1);
    },

    addSubtaskServices(state: IAddService, action) {
      state.subtaskServices.push(action.payload);
    },

    removeSubtaskService(state: IAddService, action) {
     state.subtaskServices.splice(action.payload,1)
    },

    addLinkedServices(state: IAddService, action) {
      state.linkedServices.push(action.payload);
    },

    removeLinkedService(state: IAddService, action) {
     state.linkedServices.splice(action.payload,1)
    },

    setInitialClientSubCategory: (state: IAddService) => {
      const initialClientSubCategory = {};
      CLIENT_CATEGORIES.forEach((clientCategory) => {
        initialClientSubCategory[clientCategory.label] = {
          label: clientCategory.label,
          value: clientCategory.value,
          subCategories: [],
        };
      });
      state.clientSubCategory = initialClientSubCategory;
    },

     //SERVICE PROCEDURE
     addProChecklist(state: IAddService, action) {
      state.serviceProcedure.push(action.payload);
    },
    updateProChecklistName(state: IAddService, action) {
      state.serviceProcedure[action.payload.index].name = action.payload.name;
    },
    deleteProChecklist(state: IAddService, action) {
      state.serviceProcedure.splice(action.payload, 1);
    },
    addProChecklistItem(state: IAddService, action) {
      state.serviceProcedure[action.payload.index].checklistItems.push(
        ...action.payload.items
      );
    },
    deleteProChecklistItem(state: IAddService, action) {
      state.serviceProcedure[action.payload.checklistIndex].checklistItems.splice(
        action.payload.itemIndex,
        1
      );
    },
    updateProChecklistItem(state: IAddService, action) {
      const { itemIndex, checklistIndex, data } = action.payload;
      state.serviceProcedure[checklistIndex].checklistItems[itemIndex] = data;
    },

     //SERVICE FAQS 
     addFaqChecklist(state: IAddService, action) {
      state.serviceFaqs.push(action.payload);
    },
    updateFaqChecklistName(state: IAddService, action) {
      state.serviceFaqs[action.payload.index].name = action.payload.name;
    },
    deleteFaqChecklist(state: IAddService, action) {
      state.serviceFaqs.splice(action.payload, 1);
    },
    addFaqChecklistItem(state: IAddService, action) {
      state.serviceFaqs[action.payload.index].checklistItems.push(
        ...action.payload.items
      );
    },
    deleteFaqChecklistItem(state: IAddService, action) {
      state.serviceFaqs[action.payload.checklistIndex].checklistItems.splice(
        action.payload.itemIndex,
        1
      );
    },
    updateFaqChecklistItem(state: IAddService, action) {
      const { itemIndex, checklistIndex, data } = action.payload;
      state.serviceFaqs[checklistIndex].checklistItems[itemIndex] = data;
    },

    setRecurringFrequencyDetails: (state: IAddService, action) => {
      state.recurringFrequencyDetails = action.payload;
    },
  },
});

export const addServiceState = (state: RootState) => state.addService;

export const {
  addChecklist,
  addChecklistItem,
  updateChecklistName,
  deleteChecklist,
  deleteChecklistItem,
  updateChecklistItem,
  addMilestone,
  updateMilestone,
  deleteMilestone,
  addStageOfWork,
  updateStageOfWork,
  deleteStageOfWork,
  addSubTask,
  updateSubTask,
  deleteSubTask,
  handleChange,
  setData,
  resetData,
  addSubtaskServices,
  removeSubtaskService,
  addLinkedServices,
  removeLinkedService,
   ////////////////
   addFaqChecklist,
   addFaqChecklistItem,
   updateFaqChecklistName,
   deleteFaqChecklist,
   deleteFaqChecklistItem,
   updateFaqChecklistItem,
   ///////////////
   ////////////////
   addProChecklist,
   addProChecklistItem,
   updateProChecklistName,
   deleteProChecklist,
   deleteProChecklistItem,
   updateProChecklistItem,
   setRecurringFrequencyDetails
} = addServiceSlice.actions;

export default addServiceSlice.reducer;
