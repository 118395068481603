import { http } from "api/http";

const sendEmailTemplateService = ({ emails, content }) => {
  return http.post(`/communication/sendmail`, { emails, content });
};

const createClientGroupBroadcast = (data: any) => {
  return http.post("communication/create-client-group", data);
};

const getClientGroupBroadcast = ({ queryKey }: any) => {
  const [, params] = queryKey;
return http.get("/communication/get-client-group", { params });
};

const deleteClientGroupBroadcast = (id: number) => {
  return http.delete(`/communication/delete-client-group/${id}`);
};


const updateClientGroupBroadcast = ({ id, data }: any) => {
  return http.put(`/communication/update-client-group/${id}`, data);
};

const addClientsToClientGroup = ({ id, data }: any) => {
  return http.put(`/communication/add-clients-to-clientgroup/${id}`, data);
};

const removeClientsFromClientGroup = ({ id, data }: any) => {
  return http.put(`/communication/remove-clients-from-clientgroup/${id}`, data);
};

// const getClientGroupBroadcastDetails = (id:any) => {
//   return http.get(`/communication/get-client-groupdetails/${id}`);
// };
const getClientGroupBroadcastDetails = ({ queryKey }: any) => {
  const [_, id, search] = queryKey; 
  return http.get(`/communication/get-client-groupdetails/${queryKey[1].id}`,{ params: { ...queryKey[1] } });
};


const createEmailTemplatessss = (data: any) => {
  return http.post("communication/create-email-template", data);
};

const getEmailTemplates = ({ queryKey }: any) => {
  const [, params] = queryKey;
return http.get("/communication/get-email-template", { params });
};

const deleteEmailTemplate = (id: number) => {
  return http.delete(`/communication/delete-email-template/${id}`);
};

const getOneEmailTemplate = (id:any) => {
  return http.get(`/communication/get-one-emailTemplate/${id}`);
};

const updateEmailTemplate = ({ id, data }: any) => {
  return http.put(`/communication/update-email-temlate/${id}`, data);
};
const createBroadcastActivity = (data: any) => {
  return http.post("communication/create-broadcast-activity", data);
};

const uploadFileBroadcast = (data: any) => {
  return http.post("communication/upload-files", data);
};

const getBroadcastActivity = ({ queryKey }: any) => {
  const [, params] = queryKey;
  return http.get("communication/get-brocastactivity", { params });
};

const getBroadcastActivityDetails = ({ queryKey }: any) => {
  const [_, id, search] = queryKey; 
  return http.get(`/communication/get-broadcastactivity/${queryKey[1].id}`,{ params: { ...queryKey[1] } });
};




const getFilteredClients = ({ queryKey }: any) => {
  return http.get(`/communication/get-filtered-clients/${queryKey[1].id}`,{ params: { ...queryKey[1] } });
};
export {
  sendEmailTemplateService,
  createClientGroupBroadcast,
  getClientGroupBroadcast,
  deleteClientGroupBroadcast,
  updateClientGroupBroadcast,
  getClientGroupBroadcastDetails,
  createEmailTemplatessss,
  getOneEmailTemplate,
  updateEmailTemplate,
  getEmailTemplates,
  deleteEmailTemplate,
  createBroadcastActivity,
  getBroadcastActivity,
  uploadFileBroadcast,
  getBroadcastActivityDetails,
  getFilteredClients,
  addClientsToClientGroup,
  removeClientsFromClientGroup
};
