import { Checkbox, CircularProgress, Switch, TableCell, TablePagination, TableSortLabel, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box, SystemStyleObject } from "@mui/system";
import _ from "lodash";
import React, { ChangeEvent } from "react";
import { ScrollingContent, StyledTable, StyledTableContainer, StyledTableLoader } from "./styles";

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 75, 100];

export type ColumnType = {
  key: string;
  title: string | React.ReactElement;
  render?: (item: any) => React.ReactElement | string | null | number | boolean;
  hide?: boolean;
  default?: boolean;
  width?: string;
  sort?: boolean;
  active?: boolean;
  direction?: 'asc' | 'desc';
};

export type ExtendedColumnType = ColumnType & {
  render?: (
    item: any,
    getValues?: (row: any, event: ChangeEvent<HTMLInputElement>) => void
  ) => React.ReactElement | string | null | number;
};

type PaginationType = {
  totalCount: number;
  page: number;
  setPage: (page: number) => void;
  pageCount?: number;
  setPageCount?: (pageCount: number) => void;

};

type SelectionType = {
  selected: any[];
  setSelected: (selected: any[]) => void;
};

// sx?: any; //SystemStyleObject
interface TableProps {
  columns: Array<ColumnType>;
  sx?: any;
  data: any[];
  loading?: boolean;
  onRowClick?: (v: any) => void;
  sortHandler?: (v: any) => void;
  pagination?: PaginationType | null;
  selection?: SelectionType;
  heading?: string;

}

function Table(props: TableProps) {
  const {
    columns,
    data = [],
    sx,
    pagination,
    loading = false,
    onRowClick,
    sortHandler,
    selection,
    heading
  } = props;

  const { selected, setSelected } = selection || {
    selected: [],
    setSelected: () => { },
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected(data);
    } else {
      setSelected([]);
    }
  };


  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>, item: any) => {
    e.stopPropagation();
    if (e.target.checked) {
      setSelected([...selected, item]);
    } else {
      const filtered = selected?.filter((v: any) => v?.id !== item?.id);
      setSelected(filtered);
    }
  };

  const handleRowClick = (item: any) => {
    if (!onRowClick) return;
    onRowClick(item);
  };

  const handleCreateSortHandler = (v: any) => {
    if (!sortHandler) return;
    sortHandler(v);
  }

  const handlePageChange = (v: any, page: number) => {
    pagination?.setPage(page);
    setSelected([]);
  };

  const handleRowsPerPageChange = (e: any) => {
    if (pagination?.setPageCount) {
      pagination?.setPage(0);
      pagination?.setPageCount(+e.target.value);
      setSelected([]);
    }
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = {
    base: { height: isMobile ? "auto" : '70vh', overflow: "scroll" },
    enhance: sx,
  };

  // const styles = {
  //   height: '400px',
  //   overflow: 'scroll',
  //   ...sx
  // };

  return (
    <>
      <StyledTableContainer sx={sx}>
        <div style={{ ...styles.base, ...sx }}>
          {heading && <Typography variant="h5" textAlign='center'>
            {heading}
          </Typography>}

          <StyledTable>
            <thead>
              <tr>
                {selection && data?.length > 0 && (
                  <th style={{ width: 50 }}>
                    <Checkbox
                      color="secondary"
                      onChange={handleSelectAll}
                      checked={selected?.length === data?.length}
                    />
                  </th>
                )}
                {columns.map((item, index) => {
                  if (item.hide) return null;
                  return (

                    <TableCell

                      component='th'
                      key={item.key}
                      sortDirection={false}
                      sx={{
                        width: item.width || 'auto',
                        fontWeight: 600,
                      }}
                    >
                      <Box display='flex'>
                        <>{item.title}</>
                        {item.sort && (
                          <TableSortLabel
                            active={item?.active}
                            direction={item.direction}
                            onClick={() => handleCreateSortHandler(item)}
                            sx={{
                              '& .MuiTableSortLabel-icon': {
                                opacity: item?.active ? 1 : 0.1,
                                color: "red"
                              },
                              '&:hover .MuiTableSortLabel-icon': {
                                opacity: 1,
                                color: 'inherit',
                              },
                            }}
                          />

                        )}
                      </Box>

                    </TableCell>
                  )

                }

                )}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  {selection && (
                    <td>
                      <Checkbox
                        onChange={(e) => handleSelect(e, item)}
                        color="secondary"
                        checked={Boolean(_.find(selected, { id: item?.id }))}
                      />
                    </td>
                  )}
                  {/* {columns.map((col, colIndex) => {
                  if (col.hide) return null;
                  console.log("collllllllllllll", col)
                  return (
                    <td key={colIndex} onClick={() => handleRowClick(item)} {col.key==="action"}>
                      {col?.render ? (
                        col.render(item)
                      ) : (
                        <Typography variant="body2">{_.get(item, col.key)}</Typography>
                      )}
                    </td>
                  );
                })} */}

                  {columns.map((col, colIndex) => {
                    if (col.hide) return null;
                    return (
                      <td key={colIndex} onClick={() => handleRowClick(item)}>
                        {col?.render ? (
                          col.render(item)
                        ) : (
                          <Typography
                            variant="body2"
                            style={{
                              color: "inherit",
                            }}
                          >
                            {_.get(item, col.key)}
                          </Typography>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </div>
        {pagination && (
          <Box justifyContent="flex-end" display="flex">
            <TablePagination
              component="div"
              count={pagination.totalCount || 10}
              page={pagination.page || 0}
              onPageChange={handlePageChange}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              rowsPerPage={pagination.pageCount || 10}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        )}
        {loading && (
          <StyledTableLoader>
            <CircularProgress color="primary" />
          </StyledTableLoader>
        )}
        {!loading && !data.length ? (
          <StyledTableLoader>
            <Typography variant="subtitle2" color="rgba(0,0,0,0.5)">
              No data
            </Typography>
          </StyledTableLoader>
        ) : null}
      </StyledTableContainer >
    </>

  );
}


export default Table;
