import { http } from "api/http";
import OrgConfigDetails from "views/settings/organization/OrganizationProfile/OrgConfigDetails";

const getOrganization = () => {
  return http.get(`/organization`);
};

const getGetStarted = () => {
  return http.get(`/get-started`);
};

const updateGetStarted = (data: any) => {
  return http.patch(`/get-started`, data);
};

const getOrganizationLicenses = ({ queryKey }) => {
  return http.get(`/organization-lincenses`, {
    params: {
      ...queryKey[1],
    },
  });
};

const getBankAccounts = ({ queryKey }) => {
  return http.get(`/bank-accounts`, {
    params: {
      ...queryKey[1],
    },
  });
};

const createBankAccount = (data: any) => {
  return http.post(`/bank-accounts`, data);
};

const updateBankAccount = ({ id, data }: any) => {
  return http.put(`/bank-accounts/${id}`, data);
};

const deleteBankAccount = ({ id }: any) => {
  return http.delete(`/bank-accounts/${id}`);
};

const defaultBankAccount = ({ id, data }: any) => {
  return http.put(`/bank-accounts/default/${id}`, data)
}

const createOrganizationLicense = ({ data }: any) => {
  return http.post(`/organization-lincenses`, data);
};

const updateOrganizationLicense = ({ id, data }: any) => {
  return http.patch(`/organization-lincenses/${id}`, data);
};

const updateOrganization = ({ data }: any) => {
  return http.patch(`/organization`, data);
};

const deleteOrganizationLicense = ({ id }: any) => {
  return http.delete(`/organization-lincenses/${id}`);
};

const getTaskAnalytics = ({ queryKey }) => {
  return http.get(`/stats/task-analytics`, { params: { ...queryKey[1] } });
};

const getTaskBoardAnalytics = ({ queryKey }) => {
  return http.get(`/tasks/task-analytics`, { params: { ...queryKey[1] } });
};



const getTasksDueThisWeek = ({ queryKey }) => {
  return http.get(`/stats/tasks-due-this-week`, { params: { ...queryKey[1] } });
};



const getTasksByCategory = ({ queryKey }) => {
  return http.get(`/stats/tasks-by-category`, { params: { ...queryKey[1] } });
};



const getTasksByCategoryExport = (data) => {
  return http.post(`/stats/tasks-by-category-export`, data);
};

const getTasksByClientCategory = ({ queryKey }) => {
  return http.get(`/stats/tasks-by-client-category`, { params: { ...queryKey[1] } });
};

const getTasksByClientCategoryExport = (data) => {
  return http.get(`/stats/tasks-by-client-category-export`, data);
};


const getTasksByService = ({ queryKey }) => {
  return http.get(`/stats/tasks-by-service`, { params: { ...queryKey[1] } });
};

const exportTasksByService = (data) => {
  return http.post(`/stats/tasks-by-service-export`, data);
};

const getOverdueTasks = ({ queryKey }) => {
  return http.get(`/stats/over-due-tasks`, { params: { ...queryKey[1] } });
};

const exportOverDueTask = (data) => {
  return http.post(`/stats/over-due-tasks-export`, data);
};

const getClientAnalytics = () => {
  return http.get(`/stats/client-analytics`);
};

const getClientBillingAnalytics = ({ queryKey }) => {
  return http.get(`/stats/client-billing-analytics`, { params: { ...queryKey[1] } });
}

const getDueDscRegisters = () => {
  return http.get(`/stats/due-dsc-registers`);
};

const getClientsByCategory = ({ queryKey }) => {
  return http.get(`/stats/clients-by-category`,{ params: { ...queryKey[1] } });
};
const getClientsByCategoryexport = (data) => {
  return http.post(`/stats/clients-by-category-export`, data);
};


const getTotalLogHours = ({ queryKey }) => {
  return http.get(`/stats/total-log-hours`, { params: { ...queryKey[1] } });
};

const getWeeklyLogHours = ({ queryKey }) => {
  return http.get(`/stats/weekly-log-hours`, { params: { ...queryKey[1] } });
};

const getEmployeeTasksByStatus = ({ queryKey }) => {
  return http.get(`/stats/employee-tasks-by-status`, { params: { ...queryKey[1] } });
};

const exportEmployeeTasksByStatus = (data) => {
  return http.post(`/stats/employee-tasks-by-status-report`, data);
};

const getDailyAttendance = ({ queryKey }) => {
  return http.get(`/stats/employee-attendance`, { params: { ...queryKey[1] } });
};

const getSmtp = () => {
  return http.get(`/organization/smtp`);
};

const updateSmtp = ({ data }) => {
  return http.patch(`/organization/smtp`, data);
};

const updateSmtpMail = ({ data }) => {
  return http.patch(`/organization/smtp/mail`, data);
};

const updateSmtpPreferences = ({ data }) => {
  return http.patch(`/organization/smtp/preferences`, data);
};

const getUdinUsers = () => {
  return http.get('/organization/udin-users')
}

export {
  exportOverDueTask,
  getTasksByClientCategoryExport,
  getTasksByCategoryExport,
  getClientsByCategoryexport,
  exportEmployeeTasksByStatus,
  exportTasksByService,
  getOrganization,
  updateOrganization,
  getOrganizationLicenses,
  createOrganizationLicense,
  updateOrganizationLicense,
  deleteOrganizationLicense,
  getBankAccounts,
  createBankAccount,
  updateBankAccount,
  deleteBankAccount,
  getTaskAnalytics,
  getGetStarted,
  updateGetStarted,
  getTasksDueThisWeek,
  getTasksByCategory,
  getTasksByClientCategory,
  getTasksByService,
  getOverdueTasks,
  getClientAnalytics,
  getDueDscRegisters,
  getClientsByCategory,
  getTotalLogHours,
  getWeeklyLogHours,
  getEmployeeTasksByStatus,
  OrgConfigDetails,
  getTaskBoardAnalytics,
  defaultBankAccount,
  getSmtp,
  updateSmtp,
  getDailyAttendance,
  getClientBillingAnalytics,
  getUdinUsers,
  updateSmtpMail,
  updateSmtpPreferences,
};
