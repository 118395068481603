import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, IconButton, Menu, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import { snack } from "components/toast";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useRef, useState } from "react";
import countries from "data/countries";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";


interface TextFieldWithCopyProps {
    value: string;
    label: string;
    name?: string;
    disabled?: boolean;
    required?: boolean;
    onChange?: (e: any) => void;
    counteyChange?: any;
    onBlur?: (e: any) => void;
    error?: boolean;
    helperText?: any;
    inputProps?: any;
    size?: "small" | "medium";
    country: any;

}

function TextFieldWithCountryCode(props: TextFieldWithCopyProps) {
    const { country, label, value, name, counteyChange, onChange, onBlur, disabled = false, required, error, helperText, inputProps,
        size = "small", } = props;

    const codeRef = useRef<HTMLElement | null>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const onCopy = () => {
        window.navigator.clipboard.writeText(value).then(
            function () {
                navigator.clipboard.readText().then(() => {
                    snack.success(`${label} Copied`);
                });
            },
            function (err) {
                snack.error("something went wrong");
            }
        );
    };

    let countryCode = countries.find((c) => c.code === country);
    return (
        <TextField
            label={label}
            fullWidth
            variant="outlined"
            size={size}
            onBlur={onBlur}
            value={value}
            disabled={disabled}
            required={required}
            name={name}
            onChange={onChange}
            InputProps={{
                startAdornment: (<>
                    <Box sx={{ position: "relative", mr: 11, }}>
                        <Box
                            ref={codeRef}
                            sx={{
                                position: "absolute",
                                backgroundColor: "lightgray",
                                left: 0,
                                top: 0,
                                height: "100%",
                                minWidth: 9,
                                display: "flex",
                                gap: 1,
                                alignItems: "center",
                                px: 1,
                                zIndex: 0,
                                cursor: "pointer",
                            }}
                            onClick={(e) => setAnchorEl(e.currentTarget)}
                        >
                            <Box display="flex" >
                                <Box>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${countryCode?.code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${countryCode?.code.toLowerCase()}.png 2x`}
                                        alt=""
                                    />
                                </Box >
                                <Box width="100px" display='flex'>
                                    <Typography variant="body2">+{countryCode?.phone}</Typography>
                                    <ArrowDropDownIcon sx={{ fontSize: 18 }} />
                                </Box>
                            </Box>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            onClick={() => setAnchorEl(null)}
                            PaperProps={{
                                sx: {
                                    maxHeight: "200px",
                                },
                            }}
                        >
                            {countries?.map((country: any, index: number) => (
                                <MenuItem
                                    key={index}
                                    onClick={() => {
                                        setAnchorEl(null);
                                        counteyChange(country.code);
                                    }}
                                >
                                    <Box display="flex" gap={1}>
                                        <div>
                                            <img
                                                loading="lazy"
                                                width="20"
                                                src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                                                srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                                                alt=""
                                            />
                                        </div>
                                        {country.label}
                                    </Box>
                                </MenuItem>
                            ))}
                        </Menu>

                    </Box>
                </>),
                endAdornment: (<>
                    <Tooltip title="Please enter the Mobile Number you use for WhatsApp">
                        <Typography sx={{ display: "flex" }}>
                            <Box sx={{ display: { xs: "none", sm: "flex", md: "flex" }}}>
                            <span style={{ color: "#B2BEB5" }}>
                            |</span>&nbsp;
                         <WhatsAppIcon style={{ color: "#25D366" }} />
                         </Box>
                         &nbsp;<span style={{ color: "#B2BEB5" }}>|</span></Typography></Tooltip>
                    <IconButton onClick={onCopy}>
                        <ContentCopyIcon fontSize="small" />
                    </IconButton></>
                ),
            }}
            InputLabelProps={{ shrink: true }}
            helperText={helperText}
            error={error}
        />
    );
}

export default TextFieldWithCountryCode;
