import { getWalletBalance } from "api/services/wallet";
import Loader from "components/Loader";
import { useQuery } from "react-query";
import { ResType } from "types";
import { createContext, useContext } from "react";


export const WalletBalanceContext = createContext<any>(null);


function WalletProvider({ children }) {
    const { data, isLoading, error }: ResType = useQuery(["wallet"], getWalletBalance, {
        enabled: !!localStorage.getItem("token"),

    });

    if (isLoading) return <Loader minHeight="60vh" />;

    return (
        <WalletBalanceContext.Provider value={{ data: data, isLoading, error }}>
            {children}
        </WalletBalanceContext.Provider>
    );

};

export const useWalletData = () => useContext(WalletBalanceContext);
export default WalletProvider;


