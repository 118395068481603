import { Autocomplete, Box, Button, Grid, TextField, Typography } from "@mui/material"
import { getInvoicePreference, updateWeekendData } from "api/services/invoice.preferencess";
import DrawerWrapper from "components/DrawerWrapper"
import { snack } from "components/toast";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { DialogProps } from "types"
import { getHoursOptions, getMinutesOptions } from "utils";

function UpdateOvertime({ open, setOpen }: DialogProps) {
    const queryClient = useQueryClient();
    const [hours, setHours] = useState(null);
    const [minutes, setMinutes] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { mutate, isLoading: insertLoad } = useMutation(updateWeekendData, {
        onSuccess: () => {
            snack.success("Office Working Hours Updated");
            queryClient.invalidateQueries("attendance-preferences");
            setOpen(false);
            setHours(null);
            setMinutes(null);
            setIsLoading(false);
        }
    });

    const handleUpdateOvertime = () => {
        if (!hours) {
            return snack.error("Please Select Hours");
        }
        if (!minutes) {
            return snack.error("Please Select Minutes")
        }
        setIsLoading(true);
        const apiData = { typeofdata: "updateovertime", data: { hours, minutes } }
        mutate({ data: apiData });
    }

    const { data: invoicePreferences, isLoading: isInvoicePreferencesLoading } = useQuery(
        "attendance-preferences",
        getInvoicePreference,
        {
            onSuccess: (res: any) => {
                setHours(res.data.holidayPreferences.updateovertime.hours);
                setMinutes(res.data.holidayPreferences.updateovertime.minutes);
            },
            enabled: open,
        }
    );

    return (
        <DrawerWrapper open={open} title="Update Standard Working Hours in a Day" setOpen={setOpen}>
            <Grid container>
                <Grid item xs={6}>
                    <Box sx={{ mr: 1 }}>
                        <Autocomplete
                            id="hours"
                            options={getHoursOptions()}
                            getOptionLabel={(option) => option.label}
                            value={hours}
                            onChange={(_, value: any) => {
                                setHours(value);
                            }}
                            renderInput={(params) => (
                                <TextField required {...params} label="Hours" name="hours" />
                            )}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ ml: 1 }}>
                        <Autocomplete
                            id="minutes"
                            options={getMinutesOptions()}
                            getOptionLabel={(option) => option.label}
                            value={minutes}
                            onChange={(_, value: any) => {
                                setMinutes(value);
                            }}
                            renderInput={(params) => (
                                <TextField required {...params} label="Minutes" name="minutes" />
                            )}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box>
                <Button sx={{ mt: 2 }} disabled={isLoading} fullWidth variant="contained" onClick={handleUpdateOvertime} color="secondary">Update</Button>
            </Box>
        </DrawerWrapper>
    )
}
export default UpdateOvertime
