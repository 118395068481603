import { styled } from "@mui/material/styles";

export const BackgroundImage = styled("div")(() => ({
  background: "rgba(24, 47, 83, 0.9)",
  position: "relative",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const LogoContainer = styled("div")(({ theme }) => ({
  
  maxWidth: 400,
  margin: "auto",
  padding: "20px",
  textAlign: "center",
  color: "white",
  borderRadius: 8,
  "& img": {
    maxWidth: "100%",
  },
  [theme.breakpoints.down("xs")]: { // For extra small screens
    padding: "10px",
    maxWidth: "100%",
    
  },
  [theme.breakpoints.up("sm")]: { // For small screens and up
    padding: "15px",
    maxWidth: "90%",
  },
  [theme.breakpoints.up("md")]: { // For medium screens and up
    maxWidth: 400,
    padding: "20px",
  },
}));
export const ResponsiveImageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "auto",
  width: "100%",
  background:"#182F53",
  padding: "0",
  "& img": {
    width: "100%",
    height: "auto",
    maxWidth: "400px", // Default max width
  },
  [theme.breakpoints.down("xs")]: {
    "& img": {
      width: "50%",
      maxWidth: "80%",  // Adjust width for extra small screens
      
    },
  },
  [theme.breakpoints.up("sm")]: {
    "& img": {
      maxWidth: "200px", // Adjust width for small screens and up
      display: "none",
    },
  },
  [theme.breakpoints.up("md")]: {
    "& img": {
      maxWidth: "300px", // Adjust width for medium screens and up
      display: "none",
    },
  },
  [theme.breakpoints.up("lg")]: {
    "& img": {
      maxWidth: "400px", // Adjust width for large screens and up
      display: "none",
    },
  },
}));

export const LoginBoxContainer =styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100vh;
  padding: 24px; /* Default padding */
  
  @media (max-width: 768px) {
    padding-top: 10px;
    padding-bottom: 0;
     min-height: 60vh;
  }
`;