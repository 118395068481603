import { http } from "api/http";

interface IUpdateData {
  data: {};
}

const getNotificationConstnats = () => {
  return http.get("/notifications-preferences");
};

const getNotificationConstnatsUpadte = () => {
  return http.get("/notifications-preferences/updated");
};

const getPreferences = () => {
  return http.get(`/notifications-preferences/getPreferences`);
};

const updatePreference = ({ data }: IUpdateData) => {
  return http.put(`/notifications-preferences/update`, data);
};

const updatePreferenceNew = ({ data }: IUpdateData) => {
  return http.put(`/notifications-preferences/update-new`, data);
};

const getQtmNotificationConstnats = () => {
  return http.get("/notifications-preferences/action");
};

const getPreferencesBasedOnUserId = ({ queryKey }) => {
  return http.get(`notifications-preferences/getUserPreferences/${queryKey[1]}`);
};

const updatePreferenceQtm = ({ data }) => {
  return http.put(`/notifications-preferences/update-qtm-preferences`, data);
};

const updateUserWhatsAppConfig = ({ data }) => {
  return http.put(`/notifications-preferences/update-whatsappconfig`, data);
};

export {
  getNotificationConstnats,
  getPreferences,
  updatePreference,
  getNotificationConstnatsUpadte,
  updatePreferenceNew,
  getPreferencesBasedOnUserId,
  updatePreferenceQtm,
  getQtmNotificationConstnats,
  updateUserWhatsAppConfig
};
