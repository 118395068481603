import { ArrowRightAltOutlined, MoreVert } from "@mui/icons-material";
import { Button, Checkbox, Divider, IconButton, MenuItem, Switch, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { cloneService, deleteService, servicePreference } from "api/services/services";
import CustomCard from "components/CustomCard";
import { snack } from "components/toast";
import { useConfirm } from "context/ConfirmDialog";
import { useMenu } from "context/MenuPopover";
import { MouseEvent, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { handleError } from "utils/handleError";
import { vider_logo } from "assets";
import ServiceClonePopUp from "pages/services/ServiceClonePopUp";
import { Permissions } from "data/permissons";
import ValidateAccess from "components/ValidateAccess";
import { usePermissions } from "context/PermissionsProvider";


function ServiceCard({ data }) {
  const menu = useMenu();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const [checked, setChecked] = useState(false);
  const [type, setType] = useState(true);
  const { permissions } = usePermissions();

  

  const [clonePopUpOpen, setClonePopUpOpen] = useState(false);

  
  useEffect(() => {
    if (data.serviceIsActive) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  }, []);
  
  const { mutate } = useMutation(deleteService, {
    onSuccess: () => {
      snack.success("Service Deleted");
      queryClient.invalidateQueries("services");
    },
    onError: (err: any) => {
      snack.error(err.response.data.message);
    },
  });

  const { mutate: onClone } = useMutation(cloneService, {
    onSuccess: () => {
      snack.success("Service Cloned");
      queryClient.invalidateQueries("services");
    },
    onError: (err: any) => {
      snack.error(handleError(err));
    },
  });

  const { mutate: onPreference } = useMutation(servicePreference, {
    onSuccess: () => {
      snack.success("Service Preference Updated");
      queryClient.invalidateQueries("services");
    },
    onError: (err: any) => {
      snack.error(handleError(err));
    },
  });

  const handleCheckboxClick = () => {
    if (checked) {
      onPreference({ id: data.id, action: "addPreference" });
    } else {
      onPreference({ id: data.id, action: "deletePreference" });
    }
    setChecked(!checked);
  };

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    let options: any[] = [];

    if (permissions.includes(Permissions.DELETE_SERVICES) && !data?.defaultOne) {
      options.push({
        label: "Delete",
            action: () => {
              confirm({
                msg: "Are you sure you want to delete this service?",
                action: () => {
                  mutate({ id: data?.id });
                },
              });
            },
      });
    }

    if (permissions.includes(Permissions.EDIT_SERVICES) && !data?.defaultOne) {
      options.push({
        label: "Edit",
        action: () => {
          navigate(`add/?serviceId=${data.id}`);
        },
      });
      options.push({
        label: "Clone",
            action: () => {
                setClonePopUpOpen(true)
            },
      });
    }

    if (permissions.includes(Permissions.EDIT_SERVICES) && data.defaultOne) {
      options.push({
        label: "Clone",
            action: () => {
                setClonePopUpOpen(true)
            },
      });
    }

    menu({
      target: e.currentTarget,
      options,
    });
  };

  return (
    <CustomCard
      sx={{
        position: "relative",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        wordBreak: "break-all",
      }}
    >
      <Box flex={1}>
        <Box display="flex" gap={1} justifyContent="space-between">
          <Box>
            <Typography variant="caption" color="rgba(0,0,0,0.6)">
              {data?.category?.name} {data?.subCategory && `|| ${data?.subCategory?.name}`}{" "}
              {/* {data?.fromAdmin && <span style={{ color: "red" }}>-- From Vider</span>} */}
            </Typography>
            <Typography sx={{ flex: 1 }} color="primary" variant="subtitle2">
              {data?.name}
            </Typography>
          </Box>
          {/* {!data?.defaultOne && ( */}
            <div>
              <IconButton size="small" onClick={handleClick}>
                <MoreVert />
              </IconButton>
            </div>
           {/* )} */}
        </Box>
        <Typography color="gray" variant="body2">
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              lineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
            dangerouslySetInnerHTML={{ __html: data?.description }}
          ></div>
        </Typography>
      </Box>

      <Divider sx={{ my: 1 }} />
      <Box display="flex" alignItems="center">
        <Box display='flex' alignItems='center' flexGrow={1}>
          {data?.defaultOne && (
            <div style={{ marginTop: 2, display: "inline-block" }}>
              <img style={{ width: 65, margin: 0 }} src={vider_logo} alt="" />
            </div>
          )}
           {/* <Checkbox checked={checked} onChange={handleCheckboxClick} /> */}
           <ValidateAccess name={Permissions.STATUS_SERVICES}>
           <Switch
            checked={checked}
            onChange={handleCheckboxClick}
            style={{
              color: checked ? "green":'',
            }}
          />
          </ValidateAccess>
        </Box>
        <Button
          onClick={() => {
            navigate(`/services/${data?.id}/overview`);
          }}
          endIcon={<ArrowRightAltOutlined />}
        >
          View details
        </Button>
      </Box>
      <ServiceClonePopUp
        open={clonePopUpOpen}
        setOpen={setClonePopUpOpen}
        serviceId={data?.id}
        type={type}
        serviceIsActive={data?.serviceIsActive}
      />
    </CustomCard>
  );
}

export default ServiceCard;
